<template>
    <MainLayout>
        <div class="container body_active">
            <h1 class="mb-5 text-center"><strong>Privacy Policy</strong></h1>
            <div class="mb-5">
                1. PRIVACY POLICY
                <br> <br>
                Your privacy is important to StrobeArt. StrobeArt LLC is committed to respecting your right to privacy.
                This Privacy Policy is to inform our customers, affiliates, and users of the
                website <a href="http://www.strobeart.com">www.strobeart.com</a> (the “<b>Website</b>”) how
                personal information is collected, used, disclosed, transferred and stored (the “<b>Privacy Policy</b>”).
                <br> <br>
                Please take a moment to familiarize yourself with our privacy practices and let us know if you have any
                questions by visiting our Contact Us Section on the Website.
                <br> <br>
                This Privacy Policy describes how StrobeArt collects and uses the personal information you provide on
                the Website for your use of the services, features and content offered by StrobeArt (collectively, the “<b>Services</b>”).
                It also describes the choices available to you regarding the use of your personal information and how
                you can access and update your personal information.
            </div>
            <div class="mb-5">
                2. IDENTITY AND INFORMATION PROTECTION
                <br> <br>
                StrobeArtfollows stringent procedures to help protect the confidentiality, security, and integrity of data stored on StrobeArtsystems.
                <br> <br>
                Only those employees who need access to your information to perform their duties are allowed such
                access. Any employee who violates our privacy and/or security policies is subject to disciplinary
                action, including possible termination and civil and/or criminal prosecution.
                <br> <br>
                We will share your personal information with third parties only in the ways that are described in this
                privacy policy. We do not sell your personal information to third parties.
            </div>
            <div class="mb-5">
                3. DATA SECURITY
                <br> <br>
                To prevent unauthorized access, maintain data accuracy and ensure the appropriate use of information, we
                have put in place physical, electronic, and managerial procedures to protect the information we collect
                online. When you enter sensitive information (such as a credit card number) on our order forms, we
                encrypt that information using secure socket layer technology (SSL).
                <br> <br>
                We follow generally accepted standards to protect the personal information submitted to us, both during
                transmission and once we receive it. Please note, however, that no method of transmission over the
                internet, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee its absolute
                security. Every payment made on the Website will be redirected to the secured website; StrobeArtwill
                not see or have access to any personal information pertaining to payment.
            </div>
            <div class="mb-5">
                4. WHAT INFORMATION DO WE COLLECT ABOUT YOU?
                <br> <br>
                The information StrobeArtgathers falls into two categories:
                <br> <br>
                        (a) Personally identifiable information, which includes personal information you supply when you
                register and helps identify you as a User, order from us or our partners, complete a survey, enter a contest, provide
                your email address, or provide your friends’ emails addresses, feedback sent to us or our partners.
                <br> <br>
                        (b) Non-personally identifiable information collected through technology and does not identify
                you as a User, which includes tracking information collected as you navigate the Website.
                <br> <br>
                <b>        (a) Personally Identifiable Information That You Provide Us</b>
                <br> <br>
                        (i) Registration for the StrobeArtServices requires that you supply certain personally
                identifiable information, including, in most cases, your first name, your last name, photo, biographical
                information, date of birth, a unique email address and a password to register. We may also collect
                Professional related information, Social media profiles, Financial Information such as billing name and
                address, credit or debit card number, or bank account information, Usage and device information such
                your history, user session information, IP address, browser type, domain names, access times, and
                referring website addresses, any Interactions you may have with the Platform, User submissions including
                your ratings, updates, alerts, comments, feedback, or any other information that you voluntarily
                provide.
                <br> <br>
                This allows StrobeArtto provide you access to your account every time you visit the Website.
                StrobeArtkeeps track of your information to offer you the best possible browsing experience.
                <br> <br>
                <b>        (ii) Paid Products and Services.</b> To enable the purchase of our services, we also collect
                and store billing information as well as your address, telephone number or any other information
                required to process your order. In some cases, we will ask you to share mandatory information or
                voluntary information. If the required mandatory information is not entered, your transaction will be
                annulled. This information may be disclosed to specific members of our staff and to select third parties
                (such as our credit card processor) involved in the completion of your transaction. Examples of this
                include processing credit card payments service providers. We will also use your email address to notify
                you about new services, special promotional programs, promotional offers, or any information related to
                StrobeArtServices. At any time, you can notify us that you wish to stop receiving these emails by
                either:
                <br> <br>
                <ul>
                    <li>Making the appropriate selections in your account management center, or <br></li>
                    <li>Following the "unsubscribe" instructions located near the bottom of each email, or <br></li>
                    <li>Contacting us and requesting that we remove your email from our database.</li>
                </ul>
                <br>
                In addition, we keep a record of your past orders and credits. We may also ask you for information
                regarding your personal preferences and demographics to better meet your needs.
                <br> <br>
                <b>        (iii) Registration to the Website via Third-Party Tools.</b> You may choose to log-on, create
                an account or enhance your profile on the Website with the Apple and/or Google Connect feature on the
                Website. By doing this, you are asking Apple and/or Google to send us registration information from your
                Apple and/or Google profile. We treat that information as we do any other information you give to us
                when you log on, register or create a profile. After you have connected, Apple and/or Google also sends
                us additional information from your profile that allows us to display a Apple and/or Google module on
                the pages you view. We do not store this additional information.
                <br> <br>
                <b>        (iv) Contests, Sweepstakes and Special Offers.</b> StrobeArt collects personal information such
                as your first name, your last name, a unique email address and a password from readers in connection
                with optional sweepstakes, contests, or special offers. If this information is to be shared with a third
                party other than StrobeArt, we will notify you at the time of collection. If you do not want any
                personal information shared, you may always decline to participate in the sweepstakes, contest or
                special offer. Contests can only be entered by Users over the ages of 18, and prizes need to be claimed
                48 hours after announcing the winner. If the winner of the contest fails to do so, and does not contact
                to claim the prize he will automatically lose the prize and be forfeited. By taking the prize, the
                winner accepts to disclose their name for publicity matters and will need proof of identification to
                claim the prize. StrobeArtreserves the right to cancel the Contest and may disqualify any applicant
                that does not respect our terms and conditions.
                <br> <br>
                <b>        (v) Members Surveys.</b> StrobeArtmay collect personal information such as your first name,
                your last name, a unique email address and a password as well as your opinion on specific StrobeArt
                services from members in connection with voluntary surveys. Data may be collected on the Website, on the
                phone or through the mail. The information you provide by answering optional survey questions may be
                shared, but only in the aggregate, with advertisers and partners unless we notify you otherwise at the
                time of collection.
                <br> <br>
                <b>        (vi) Access to Personally Identifiable Information.</b> If your personal identifiable
                information changes, or if you no longer desire our services, you may correct, update, delete/deactivate
                it by either:
                <br> <br>
                <ul>
                    <li>Making the necessary changes in your member information page, or <br></li>
                    <li>Emailing our Customer Service team on our Contact Us page.</li>
                </ul>
                <br>
                We will retain your information for as long as your account is active or as needed to provide you
                services. We will retain and use your information as necessary to comply with our legal obligations,
                resolve disputes, and enforce our agreements.
                <br> <br>
                <b>        (vii) Invite-A-Friend.</b> If you choose to use StrobeArt’s invite
                a friend referral service
                to invite your friends to join the Website or send an email regarding a product/event to a friend, we
                will ask you for your friend’s email address and may store their personal information. We will
                automatically send your friend a one-time email inviting him or her to visit the Website. StrobeArt
                stores this information for the purpose of sending this one-time email and email reminders and tracking
                the success of its invite to a friend referral program. You can also import contacts from your Outlook
                or another email account address book to invite your friends to become members of the Website. We
                collect the username and password for the email account from which you wish to import your contacts and
                will only use it for this purpose. Your friend may contact us on our Contact Us page to request that we
                remove this information from our database.
                <br> <br>
                <b>        (viii) Compliance with Legal Process.</b> We may disclose personal information if we are
                required to do so by law or we in good faith believe that such action is necessary to (1) comply with
                the law or with legal process; (2) protect and defend our rights and property; (3) protect against
                misuse or unauthorized use of the StrobeArtServices; or (4) protect the personal safety or property of
                our users or the public (among other things, this means that if you provide false information or attempt
                to pose as someone else, information about you may be disclosed as part of any investigation into your
                actions). In the unlikely event that a dispute needs to be resolved or for any administrative tasks, we
                may also use your Personally Identifiable Information.
                <br> <br>
                <b>        b) Non-Personally <em>Identifiable Information Collected Using Technology</em></b>
                <br> <br>
                <b>        (i) Information Collected by Us Using Technology.</b> Device Information: We may collect
                information about the computer, mobile telephone, smartphone, or other device you use to access the
                Website. This information may be used for troubleshooting purposes, analyzing trend patterns, or
                thoroughly studying a certain target market.
                <br> <br>
                <b>        (ix) Information Collected by Third Parties Using Technology.</b> Some of the services and
                advertisements included in the StrobeArtServices, including on the Website, are delivered, or served by
                third-party companies that may place or recognize cookies, Web beacons or other technology to track
                certain non-personally identifiable information about the Website users. For example, while serving
                certain advertisements, some advertisers may place or recognize a unique cookie on your browser to
                collect certain information about your use of the StrobeArtServices. In many cases, this information
                could be used to show you ads based on your interests on other websites. Please note that we do not have
                access to, nor control over, advertisers' or service providers' cookies or how they may be used.
                <br> <br>
                <b>        (x) Cookies.</b> The Website also transmits non-personally identifiable Website usage
                information about visitors to the servers of reputable third parties. To do this, StrobeArtuses Web
                Beacons in conjunction with cookies provided by our third-party ad server vendors.
                <br> <br>
                The purpose is to recognize you, provide a personalized experience, improve your shopping experience,
                save you time, help us understand where our visitors are going and how much time they spend there, as
                well as to target internet banner advertisements on other sites. You will not be able to access certain
                areas of the Website if your computer does not accept cookies from the Website. A cookie is a small
                piece of information that is stored on a computer for the purpose of identifying that browser during
                interaction on the websites. Cookies may be used to store items such as identifiers and user
                preferences. A website may set a cookie to the browser if the browser's preferences allow it. A browser
                only permits a website to access the cookies it has set, not those set by other websites.
                <br> <br>
                A session ID cookie expires when you close your browser. A persistent cookie remains on your computer
                for an extended period. You can remove persistent cookies by following the directions provided in your
                Internet browser’s “help” file. If you reject cookies, you may still use the Website, but your ability
                to use some areas of the Website may be limited.
                <br> <br>
                StrobeArtuses third party vendors to store cookies on your computer when you first visit the Website to
                enable StrobeArtto recognize you each time you come back to the Website. Our third-party vendors use
                both session ID cookies and/or persistent cookies. Through cookies StrobeArtcan customize the Website
                to your individual preferences to create a more personalized and convenient user experience.
                <br> <br>
                Please note that cookies stored on your computer through the Website or StrobeArtemail campaigns do not
                store personally identifiable information about you or your finances.
                <br> <br>
                Our third-party vendors use cookies to serve ads based on your prior visits to the Website.
                <br> <br>
                You have choices about the collection of information by third parties on the Website. If you would like
                more information about advertisers' use of cookies, and about your option not to accept these cookies,
                or if you would like to opt-out of having interest-based information collected during your visits to
                this Website or other sites, please contact us on our Contact Us page. Your access to the Website will
                not be affected if you do not accept cookies served by third parties.
                <br> <br>
                <b>        (xi) Web Beacons.</b> A web beacon is an object that is embedded in a web page or e-mail and
                is usually invisible to the user but allows checking that a user has viewed a specific page of the
                Website or e-mail. StrobeArtand our third-party advertising partner employ a tracking technique called
                web beacons. Web beacons can be small (usually 1×1 pixel) transparent GIF or PNG with a unique
                identifier, similar in function to cookies, embedded in an HTML page, usually a page on the web or the
                content of an e-mail, used to know if a user has completed a specific action like registering or placing
                an order. We do not tie the information gathered by web beacons to our customers’ personally
                identifiable information. We use web beacons in our HTML-based emails to know which emails have been
                opened by recipients. This allows StrobeArtto gauge the effectiveness of certain communications and
                marketing campaigns. If you would like to opt-out of these emails, please proceed by contacting us on
                our Contact Us page or by making the appropriate selections in your account man
                <br> <br>
                <b>YOUR CALIFORNIA PRIVACY RIGHTS.</b>
                <br> <br>
                If you reside in California, you have the right to ask someone from our team one time each year if we
                have shared personal information with third parties for their direct marketing purposes. To make a
                request, please send us an email at info@strobeart.com. Please write: “Shine the Light” in your subject
                line and stipulate in the body of the email that you are a California resident.
                <br> <br> <br>
                <b>        (xii) Analytics Technologies:</b> StrobeArtuses third-party tools
                to collect information,
                reports and analysis about the usage, browsing patterns of users of the Website. StrobeArtuses this
                data to make improvements to your shopping experience and to ensure that the Website has enough capacity
                to properly serve all our users. The software provides aggregate reporting information to StrobeArt
                only. No personal or personally identifiable information is gathered or used for this process.
            </div>
            <div class="mb-5">
                5. WHAT DO WE DO WITH THE INFORMATION WE COLLECT ABOUT YOU?
                <br> <br>
                <b>        a) Providing the Services You Request.</b> We use the information we gather about you to
                enable your use of the StrobeArtServices.
                <br> <br>
                <b>        c) Statistical Analysis.</b> We perform statistical, demographic and marketing analyses of
                users of the StrobeArtServices, and their subscribing and purchasing patterns, for product development
                purposes and to generally inform advertisers about the nature of our subscriber base. StrobeArtuses
                this information for analysis purposes, including analysis to improve customer relationships and to
                build internal reports that may be shared with other third parties with whom we do business. All these
                reports and generated statistics will not contain information that will allow you to be traced.
                <br> <br>
                <b>        d) Customizing Your Experience.</b> We use the information that we collect to allow
                advertising to be targeted to the users for whom such advertising is most pertinent. We also use this
                information to customize certain features of the StrobeArtServices to provide you with an enhanced
                experienced based on the type of device you are using to access the StrobeArtServices, and in certain
                cases, provide you with requested services.
                <br> <br>
                <b>       e) Relevant Advertising.</b> We may use demographic and preference information to allow
                advertising on all StrobeArtplatforms to be targeted, in aggregate, to the users for whom they are most
                pertinent. This means users see advertising that is most likely to interest them, and advertisers send
                their messages to people who are most likely to be receptive, improving both the viewer's experience and
                the effectiveness of the ads. We might also tell you about new features or updates. We disclose
                information to third parties only in aggregate form. With your approval, we may post your comments as
                sample testimonials.
                <br> <br>
                <b>        f) Email.</b> StrobeArtwill periodically send you promotional emails about services offered
                by StrobeArtand its advertisers. For details about StrobeArtmail policy, please see the section
                labeled "Email Policy" below.
            </div>
            <div>
                6. WITH WHOM DO WE SHARE THE INFORMATION THAT WE GATHER?
                <br> <br>
                <b>StrobeArt</b> assures the highest degree of discretion with personal information and only some
                information will be shared with the following:
                <br> <br>
                <b>        a) StrobeArt.</b> If you have registered to use the StrobeArt Services, we will not sell,
                rent, swap or authorize any third party to use your email address or any information that personally identifies
                you without your permission. We do, however, share information about our audience in aggregate form.
                <br> <br>
                <b>        g) Service Providers.</b> We contract with other companies to provide services on our behalf,
                including hosting the Website, operating various features made available on the Website, sending emails,
                analyzing data, providing search results and links, credit-card and payment processing, email
                distribution, list processing and analytics, customer service through live chat software, service order
                fulfillment or promotions management. We provide these companies only with the information they need to
                perform their services. These service providers are restricted from using this data in any way other
                than to provide services for StrobeArt, and they may not share or resell this data. We reserve the right
                to disclose your unsubscribe information to third parties so they can suppress your name from future
                solicitations, in accordance with applicable laws. We may occasionally release personal information as
                required by law, for example, to comply with a court order or subpoena.
                <br> <br>
                <b>        h) Partners.</b> If StrobeArtdecides to merge or acquire with another company/organization,
                your Personally Identifiable Information will be shared with the latter company. Rest assured that the
                Personally Identifiable Information will still respect the Privacy Policy.
                <br> <br>
                <b>WE MAY UPDATE THIS POLICY.</b>
                <br> <br>
                This Privacy Policy may be updated occasionally, but the changes will not apply retroactively. We will
                notify you of any material changes to our Policy as required by law. We will also post an updated copy
                on our website and channels. Please check our website and channels periodically for updates.
            </div>
        </div>
    </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";

export default {
    name: "PrivacyPolicy",
    components: {
        MainLayout,
    },
    data() {
        return {
            page_loaded: false,
        }
    },
}
</script>

<style scoped>
.body_active {
    margin-bottom: 70px;
}
</style>
