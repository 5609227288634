<template>
    <div>
        <div class="title-1 ps-3 d-flex flex-row align-items-center" @click="showAccounts">
            Connected accounts
            <svg class="ms-3" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.8528 6.94727C18.6066 6.70121 18.2729 6.56299 17.9248 6.56299C17.5768 6.56299 17.243 6.70121 16.9969 6.94727L10.5 13.4441L4.00315 6.94727C3.75561 6.70819 3.42407 6.5759 3.07994 6.57889C2.73581 6.58188 2.40661 6.71991 2.16327 6.96326C1.91992 7.20661 1.78189 7.5358 1.7789 7.87993C1.77591 8.22406 1.9082 8.5556 2.14728 8.80314L9.57209 16.228C9.81822 16.474 10.152 16.6122 10.5 16.6122C10.8481 16.6122 11.1818 16.474 11.428 16.228L18.8528 8.80314C19.0988 8.55701 19.2371 8.22324 19.2371 7.87521C19.2371 7.52718 19.0988 7.1934 18.8528 6.94727Z" fill="#494949"/>
            </svg>
        </div>
        <div class="account-items" v-if="show_accounts">
            <div v-if="instagram_connect" class="row mb-4">
                <div class="col-12">
                    <div class="account-item with_underline">
                        <div class="account-item__icon">
                            <img src="@/assets/icons/Instagram_icon.svg">
                        </div>
                        <div class="account-item__wrapper">
                            <div class="account-item__name">
                                {{instagram_connect_data ? instagram_connect_data.name : ''}}
                            </div>
                            <div class="account-item__type">Instagram</div>
                        </div>
                        <div class="phone__block cp pb-2" @click="unlinkInstagram">
                            <div class="text2">
                                <button class="btn-c3">Disconnect</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="shopify_connect" class="row mb-4">
                <div class="col-12">
                    <div class="account-item with_underline">
                        <div class="account-item__icon">
                            <img src="@/assets/icons/Shopify_icon.svg">
                        </div>
                        <div class="account-item__wrapper">
                            <div class="account-item__name">
                                {{shopify_connect_data.name}}
                            </div>
                            <div class="account-item__type">Shopify</div>
                        </div>
                        <div class="phone__block cp pb-2" @click="unlinkShopify">
                            <div class="text2">
                                <button class="btn-c3">Disconnect</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="amazon_connect" class="row mb-4">
                <div class="col-12">
                    <div class="account-item with_underline">
                        <div class="account-item__icon">
                            <img src="@/assets/icons/Amazon_icon.png">
                        </div>
                        <div class="account-item__wrapper">
                            <div class="account-item__name">
<!--                                {{user.connections.amazon.name}}-->
                            </div>
                            <div class="account-item__type">Amazon</div>
                        </div>
                        <div class="phone__block cp pb-2" @click="unlinkAmazon">
                            <div class="text2">
                                <button class="btn-c3">Disconnect</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import helpFunctions from "../../helpFunctions";

    export default {
        name: 'Disconnect',
        data() {
            return {
                instagram_connect_data: [],
                instagram_connect: false,
                shopify_connect_data: [],
                shopify_connect: false,
                amazon_connect: false,
                show_accounts: false,
            }
        },
        mounted() {
            this.connections();
        },
        methods: {
            showAccounts() {
                this.show_accounts = !this.show_accounts;
            },
            async unlinkInstagram() {
                if (window.confirm("Do you really want to disconnect instagram?")) {
                    await this.$http.postAuth(`${this.$http.apiUrl()}profile/unlink-instagram`);
                    this.instagram_connect = false;
                }
            },
            async unlinkShopify() {
                if (window.confirm("Do you really want to disconnect shopify?")) {
                    await this.$http.postAuth(`${this.$http.apiUrl()}profile/unlink-shopify`);
                    this.shopify_connect = false;
                }
            },
            async unlinkAmazon() {
                if (window.confirm("Do you really want to disconnect amazon?")) {
                    await this.$http.postAuth(`${this.$http.apiUrl()}profile/unlink-amazon`);
                    this.amazon_connect = false;
                }
            },
            async connections() {
                try {
                    let resp = await this.$http.getAuth(`${this.$http.apiUrl()}profile/setting`);
                    this.instagram_connect = helpFunctions.isEmptyObject(resp?.data?.data?.user?.login_instagram || {});
                    this.shopify_connect = helpFunctions.isEmptyObject(resp?.data?.data?.user?.shopify_connect || {});
                    this.amazon_connect = helpFunctions.isEmptyObject(resp?.data?.data?.user?.aws || {});
                    console.log(resp?.data?.data)
                    resp = await this.$http.getAuth(`${this.$http.apiUrl()}user-connections`);
                    console.log(resp)
                    if (resp.data?.data?.instagram?.data) {
                        this.instagram_connect_data = resp.data.data.instagram.data[0];
                    }
                    if (resp.data?.data?.shopify) {
                        this.shopify_connect_data = resp.data.data.shopify;
                    }
                } catch (error) {
                    console.log(error);
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    .with_underline {
        border-bottom: 0.5px solid rgba(73, 73, 73, 0.45);
    }
    .title-1 {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #494949;

        cursor: pointer;
    }
    .account-items {
        padding-top: 15px;
    }
    .account-item {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        border-radius: 4px;
        padding: 10px 18px;

        &__icon {
            width: 40px;
            img {
                width: 100%;
            }
        }
        &__wrapper {
            width: 100%;
            margin-left: 10px;
        }
        &__name {
            font-size: 14px;
        }
        &__type {
            font-size: 11px;
        }
    }
</style>
