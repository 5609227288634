<template>
    <FluidLayout>
        <div class="container-fluid body_active">
            <div class="head-block">
                <div class="head__images-container">
                    <img class="head__image head__image_first" src="@/assets/images/HowItWorks/Head_first.png">
                    <img class="head__image head__image_second" src="@/assets/images/HowItWorks/Head_second.png">
                    <img class="head__image head__image_third" src="@/assets/images/HowItWorks/Head_third.png">
                    <img class="head__image head__image_fourth" src="@/assets/images/HowItWorks/Head_fourth.png">
                    <img class="head__image head__image_fifth" src="@/assets/images/HowItWorks/Head_fifth.png">
                </div>
                <div class="head__stripe">
                    Flexible    editing    opportunities    with    Strobeart.    Flexible    editing    opportunities    with    Strobeart
                </div>
                <div class="head-bottom">
                    <div class="head__text">
                        Looking for full-time or part-time retouching jobs? Join the platform with the largest network of active clients and start earning today! Get paid to do what you love on your schedule.
                    </div>
                </div>
                <img class="head__arrow" src="@/assets/images/HowItWorks/Head_arrow.svg">
            </div>
            <div class="row orange-row" v-if="page_loaded">
                <div class="col-12 mt-5 text-center">
                    <router-link :to="{ name: 'Login' }" class="banner-mobile__button">Sign in</router-link>
                </div>
                <div class="col-12 plans-container">
                    <div class="plan plan_orange">
                        <img src="@/assets/images/HowItWorks/Basic.png" alt="Basic plan">
                        <div class="plan__text">
                            <span class="plan__name">Basic edit</span>
                            <span class="plan__price">${{(plans.EXPERT.retouching.Simple * prices.editor.coefficient / 100).toFixed(2)}}</span>
                        </div>
                    </div>
                    <div class="plan plan_green">
                        <img src="@/assets/images/HowItWorks/Intermediate.png" alt="Basic plan">
                        <div class="plan__text">
                            <span class="plan__name">Intermediate</span>
                            <span class="plan__price">${{(plans.EXPERT.retouching.Intermediate * prices.editor.coefficient / 100).toFixed(2)}}</span>
                        </div>
                    </div>
                    <div class="plan plan_violet">
                        <img src="@/assets/images/HowItWorks/Expert.png" alt="Basic plan">
                        <div class="plan__text">
                            <span class="plan__name">Expert</span>
                            <span class="plan__price">${{(plans.EXPERT.retouching.Advanced * prices.editor.coefficient / 100).toFixed(2)}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 money-time-block">
                    <div class="money-time__title">
                        How it works:
                    </div>
                    <div class="row d-flex flex-column-reverse flex-lg-row">
                        <div class="col-lg-5 offset-lg-1 money-time__left">
                            <div class="money-time__text-title">COMPLETE WITHIN:</div>
                            <div class="money-time__text">
                                <img class="me-2" src="@/assets/icons/tick.svg">
                                15 minutes, receive the full commission
                            </div>
                            <div class="money-time__text">
                                <img class="me-2" src="@/assets/icons/tick.svg">
                                10 minutes, receive an additional 5% bonus
                            </div>
                            <div class="money-time__text">
                                <img class="me-2" src="@/assets/icons/tick.svg">
                                5 minutes, receive an additional 10% bonus
                            </div>
                            <div class="money-time__subtext">
                                Client can request one round of revisions, which will result in a 5% reduction in commission. An additional 15 minutes will be provided to complete the changes.
                            </div>
                        </div>
                        <div class="col-lg-6 money-time__right text-center">
                            <img src="@/assets/images/HowItWorks/Clock.svg" alt="Time price">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 info-block">
                    <div class="row">
                        <div class="col-lg-5 offset-lg-1 info__left">
                            <img class="info__image info__image_first" src="@/assets/images/HowItWorks/First.svg">
                            <img class="info__image info__image_second" src="@/assets/images/HowItWorks/Second.svg">
                            <img class="info__image info__image_third" src="@/assets/images/HowItWorks/Third.svg">
                        </div>
                        <div class="col-lg-4 offset-lg-1 info__right">
                            <div class="info__item">
                                <img class="info__image info__image_first" src="@/assets/images/HowItWorks/First.svg">
                                <div class="info__item-title">Make more money</div>
                                <div class="info__item-text">
                                    With the exponential pop-up of e-commerce stores and influencers comes a need for experts editors. Strobeart is the perfect way to earn on any schedule.
                                </div>
                            </div>
                            <div class="info__item">
                                <img class="info__image info__image_second" src="@/assets/images/HowItWorks/Second.svg">
                                <div class="info__item-title">Be your own boss</div>
                                <div class="info__item-text">
                                    Make your own schedule and choose when and where you edit. How about editing while sipping a margarita on the beach.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </FluidLayout>
</template>

<script>
import FluidLayout from "@/layouts/FluidLayout";
import { mapMutations } from 'vuex';

export default {
    name: 'HowItWorks',
    components: {
        FluidLayout,
    },
    data() {
        return {
            page_loaded: false,
            plans: [],
            prices: [],
        }
    },
    async mounted() {
        await this.getPlans();
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        async getPlans() {
            try {
                this.showLoader();
                const response = await this.$http.get(`${this.$http.apiUrl()}get-prices`);
                if (response.data.data) {
                    this.plans = response.data.data.subscribes;
                    this.prices = response.data.data;
                    this.page_loaded = true;
                }
            } catch (e) {
                console.log(e);
            }
            this.hideLoader();
        }
    }
}
</script>

<style lang="scss" scoped>
.banner-mobile__button {
    margin-top: 15px;
    padding: 14px 31px;
    background: linear-gradient(180deg, #E27582 0%, #FEC0C8 99.5%), #FCF5EF;
    border: 0.5px solid white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: white;
    text-decoration: none;
}
.head {
    &-block {
        margin-left: -12px !important;
        margin-right: -12px !important;
        margin-bottom: -60px;
        //padding-bottom: 60px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        @media only screen and (max-width: 992px) {
            display: none;
        }
    }
    &__stripe {
        width: 100%;
        max-height: 64px;
        padding: 13px 22px 3px 22px;
        position: relative;
        top: -250px;

        background: #EBF592;

        text-align: center !important;
        font-family: 'Bebas Neue';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        color: black;

        word-spacing: 40px;
        white-space: nowrap;
        @media only screen and (max-width: 1630px) {
            word-spacing: 20px;
        }
        @media only screen and (max-width: 1452px) {
            word-spacing: 10px;
        }
        @media only screen and (max-width: 1370px) {
            overflow: hidden;
        }
    }
    &__images-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__image {
        @media only screen and (max-width: 1280px) {
            overflow: auto
        }
    }
    &__text {
        max-width: 570px;
        margin-top: 32px;

        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
    }
    &__arrow {
        position: relative;
        top: -20px;
        right: -260px;
        transform: rotate(6deg);
    }
}
.orange-row {
    background: #FCF5EF;
}
.plans-container {
    display: flex;
    justify-content: space-around;
    padding-top: 74px;
    padding-bottom: 72px;

    @media only screen and (max-width: 992px) {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
}
.plan {
    width: 290px;
    height: 314px;
    padding: 16px 16px 12px 16px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.04);
    border-radius: 16px;

    &__text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
    }
    &__name {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;

        color: #333333;
    }
    &__price {
        font-family: 'Bebas Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 64px;
        letter-spacing: -2px;
        color: #111111;
    }
    &_orange {
        background: #FFD6C5;
    }
    &_green {
        background: #D6FFD6;
    }
    &_violet {
        background: #EFE0FD;
    }

    @media only screen and (max-width: 992px) {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
.money-time {
    color: #333333;

    &-block {
        padding: 64px 130px 100px 130px;

        @media only screen and (max-width: 992px) {
            padding: 20px;
        }
    }
    &__title {
        font-weight: 600;
        font-size: 40px;
        line-height: 64px;
        letter-spacing: -2px;
        text-align: center;

        @media only screen and (max-width: 992px) {
            margin-bottom: 20px;
        }
    }
    &__body {
        display: flex;
        justify-content: space-between;
    }
    &__left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__right {
        @media only screen and (max-width: 992px) {
            img {
                max-width: 100%;
            }
        }
    }
    &__text-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;

        @media only screen and (max-width: 992px) {
            text-align: center;
            margin-bottom: 20px;
        }
    }
    &__text-subtitle {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;

        @media only screen and (max-width: 992px) {
            margin-bottom: 16px;
            text-align: center;
        }
    }
    &__text {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;

        @media only screen and (max-width: 992px) {
            margin-bottom: 10px;
            text-align: justify;
        }
    }
    &__subtext {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;

        @media only screen and (max-width: 992px) {
            margin-bottom: 10px;
            text-align: justify;
        }
    }
}
.info {
    &-block {
        padding: 100px 150px 140px 150px;

        @media only screen and (max-width: 992px) {
            padding: 20px;
        }
    }
    &__left {
        max-width: 524px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media only screen and (max-width: 992px) {
            display: none;
        }
    }
    &__right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .info__image {
            display: none;
        }

        @media only screen and (max-width: 992px) {
            margin-bottom: 60px;

            .info__image {
                display: block;
                padding: 0;
                margin-left: auto;
                margin-right: auto;
                width: 100%;
            }
        }
    }
    &__image {
        &_first {
            padding-right: 30px;
        }

        &_third {
            padding-top: 20px;
        }
    }
    &__item {
        &-title {
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;

            @media only screen and (max-width: 992px) {
                text-align: center;
                margin-bottom: 15px;
            }
        }
        &-text {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;

            @media only screen and (max-width: 992px) {
                text-align: justify;
                margin-bottom: 25px;
            }
        }
    }
}
</style>
