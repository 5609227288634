<template>
    <MainLayout>
        <div class="container body_active">
            <div class="row mb-4">
                <div class="col-12 col-lg-8 offset-lg-2">
                    <h2 class="contact__title">Send us an email</h2>
                    <span class="contact__text">
                        StrobeArt values your comments and concerns. Please feel free to contact us at info@strobeart.com as we welcome your feedback. <br>
                        Phone: <a href="tel:17476665720">+1 323-553-0670</a> (Mon - Fri, 9am - 5pm PST) <br>
                        Still Need Help? Contact Us Here by filling all required fields!
                    </span>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 col-lg-4 offset-lg-2 d-flex flex-column">
                    <label class="contact__label">Name</label>
                    <input v-model="name" type="text" class="contact__input" :class="{ 'is-invalid': name_is_valid }">
                    <div class="invalid-feedback">{{ name_is_valid }}</div>
                </div>
                <div class="col-12 col-lg-4 d-flex flex-column mt-3 mt-lg-0">
                    <label class="contact__label">Email</label>
                    <input v-model="email" type="email" class="contact__input" :class="{ 'is-invalid': email_is_valid }">
                    <div class="invalid-feedback">{{ email_is_valid }}</div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 col-lg-4 offset-lg-2 d-flex flex-column">
                    <label class="contact__label">Phone</label>
                    <vue-tel-input :class="{ 'is-invalid': phone_is_valid }" class="contact__input" v-model="phone" v-bind="props_tel"/>
                    <div class="invalid-feedback">{{ phone_is_valid }}</div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12 col-lg-8 offset-lg-2 d-flex flex-column">
                    <label class="contact__label">Message</label>
                    <textarea v-model="message" cols="30" rows="6" class="contact__input" :class="{ 'is-invalid': message_is_valid }"/>
                    <div class="invalid-feedback">{{ message_is_valid }}</div>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-12 mb-5 mb-lg-0 col-lg-2 offset-lg-2 d-flex flex-column">
                    <button type="button" :style="'background: ' + getDefaultColor" class="contact__submit-btn" @click="submitForm">Send</button>
                </div>
            </div>
        </div>
        <div class="modal" id="successModalMail" ref="successModalMail" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="input_modal">
                            <div class="modal_title">{{ message_modal }}</div>
                            <button class="btn_modal_close" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </MainLayout>
</template>

<script>
import {mapMutations, mapGetters} from 'vuex';
import MainLayout from "@/layouts/MainLayout";
import { errorMessage } from '../services/messages';
import {VueTelInput} from 'vue-tel-input';
import { Modal } from 'bootstrap';
import helpFunctions from "../helpFunctions";

export default {
    name: 'ContactUs',
    components: {
        MainLayout,
        VueTelInput,
    },
    data() {
        return {
            name: '',
            email: '',
            valid_email: false,
            email_is_valid: false,
            phone_is_valid: false,
            name_is_valid: false,
            message_is_valid: false,
            phone: '',
            message: '',
            successModalMail: null,

            props_tel: {
                preferredCountries: ["US", "GB"],
                placeholder: "Enter your phone",
                mode: "international",
                inputOptions: {
                    showDialCode: true
                },
                disabledFormatting: false,
                wrapperClasses: "country-phone-input"
            },
        }
    },
    mounted() {
        this.successModalMail = new Modal(this.$refs.successModalMail);
    },
    destroyed() {
        if (this.successModalMail) {
            this.successModalMail.dispose();
        }
    },
    computed: {
        ...mapGetters([
            'getDefaultColor'
        ]),
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        async submitForm() {
            if (this.validateForm()) {
                return;
            }
            try {
                this.showLoader();

                const response = await this.$http.postAuth(`${this.$http.apiUrl()}contact-us`, {
                    'name': this.name,
                    'email': this.email,
                    'phone': this.phone.split(' ').join('').trim(),
                    'order_number': '',
                    'message':this.message
                });

                if (response?.data?.success) {
                    this.clearValid();
                    this.clearForm();
                }
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        clearForm() {
            this.name = '';
            this.email = '';
            this.phone = '';
            this.message = '';
        },
        clearValid() {
            this.valid_email = false;
            this.email_is_valid = false;
            this.phone_is_valid = false;
            this.name_is_valid = false;
        },
        validateForm() {
            let error_form = false;
            this.clearValid();
            if (!this.name) {
                this.name_is_valid = 'This field is required';
                error_form = true;
            }
            if (!this.email) {
                this.email_is_valid = 'This field is required';
                error_form = true;
            }
            if (this.email && this.valid_email) {
                this.email_is_valid = 'Incorrect email format. Please try again';
                error_form = true;
            }
            if (this.phone.split('+').join('').trim().length < 10) {
                this.phone_is_valid = 'Phone number should contain at least 10 digits';
                error_form = true;
            }
            if (this.phone.split(' ').join('').trim().length > 13) {
                this.phone_is_valid = 'Phone number can contain 12 digits maximum.';
                error_form = true;
            }
            if (!this.message) {
                this.message_is_valid = 'This field is required';
                error_form = true;
            }
            return error_form;
        },
    },
    watch: {
        name(val) {
            this.name_is_valid = '';
            if (!val) {
                this.name_is_valid = 'This field is required';
            }
        },
        email(val) {
            this.email_is_valid = '';
            this.valid_email = helpFunctions.isValidEmail(val);
            if (!val) {
                this.email_is_valid = 'This field is required';
            }
            if (val && this.valid_email) {
                this.email_is_valid = 'Incorrect email format. Please try again';
            }
        },
        phone(val) {
            this.phone_is_valid = '';
            if (val.split('+').join('').trim().length < 10) {
                this.phone_is_valid = 'Phone number should contain at least 10 digits';
            }
            if (val.split(' ').join('').trim().length > 13) {
                this.phone_is_valid = 'Phone number can contain 12 digits maximum.';
            }
            if(!val) {
                this.phone_is_valid = 'This field is required';
            }
        },
        message(val) {
            this.message_is_valid = '';
            if (!val) {
                this.message_is_valid = 'This field is required';
            }
        },
    }
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"/>
<style lang="scss" scoped>
.contact {
    &__title {
        font-size: 27px;
        color: #171717;
        line-height: 1.2;
        margin-bottom: 10px;
    }
    &__text {
        color: #676869;
        font-size: 14px;
    }
    &__label {
        display: block;
        font-size: 14px;
        line-height: 1.4em;
        font-weight: 600;
        margin-bottom: 8px;
        color: #676869;
    }
    &__input {
        font-size: 16px;
        line-height: 14px;
        font-family: Basic Commercial, sans-serif;
        font-weight: 400;
        font-style: normal;
        background: white;
        color: #676869;
        border: 1.5px solid #d9d9da;
        padding: 0.8em 15px;
        margin: 0;
        vertical-align: middle;
        max-width: 100%;
        border-radius: 3px;
        -webkit-appearance: none;
        box-sizing: border-box;
    }
    &__submit-btn {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: black;
        border: 0.5px solid #494949;
        box-sizing: border-box;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        padding: 17px 23px;
        text-align: center;
        cursor: pointer;
        width: 100%;
        white-space: nowrap;
    }
}
</style>
