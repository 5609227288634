<template>
    <MainLayout>
        <div class="container body_active">
            <h1 class="mb-5 text-center"><strong>Terms of Use</strong></h1>
            <div class="mb-5">
                GENERAL TERMS AND CONDITIONS
            </div>
            <div class="mb-5">
                ACCEPTANCE OF TERMS AND CONDITIONS
                <br> <br>
                PLEASE READ THESE GENERAL TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS WEBSITE. THESE GENERAL TERMS
                AND CONDITIONS (THE "<b>TERMS</b>") GOVERN YOUR ACCESS TO AND USE OF THE WEBSITE. THE WEBSITE IS
                AVAILABLE FOR YOUR USE ONLY ON THE CONDITION THAT YOU AGREE TO THE TERMS SET FORTH BELOW. IF YOU DO NOT
                AGREE TO ALL OF THE TERMS, DO NOT ACCESS, OR USE THE WEBSITE. BY USING OR ACCESSING THE WEBSITE, YOU AND
                THE ENTITY YOU ARE AUTHORIZED TO REPRESENT (THE "<b>USERS</b>") ARE DEEMED TO HAVE AGREED TO COMPLY WITH
                AND TO BE BOUND BY, WITHOUT LIMITATION OR EXCEPTION, THE TERMS.
            </div>
            <div class="mb-5">
                I. TERMS OF USE
            </div>
            <div class="mb-5">
                     1. GENERAL
                <br> <br>
                The website <a href="https://www.StrobeArt.com">www.StrobeArt.com</a> (the “<b>Website</b>”) and all associated Social Media accounts, including
                without limitation Instagram™, Facebook™, YouTube™, are operated by StrobeArt LLC and provide an
                interactive online service that makes available to the end Users, as hereinafter defined, a
                photo-editing marketplace platform in exchange for payment by a business entity, influencer,
                professional body and a remittance of payment for services rendered by a photo editor and/or re-toucher.
                The Terms are designed to give the Users the information needed to navigate and use the Website.
                <br> <br>
                The Terms govern the use by the Users (defined as either “Business Entities and/or other “Professional
                Bodies”) of the Website and all applications, software and services (collectively, the "<b>Services</b>")
                available via the Website unless such Services are the subject of a separate agreement. Specific terms
                or agreements may apply to the use of certain Services and other items provided to the Users via the
                Website (the "<b>Service Agreement(s)</b>").
                <br> <br>
                The Terms sets forth the terms and conditions that apply to the use of the Website by the Users. By
                using the Website, the Users agrees to comply with all the terms and conditions hereof. The right to use
                the Website is personal to the Users and is not transferable to any other person or entity. The Users
                shall be responsible for protecting the confidentiality of its password(s), if any.
                <br> <br>
                By registering and/or participating in this Service, you agree to be contacted via email, SMS and/or
                text messaging by us, including push notifications, and by third parties if relevant, regarding our
                Services, our Site, third party notifications and/or advertisements, and requests to rate our Site
                and/or Apps.
                <br> <br>
                The Users acknowledges that, although the internet is often a secure environment, sometimes there are
                interruptions in service or events that are beyond the control of StrobeArt, and StrobeArt shall not be
                responsible for any data lost while transmitting information on the internet. While it is StrobeArt’s
                objective to make the Website accessible 24 hours per day, 7 days per week, the Website may be
                unavailable from time to time for any reason including, without limitation, routine maintenance. You
                understand and acknowledge that due to circumstances both within and outside of the control of
                StrobeArt, access to the Website may be interrupted, suspended, or terminated from time to time.
                <br> <br>
                StrobeArt shall have the right at any time to change or discontinue any aspect or feature of the
                Website, including, but not limited to, content, hours of availability and equipment needed for access
                or use.
            </div>
            <div class="mb-5">
                     2. USERS ELIGIBILITY
                <br> <br>
                The Website is available only to entities and persons over the age of legal majority who can form
                legally binding agreement(s) under applicable law, the Users must be at least 18 years of age to use
                and/or navigate the Website. The Users of the Website may only engage in services for its own use. If
                the Users decides to offer a service to someone else, it is the Users’s responsibility to make sure the
                recipient of the offer complies with the Terms. The Users, as well as the recipient, is required to
                respect the minimum age requirement in accordance with these Terms and the laws of the territory the
                Website is used in. If the Users does not qualify, he/she is not permitted to use the Website. By
                transferring, sharing, or selling its account to another person, the Users is in violation with the
                Terms and will result in the complete termination of its account. The Users will then lose all pending
                and current service privileges and will not be reimbursed. If the Users falsifies information to gain
                access and/or commit any form of fraud, appropriate legal action will be taken.
            </div>
            <div class="mb-5">
                     3. MODIFICATIONS
                <br> <br>
                StrobeArt may revise and update these Terms at any time. The continued usage of the Website after any
                changes to these Terms will mean that the Users accepts those changes. StrobeArt reserves the right to
                add, modify or delete any information or content on the Website without notice and at its sole
                discretion. StrobeArt may also change fees for services provided through the Website at any time at its
                sole discretion.
            </div>
            <div class="mb-5">
                      4. PRIVACY
                <br> <br>
                When accessing information on the Website, the Users may be asked to provide information like its name,
                address, business name, biographical photo, e-mail address or other information. It is completely
                optional for the Users to provide such information. If personal information is provided, the Users
                understands and agrees that StrobeArt may record and use the personal information in accordance with
                the <u>Privacy Policy</u> which may include certain use of this personal information.
                <br> <br>
                By using or accessing the Website, the Users agrees to the terms and conditions of the <u>Privacy
                Policy</u>. If you do not agree, do not access any of our online information or services. StrobeArt and
                its principles are not responsible for the content or privacy practices of any other website that we may
                be linked to or linked from.
                <br> <br>
                Furthermore, the Users acknowledges that all discussion for ratings, comments, bulletin board service,
                chat rooms and/or other message or communication facilities (collectively “Communities”) are public and
                not private communications, and that, therefore, others may read the Users’s communications without the
                Users’s knowledge. The Users acknowledges that StrobeArt does not control or endorse the content,
                messages or information found in any Community, and, therefore, StrobeArt specifically disclaims any
                liability concerning the Communities and any actions resulting from the Users’ participation in any
                Community, including any objectionable content. By posting comments, messages or other information on
                the Website, the Users grants StrobeArt the right to use such comments, messages or information for
                promotions, advertising, market research or any other lawful purpose without territorial, time, or other
                limitation. For more information, consult the <u>Privacy Policy</u>.
            </div>
            <div class="mb-5">
                     5. LICENSE AND OWNERSHIP
                <br> <br>
                All intellectual property rights ("<b>Intellectual Property</b>") associated with the Website and its
                contents (the "<b>Content</b>") are the sole property of StrobeArt, its affiliates or third parties. The
                Content is protected by copyright and other laws in the United States of America, Canada and other
                countries. Elements of the Website are also protected by trade dress, trade secret, unfair competition,
                and other laws and may not be copied or imitated in whole or in part. All custom graphics, icons, and
                other items that appear on the Website are trademarks, service marks or trade dress (the "<b>Marks</b>")
                of StrobeArt, its affiliates or other entities that have granted StrobeArt the right and license to use
                such Marks and may not be used or interfered with in any manner without the express written consent of
                StrobeArt. Except as otherwise expressly authorized by these Terms, the Users may not copy, reproduce,
                modify, lease, loan, sell, create derivative works from, upload, transmit, or distribute the
                Intellectual Property of the Website in any way without StrobeArt’s or the appropriate third party's
                prior written permission. Except as expressly provided herein, StrobeArt does not grant to the Users any
                express or implied rights to StrobeArt’s or any third party's Intellectual Property.
                <br> <br>
                StrobeArt grants the Users a limited, personal, nontransferable, non-sublicensable, revocable license to
                access and use only the Website, Content and Services only in the manner presented by StrobeArt. Except
                for this limited license, StrobeArt does not convey any interest in or to the information or data
                available via the Website (the “<b>Information</b>”), Content, Services or any other StrobeArt property
                by permitting the Users to access the Website. Except to the extent required by law or as expressly
                provided herein, none of the Content and/or Information may be reverse-engineered, modified, reproduced,
                republished, translated into any language or computer language, re-transmitted in any form or by any
                means, resold or redistributed without the prior written consent of StrobeArt. The Users may not make,
                sell, offer for sale, modify, reproduce, display, publicly perform, import, distribute, re-transmit, or
                otherwise use the Content in any way, unless expressly permitted to do so by StrobeArt.
                <br> <br>
                ANY COPYING, DISTRIBUTING, TRANSMITTING, POSTING, LINKING, DEEP LINKING, OR OTHERWISE MODIFYING OF THE
                WEBSITE WITHOUT THE EXPRESS WRITTEN PERMISSION OF STROBEART IS STRICTLY PROHIBITED. Any violation of
                this policy may result in a civil, copyright, trademark or other intellectual property right
                infringement that may subject the Users to civil and/or criminal penalties. Without limitation of the
                foregoing, StrobeArt reserves the right to terminate its agreement with any Users who infringes
                copyright rights upon prompt notification by StrobeArt.
            </div>
            <div class="mb-5">
                     6. TRADEMARKS
                <br> <br>
                StrobeArt is a trademark of StrobeArt LLC. All rights in respect of said trademark are hereby expressly
                reserved. Unless otherwise indicated, all other trademarks appearing on the Website are the property of
                their respective owners.
            </div>
            <div class="mb-5">
                     7. THIRD-PARTY CONTENT
                <br> <br>
                StrobeArt is an intermediary (a distributor and not a publisher) of the Content supplied by third
                parties and the Userss. Accordingly, StrobeArt has no editorial control over such Content. Any opinions,
                advice, statements, services, offers, or other information or content expressed or made available by
                third parties, including information providers, or any other Users are those of the respective author(s)
                or distributor(s) and not of StrobeArt.
                <br> <br>
                In many instances, the Content available through the Website represents the opinions and judgments of
                the respective information provider, Users, or other Users not under contract with StrobeArt. StrobeArt
                neither endorses nor is responsible for the accuracy or reliability of any opinion, advice, or statement
                made on the Website by anyone other than authorized StrobeArt employee spokespersons while acting in
                official capacities. Under no circumstances will StrobeArt be liable for any loss or damage caused by a
                Users’s reliance on information obtained through StrobeArt. It is the responsibility of the Users to
                evaluate the accuracy, completeness, or usefulness of any information, opinion, advice, etc., or other
                content available through StrobeArt.
                <br> <br>
                <b>Outbound Links</b>. The Website may contain links to third-party websites and resources (collectively,
                "<b>Linked Sites</b>"). These Linked Sites are provided solely as a convenience to the Users and not as an
                endorsement by StrobeArt of the content on such Linked Sites. StrobeArt makes no representations or
                warranties regarding the correctness, accuracy, performance or quality of any content, software,
                service, or application found at any Linked Site. StrobeArt is not responsible for the availability of
                the Linked Sites or the content or activities of such sites. If the Users decides to access Linked
                Sites, it shall do so at its own risk. In addition, the Users’s use of Linked Sites is subject to any
                applicable policies and terms and conditions of use, including but not limited to, the Linked Site's
                privacy policy.
                <br> <br>
                <b>Inbound Links</b>. Linking to any page of the Website other than to <a href="https://www.strobeart.com">https://www.strobeart.com</a>
                through a plain text link is strictly prohibited in the absence of a separate linking agreement with StrobeArt. Any
                website or other device that links to <a href="https://www.strobeart.com">https://www.strobeart.com</a> or any page available therein is
                prohibited from: (a) replicating Content, (b) using a browser or border environment around the Content,
                (c) implying in any fashion that StrobeArt or any of its affiliates are endorsing it or its services,
                (d) misrepresenting any state of facts, including its relationship with StrobeArt or any of its
                affiliates, (e) presenting false information about StrobeArt services, and (f) using any logo or mark of
                StrobeArt or any of its affiliates without express written permission from StrobeArt.
            </div>
            <div class="mb-5">
                     8. RESTRICTIONS ON USE OF THE WEBSITE
                <br> <br>
                In addition to other restrictions set forth in these Terms, the Users agrees that:
                <br> <br>
                <ul>
                    <li>It shall not disguise the origin of information transmitted through the Website.</li>
                    <li>It will not place false or misleading information on the Website.</li>
                    <li>It will not use or access any service, information, application, or software available via the Website in a manner not expressly permitted by StrobeArt.</li>
                    <li>It will not input or upload to the Website any information which contains viruses, Trojan horses, worms, time
                        bombs or other computer programming routines that are intended to damage, interfere with, intercept, or expropriate
                        any system, the Website or Information or that infringes the Intellectual Property rights of another.</li>
                    <li>Certain areas of the Website are restricted to customers of StrobeArt.</li>
                    <li>It may not use or access the Website or the StrobeArt systems or Services in any way that, in StrobeArt’s judgement,
                        adversely affects the performance or function of the StrobeArt systems, Services or the Website or interferes with
                        the ability of authorized parties to access the StrobeArt systems, Services or the Website.</li>
                    <li>It may not frame or utilize framing techniques to enclose any portion or aspect of the Content or the Information, without the express written consent of StrobeArt.</li>
                </ul>
            </div>
            <div class="mb-5">
                     9. SUBMISSIONS
                <br> <br>
                StrobeArt does not accept ideas, concepts, or techniques for new services through the Website
                ("<b>Comments</b>"). If such Comments are received, the Users acknowledges that (a) they will not be considered
                confidential or proprietary, (b) StrobeArt and its affiliates are under no obligation to keep such
                information confidential, and (c) StrobeArt will have an unrestricted, irrevocable, world-wide, royalty
                free right to use, communicate, reproduce, publish, display, distribute and exploit such Comments in any
                manner it chooses.
            </div>
            <div class="mb-5">
                     10. DISCLAIMER OF WARRANTY / LIMITATION OF LIABILITY
                <br> <br>
                Although StrobeArt strives to update and keep accurate as much as possible the Content contained on the
                Website, errors and/or omissions may occur. THE CONTENT CONTAINED IN THE WEBSITE OR WHICH MAY BE
                DOWNLOADED FROM THE WEBSITE IS PROVIDED "AS IS" AND "AS AVAILABLE". As such, StrobeArt makes no warranty
                or representation regarding the quality, accuracy or completeness of such Content and expressly
                disclaims liability for errors and/or omissions in said Content.
                <br> <br>
                THE USERS EXPRESSLY AGREES THAT USE OF THE WEBSITE IS AT ITS SOLE RISK. NEITHER STROBEART, ITS
                AFFILIATES NOR ANY OF THEIR RESPECTIVE EMPLOYEES, AGENTS, AFFILIATED PARTNERS, THIRD-PARTY CONTENT
                PROVIDERS OR LICENSORS, OR ANY OF THEIR OFFICERS, DIRECTORS, EMPLOYEES OR AGENTS, WARRANT THAT USE OF
                THE WEBSITE WILL BE UNINTERRUPTED OR ERROR FREE; NOR DO THEY MAKE ANY WARRANTY AS TO (I) THE RESULTS
                THAT MAY BE OBTAINED FROM USE OF THE WEBSITE, OR (II) THE ACCURACY, RELIABILITY OR CONTENT OF ANY
                INFORMATION, SERVICE OR VOUCHERS PROVIDED THROUGH THE WEBSITE.
                <br> <br>
                IN NO EVENT SHALL STROBEART BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL, EXEMPLARY
                OR CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER, EVEN IF STROBEART HAVE BEEN PREVIOUSLY ADVISED OF
                THE POSSIBILITY OF SUCH DAMAGES, WHETHER IN AN ACTION UNDER CONTRACT, NEGLIGENCE, CIVIL LIABILITY, OR
                ANY OTHER THEORY ARISING OUT OF OR IN CONNECTION WITH THE USE, INABILITY TO USE, OR PERFORMANCE OF THE
                INFORMATION, SERVICES, PRODUCTS, AND MATERIALS AVAILABLE FROM THE WEBSITE.
                <br> <br>
                The Internet is not yet a fully secure medium, and therefore confidentiality and privacy of the
                information transmitted thereon cannot be ensured. StrobeArt will not be liable for any damages
                resulting from the transmission of confidential or personal information. (Please refer to the <u>Privacy
                Policy</u>).
                <br> <br>
                StrobeArt offers no guarantee of the longevity of the Website and reserve the right to terminate it at
                any moment, at its sole discretion and without prior notice. StrobeArt assumes no responsibility for any
                prejudice resulting from the termination of the Website.
            </div>
            <div class="mb-5">
                     11. AVAILABILITY OF THE SITE
                <br> <br>
                At any time, StrobeArt reserves the right to suspend or interrupt the Website functioning as well as
                deny access to anyone, even members, for any reason whatsoever. As a reasonable service company,
                StrobeArt will always strive to provide the highest of customer satisfactory experience and have the
                Website accessible but wishes to inform the Users that occasional Website downtimes or maintenance time
                may occur.
                <br> <br>
                Because StrobeArt does not control what is being visited and/or downloaded on the Users’s devices or
                on/from the Internet, StrobeArt is not responsible for any data lost or the security of information that
                the Users may choose to communicate through the Website.
            </div>
            <div class="mb-5">
                     12. INDEMNIFICATION / RELEASE.
                <br> <br>
                The Users agrees to defend, indemnify, and hold harmless StrobeArt, its affiliates and their respective
                directors, officers, employees and agents from and against all claims and expenses, including attorneys’
                fees, arising out of or related to any products or services purchased by the Users in connection with
                the Website.
            </div>
            <div class="mb-5">
                     13. TERMINATION
                <br> <br>
                The Users agrees that StrobeArt may, at its sole discretion, terminate or suspend the use of the
                Website, Information, Services and Content at any time and for any or no reason, even if access and use
                continue to be allowed to others. Upon such suspension or termination, the Users must immediately (a)
                discontinue use of the Website, and (b) destroy any copies it has made of any portion of the Content.
                Accessing the Website, Information or Services after such termination, suspension or discontinuation
                shall constitute an act of violation of these Terms. Furthermore, the Users agrees that StrobeArt shall
                not be liable for any termination or suspension of the Users’s access to the Website, Information and/or
                the Services. Provisions that are intended to remain valid after the termination of this Agreement will
                survive termination of this Agreement.
            </div>
            <div class="mb-5">
                     14. COMPLIANCE WITH LAW
                <br> <br>
                The Users agrees to use the Website in strict compliance with all applicable laws, rulings, and
                regulations and in a fashion that does not, in the sole judgment of StrobeArt, negatively reflect on the
                goodwill or reputation of StrobeArt and shall take no actions which would cause StrobeArt to be in
                violation of any laws, rulings or regulations applicable to StrobeArt.
                <br> <br>
                The Users may not upload to, distribute, or otherwise publish through the Website any content which is
                libelous, defamatory, obscene, threatening, invasive of privacy or publicity rights, abusive, illegal,
                or otherwise objectionable, or which may constitute or encourage a criminal offense, violate the rights
                of any party, or otherwise give rise to liability or violate any law. The Users may not upload
                commercial content on our website or use our website to solicit others to join or become members of any
                commercial online service or other organization.
                <br> <br>
                StrobeArt and the Website are based in The United States of America. The United States of America and
                certain other jurisdictions control the export of information. The Users agrees to comply with all such
                applicable restrictions and not to export or re-export the Content (including any software or the
                Services) to countries or persons prohibited under the United States of America or other applicable
                export control laws or regulations. If the Users accesses and downloads the Content (including any
                software or the Services) or Information, it represents that it is not in a country where such export is
                prohibited or are not a person or entity to which such export is prohibited. The Users is solely
                responsible for compliance with the laws of its local jurisdiction and any other applicable laws
                regarding the import, export, or re-export of the Content (including any software or the Services).
            </div>
            <div class="mb-5">
                     15. APPLICABLE LAW AND JURISDICTION
                <br> <br>
                These Terms are governed by and construed in accordance with the laws of the state of California and of
                the laws of the United States of America applicable therein, and any dispute is to be submitted to a
                court of competent jurisdiction in the judicial district of Los Angeles, California. If any provision of
                the present Terms shall be unlawful, void, or for any reason unenforceable, then such provision shall be
                severable from these Terms and shall not affect the validity and enforceability of any remaining
                provisions.
            </div>
            <div class="mb-5">
                     16. ENTIRE AGREEMENT
                <br> <br>
                Except as expressly provided in a separate license, service, or other written agreement between the
                Users and StrobeArt, these Terms constitute the entire agreement between the Users and StrobeArt with
                respect to the use of the Website, and any software or Service, Information and Content contained
                therein, and supersede all discussions, communications, conversations and agreements concerning the
                subject matter hereof.
            </div>
            <div class="mb-5">
                     17. CUSTOMER SERVICE
                <br> <br>
                Questions or comments regarding the Website may be submitted to StrobeArt Customer Service at the following link.
            </div>
        </div>
    </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";

export default {
    name: "views",
    components: {
        MainLayout,
    },
    data() {
        return {}
    },
}
</script>

<style scoped>
.body_active {
    margin-bottom: 70px;
}
</style>
