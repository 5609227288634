<template>
    <MainLayout :is-show-header=true>
        <div class="info">
            <div class="info__title">How to connect your Facebook</div>
            <div class="row">
                <div class="col-lg-6 offset-lg-3">
                    <div class="info__step">
                        <div class="step__text">
                            1. Under <router-link :to="{name: 'Jobs'}">Jobs</router-link> >
                            <router-link :to="{name: 'PastJobs'}"> Past Jobs</router-link> >
                            Select any jobs you would like to push to Social Platforms
                            <ul>
                                <li>Select which image(s) you would like to push to Social (can select more than one)</li>
                                <li>Accept</li>
                                <li>Under “Upload To” → Select Instagram</li>
                            </ul>
                        </div>
                    </div>
                    <div class="info__step">
                        <div class="step__text">
                            2. You will be prompted to connect your Instagram account
                        </div>
                        <div class="step__image">
                            <img src="@/assets/images/HowConnectFacebook/step_2.png" alt="step 2" style="max-width:100%;">
                        </div>
                    </div>
                    <div class="info__step">
                        <div class="step__text">
                            3. A new tab will open which redirects to Facebook’s Client OAuth Login.
                        </div>
                        <div class="step__image">
                            <img src="@/assets/images/HowConnectFacebook/step_3.png" alt="step 3" style="max-width:100%;">
                        </div>
                    </div>
                    <div class="info__step">
                        <div class="step__text">
                            4. Enter your Login Credentials<br>
                            <em>
                                Please note than In order to successfully link with StrobeArt and activate auto-posting, you'll need
                                to have an Instagram Business Account connected to a Facebook Business Page. <br> <br>
                                Instagram is a company owned by Meta (Facebook), therefore in order to use Instagram business features and
                                activate auto-posting, you must have an Instagram business account and authenticate with a Facebook Page.
                                If you haven’t created a Facebook page yet, we recommend creating one first in order to successfully link your Instagram account to StrobeArt.
                            </em>
                        </div>
                        <div class="step__image">
                            <img src="@/assets/images/HowConnectFacebook/step_4_1.png" alt="step 4 (1)" style="max-width:100%;">
                        </div>
                        <div class="step__image mt-3">
                            <img src="@/assets/images/HowConnectFacebook/step_4_2.png" alt="step 4 (2)" style="max-width:100%;">
                        </div>
                    </div>
                    <div class="step__info">
                        <div class="step__text">
                            5. You will then be redirected to your StrobeArt Profile page (<a href="https://strobeart.com/app/profile">https://strobeart.com/app/profile</a>)
                            <ul>
                                <li>
                                    Refresh your Page to see the newly added (Instagram) connected account
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </MainLayout>
</template>

<script>
import MainLayout from "../layouts/MainLayout"

export default {
    name: 'HowFacebookConnect',
    components: {
        MainLayout,
    },
}
</script>

<style lang="scss" scoped>
.info {
    &__title {
        font-size: 48px;
        text-align: center;
    }
    &__step {
        margin-bottom: 25px;
    }
    &__text {
        font-size: 18px;
    }
}
.step {
    &__image {
        img {
            max-width: 1000px;
            max-height: 500px;
        }
    }
}
</style>
