<template>
    <MainLayout :is-show-header=true>
        <div class="info">
            <div class="info__title">Approval Process</div>
            <div class="row mt-3">
                <div class="col-lg-8 offset-lg-2 col-12">
                    <div class="ps-2 pe-2">
                        Complete transparency with the StrobeArt dashboard and real-time notifications.
                        Customize image retouching to your specific brand standards. Tailor the process of editing and enhancing images to match your brand’s unique guidelines.
                        Arrange the timing for when you want your images retouched.
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-8 offset-lg-2 col-12">
                    <div class="items-container">
                        <div class="item">
                            <div class="icon-box">
                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" class="item__share-icon" viewBox="0 0 244.000000 226.000000" preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,226.000000) scale(0.100000,-0.100000)" fill="#ACACAC" stroke="none">
                                        <path d="M1572 2030 c-51 -31 -62 -71 -62 -215 l0 -125 -178 0 c-183 0 -285-15 -359 -54 -69 -35 -153 -110 -197 -174 -63 -92 -88 -166 -94 -282 -9 -180 33 -287 162 -415 130 -129 240 -181 285 -136 36 36 28 75 -28 129 -38 37 -51 58 -60 99 -29 120 42 262 162 325 36 19 61 23 175 26 l132 4 0 -126 c0 -137 11 -181 51 -210 31 -22 117 -21 149 1 29 20 529 470 547 493 7 8 18 31 24 51 23 73 11 88 -279 350 -147 133 -280 250 -297 260 -39 25 -92 24 -133 -1z"/>
                                        <path d="M405 1916 c-66 -21 -101 -43 -139 -87 -73 -86 -71 -66 -71 -804 l0 -660 33 -67 c36 -73 80 -115 153 -145 41 -17 89 -18 714 -18 l670 0 57 28 c69 34 121 92 145 161 23 65 26 308 5 348 -43 79 -173 75 -208 -7 -9 -23 -14 -72 -14 -142 0 -82 -4 -114 -16 -131 l-15 -22 -625 0 c-611 0 -624 0 -644 20 -20 20 -20 33 -20 639 0 594 1 620 19 642 18 23 27 24 143 27 69 1 133 8 146 14 58 30 70 127 23 180 l-29 33 -144 2 c-96 2 -156 -2 -183 -11z"/>
                                    </g>
                                </svg>
                                <svg id="step1" width="58px" height="51px" viewBox="0 0 58 51" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <defs>
                                        <filter x="0.0%" y="0.0%" width="100.0%" height="100.0%" filterUnits="objectBoundingBox" id="filter-1">
                                            <feGaussianBlur stdDeviation="0" in="SourceGraphic"/>
                                        </filter>
                                    </defs>
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g transform="translate(-6.000000, -102.000000)">
                                            <g transform="translate(7.000000, 103.000000)">
                                                <path d="M16,4 L34,4 C46.7025492,4 57,14.2974508 57,27 L57,45 C57,47.7614237 54.7614237,50 52,50 L16,50 C13.2385763,50 11,47.7614237 11,45 L11,9 C11,6.23857625 13.2385763,4 16,4 Z" fill="#CDCDCD" opacity="0.258928571" filter="url(#filter-1)" transform="translate(34.000000, 27.000000) rotate(-90.000000) translate(-34.000000, -27.000000) "/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <h3>Share Your Image Requirements</h3>
                            <p class="item__text">
                                Fill out all necessary fields to ensure that the photo editor delivers the images to meet your expectations.
                            </p>
                            <img v-if="!showJobDetailsMob" class="item__arrow" src="@/assets/icons/hand_down_arrow.svg" alt="">
                        </div>
                        <div class="item">
                            <div class="icon-box">
                                <svg version="1.0" class="item__progress-bar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 284.000000 215.000000" preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,215.000000) scale(0.100000,-0.100000)" fill="#ACACAC" stroke="none">
                                        <path d="M655 1765 c-60 -16 -109 -65 -125 -126 -7 -26 -10 -98 -8 -183 3 -128 5 -145 27 -184 18 -30 39 -49 75 -67 l50 -25 810 0 c767 0 813 1 851 19 46 21 79 54 94 94 6 16 11 99 11 188 0 139 -3 165 -20 198 -26 52 -60 77 -121 90 -79 17 -1579 14 -1644 -4z m1610 -285 l0 -115 -272 -3 -273 -2 0 120 0 120 273 -2 272 -3 0 -115z"/>
                                        <path d="M625 916 c-82 -36 -109 -116 -103 -311 4 -160 21 -200 102 -240 l50 -25 810 0 c767 0 813 1 851 19 46 21 79 54 94 94 6 16 11 98 11 186 0 143 -2 161 -22 201 -15 30 -37 53 -63 67 l-40 23 -830 -1 c-668 0 -836 -2 -860 -13z m1640 -276 l0 -115 -512 -3 -513 -2 0 113 c0 63 3 117 7 120 3 4 234 6 512 5 l506 -3 0 -115z"/>
                                    </g>
                                </svg>
                                <svg id="step2" width="54px" height="53px" viewBox="0 0 54 53" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <defs>
                                        <filter x="0.0%" y="0.0%" width="100.0%" height="100.0%" filterUnits="objectBoundingBox" id="filter-1">
                                            <feGaussianBlur stdDeviation="0" in="SourceGraphic"/>
                                        </filter>
                                    </defs>
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g transform="translate(-349.000000, -196.000000)">
                                            <g transform="translate(350.000000, 197.000000)">
                                                <path d="M12,6 L30,6 C42.7025492,6 53,16.2974508 53,29 L53,47 C53,49.7614237 50.7614237,52 48,52 L30,52 C17.2974508,52 7,41.7025492 7,29 L7,11 C7,8.23857625 9.23857625,6 12,6 Z" fill="#92C36A" opacity="0.17561849" filter="url(#filter-1)" transform="translate(30.000000, 29.000000) rotate(-90.000000) translate(-30.000000, -29.000000) "/>
                                                <g class="step2-1" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
                                                    <path d="M33.7708333,7.52083333 L33.7708333,32.3125 C33.7708333,33.1179153 33.1179153,33.7708333 32.3125,33.7708333 L7.52083333,33.7708333"/>
                                                    <path d="M30.8541667,3.14583333 L30.8541667,29.3958333 C30.8541667,30.2012486 30.2012486,30.8541667 29.3958333,30.8541667 L3.14583333,30.8541667"/>
                                                    <polygon id="Path" points="0.229166667 0.229166667 27.9375 0.229166667 27.9375 27.9375 0.229166667 27.9375"/>
                                                    <path d="M24.2916667,22.1041667 L19.1102083,11.74125 C18.7514583,11.02375 18.1652083,11.02375 17.8064583,11.74125 L14.0833333,19.1875 L11.3708333,15.9325 C11.1752836,15.6433972 10.8396475,15.48145 10.4916535,15.5082884 C10.1436595,15.5351267 9.83682462,15.7466233 9.68791667,16.0622917 L6.0625,22.1041667"/>
                                                    <path d="M6.0625,6.79166667 C6.0625,7.99978956 7.04187711,8.97916667 8.25,8.97916667 C9.45812289,8.97916667 10.4375,7.99978956 10.4375,6.79166667 C10.4375,5.58354378 9.45812289,4.60416667 8.25,4.60416667 C7.04187711,4.60416667 6.0625,5.58354378 6.0625,6.79166667 Z"/>
                                                    <line x1="27.9375" y1="22.1041667" x2="0.229166667" y2="22.1041667"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <h3>Track the Progress of your job</h3>
                            <p class="item__text">
                                Communicate with your photo retoucher through chat, check in real-time how many images
                                have been edited and ready for review, approve the work if satisfied
                            </p>
                            <img v-if="!showJobDetailsMob" class="item__arrow" src="@/assets/icons/hand_down_arrow.svg" alt="">
                        </div>
                        <div class="item">
                            <div class="icon-box">
                                <svg class="item__image-upload" version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 335.000000 320.000000" preserveAspectRatio="xMidYMid meet">
                                    <g transform="translate(0.000000,320.000000) scale(0.100000,-0.100000)" fill="#ACACAC" stroke="none">
                                        <path d="M370 2350 l0 -110 40 0 40 0 0 75 0 75 860 0 860 0 0 -241 0 -240 38 3 37 3 3 273 2 272 -940 0 -940 0 0 -110z"/>
                                        <path d="M1870 2275 c0 -34 1 -35 40 -35 39 0 40 1 40 35 0 34 -1 35 -40 35 -39 0 -40 -1 -40 -35z"/>
                                        <path d="M2020 2275 c0 -34 1 -35 40 -35 39 0 40 1 40 35 0 34 -1 35 -40 35 -39 0 -40 -1 -40 -35z"/>
                                        <path d="M835 2183 c-68 -36 -105 -95 -105 -168 0 -104 81 -185 185 -185 58 0 116 29 151 76 52 68 41 190 -22 245 -56 49 -150 64 -209 32z m160 -93 c24 -25 30 -39 30 -75 0 -37 -6 -50 -33 -77 -85 -86 -224 5 -178 116 32 78 121 96 181 36z"/>
                                        <path d="M370 1525 l0 -635 655 0 655 0 4 -22 c32 -164 52 -216 121 -314 143 -200 409 -296 649 -234 349 89 553 450 447 790 -77 247 -311 425 -566 433 l-80 2 -5 70 -5 70 -37 3 -38 3 0 -79 0 -79 -42 -13 c-57 -17 -132 -55 -192 -97 -40 -29 -51 -32 -61 -21 -7 7 -85 105 -175 218 -90 113 -168 211 -175 218 -9 10 -45 -30 -174 -193 -90 -113 -166 -205 -170 -204 -3 0 -46 47 -94 105 -49 57 -92 104 -97 104 -5 0 -127 -138 -272 -307 l-263 -307 -3 562 -2 562 -40 0 -40 0 0 -635z m1305 5 c76 -96 142 -181 148 -187 6 -9 1 -23 -16 -47 -58 -75 -97 -174 -122 -308 -5 -26 -9 -28 -49 -28 l-44 0 -178 206 c-168 196 -176 208 -163 229 8 12 70 92 138 178 99 124 127 155 137 145 6 -7 74 -92 149 -188z m-432 -280 l245 -285 -249 -3 c-137 -1 -361 -1 -498 0 l-249 3 247 287 c135 158 249 286 253 285 4 -1 117 -130 251 -287z m1179 209 c188 -39 356 -197 412 -388 20 -67 21 -217 2 -286 -50 -190 -216 -351 -406 -396 -181 -42 -378 16 -510 150 -117 118 -166 246 -157 411 12 245 171 440 408 504 65 18 179 20 251 5z"/>
                                        <path d="M2195 1140 l-110 -110 28 -27 27 -27 65 64 65 64 0 -152 0 -152 35 0 35 0 0 150 c0 83 3 150 7 150 4 0 36 -28 70 -62 l63 -62 25 24 25 25 -113 113 -112 112 -110 -110z"/>
                                        <path d="M2050 685 l0 -75 255 0 255 0 0 75 0 75 -40 0 -40 0 0 -40 0 -40 -175 0 -175 0 0 40 0 40 -40 0 -40 0 0 -75z"/>
                                        <path d="M2170 1800 l0 -41 38 3 c35 3 37 5 37 38 0 33 -2 35 -37 38 l-38 3 0 -41z"/>
                                    </g>
                                </svg>
                                <svg id="step3" width="57px" height="51px" viewBox="0 0 57 51" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                    <defs>
                                        <filter x="0.0%" y="0.0%" width="100.0%" height="100.0%" filterUnits="objectBoundingBox" id="filter-1">
                                            <feGaussianBlur stdDeviation="0" in="SourceGraphic"/>
                                        </filter>
                                    </defs>
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g transform="translate(-351.000000, -323.000000)">
                                            <g transform="translate(352.000000, 324.000000)">
                                                <path d="M15,4 L33,4 C45.7025492,4 56,14.2974508 56,27 L56,45 C56,47.7614237 53.7614237,50 51,50 L15,50 C12.2385763,50 10,47.7614237 10,45 L10,9 C10,6.23857625 12.2385763,4 15,4 Z" fill="#DADADA" opacity="0.258928571" filter="url(#filter-1)" transform="translate(33.000000, 27.000000) rotate(-270.000000) translate(-33.000000, -27.000000) "/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <h3>Push Approved Images to Your Social Platforms</h3>
                            <p class="item__text">Post high-quality images on your social media channels to improve conversion rates</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <div class="info-gif-container">
                        <img class="info-gif" src="@/assets/images/ApprovalProcess/AdobeStock_487965398_1.gif" alt="">
                        <img class="info-gif mt-3 mt-lg-0" src="@/assets/images/ApprovalProcess/AdobeStock_167409022.gif" alt="">
                    </div>
                </div>
            </div>
        </div>
    </MainLayout>
</template>

<script>
import MainLayout from "../layouts/MainLayout"

export default {
    name: 'ApprovalProcess',
    components: {
        MainLayout,
    },
    computed: {
        showJobDetailsMob() {
            return window.innerWidth <= 992
        },
    }
}
</script>

<style lang="scss" scoped>
.info {
    margin-bottom: 200px;

    &__title {
        font-size: 48px;
        text-align: center;
    }
    &__step {
        margin-bottom: 25px;
    }
    &__text {
        font-size: 18px;
    }
    &__image {
        max-height: 500px;
    }
}
.items-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 992px) {
        flex-direction: column;
    }
    .item {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: center;
        width: 365px;
        height: 385px;
        padding: 30px;
        box-shadow: 0 40px 40px -6px rgba(22, 28, 39, 0.09);

        &__text {
            height: 120px;
            position: relative;
            top: 28px;
        }
        &__arrow {
            width: 75px;
            position: relative;
            top: -300px;
            right: -300px;
            transform: scale(-1,1) rotate(138deg);
        }
        &__image-upload {
            width: 65px;
            position: relative;
            right: -50px;
            top: -13px;
        }
        &__share-icon {
            width: 47px;
            position: relative;
            right: -45px;
            top: -10px;
        }
        &__progress-bar {
            width: 60px;
            position: relative;
            right: -45px;
            top: -8px;
        }
    }
}
.info-gif {
    max-width: 400px;

    &-container {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        @media only screen and (max-width: 992px) {
            align-items: center;
            flex-direction: column;
        }
    }
}
.step {
    &__image {
        img {
            max-width: 1000px;
            max-height: 500px;
        }
    }
}
</style>
