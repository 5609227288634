<template>
    <MainLayout :is-show-header=true>
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-12 text-center">
                <h2 class="faq__title">Welcome to StrobeArt 101</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8 offset-lg-2 col-12 text-center">
                <p class="faq__subtitle">
                    Discover the full potential of StrobeArt with our comprehensive video tutorials. If you are just getting started with StrobeArt, you'll find everything you need to know to become an expert on this page.
                </p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-8 offset-lg-2 col-12">
                <video class="faq__video" controls="controls" src="@/assets/video/Strobeart_FAQ.mp4"/>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-8 offset-lg-2 col-12">
                <div class="faq__item-group-title">
                    Account Linking
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-8 offset-lg-2 col-12 d-flex flex-column flex-lg-row justify-content-between align-items-center">
                <router-link :to="{name:'HowFacebookConnect'}" class="faq__item item">
                    <div class="item__title">Instagram</div>
                    <img class="item__logo" src="@/assets/icons/Instagram_logo_2016.png" alt="">
                </router-link>
                <router-link :to="{name:'HowShopifyConnect'}" class="faq__item item">
                    <div class="item__title">Shopify</div>
                    <img class="item__logo" src="@/assets/icons/shopify_logo.png" alt="">
                </router-link>
                <router-link :to="{name:'HowAmazonConnect'}" class="faq__item item">
                    <div class="item__title">Amazon</div>
                    <img class="item__logo" src="@/assets/icons/Amazon_logo.png" alt="">
                </router-link>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-8 offset-lg-2 col-12">
                <div class="faq__item-group-title">
                    Approval Process
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-8 offset-lg-2 col-12 d-flex flex-column flex-lg-row justify-content-between align-items-center">
                <router-link :to="{name:'ApprovalProcess'}" class="faq__item item">
                    <div class="item__title">Approval Process</div>
                </router-link>
            </div>
        </div>
        <div class="margin-bottom"/>
    </MainLayout>
</template>

<script>
import MainLayout from '../layouts/MainLayout';

export default {
    name: 'FAQ',
    components: {
        MainLayout,
    },
}
</script>

<style lang="scss" scoped>
$roboto_font_family: 'Roboto', sans-serif;
.faq {
    &__title {
        font-family: $roboto_font_family;
    }
    &__subtitle {
        text-align: justify;
    }
    &__video {
        width: 100%;
        max-width: 100%;
        max-height: 600px;
    }
    &__item-group-title {
        font-size: 32px;
        font-family: $roboto_font_family;
    }
    &__item {
        width: 300px;
        padding: 15px;
        border: 1px solid #d5e0d5;
        border-radius: 10px;
    }
}
.item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: #212529;

    &__title {
        font-size: 24px;
    }
    &__logo {
        max-width: 35px;
    }
}
.margin-bottom {
    margin-bottom: 200px;
}
</style>
