<template>
    <FluidLayout>
        <div class="container-fluid body_active">
            <div v-if="page_loaded" class="row">
                <div class="col-12 plans-container">
                    <div class="plan plan_third">
                        <div class="plan__price"/>
                        <div class="plan__body">
                            <div class="plan__title">Pay as You Go</div>
                            <div class="plan__items">
                                <!--<div class="plan__item plan__item_bold">-->
                                <!--    <img src="@/assets/icons/Oval.svg" class="plan__item-point">-->
                                <!--    1001-and up edited pictures a month-->
                                <!--</div>-->
                                <div class="plan__item plan__item_light">
                                    <img src="@/assets/icons/Oval.svg" class="plan__item-point">
                                    Basic Retouching ${{plans.NO.retouching.Simple / 100}}/image
                                </div>
                                <div class="plan__item plan__item_light">
                                    <img src="@/assets/icons/Oval.svg" class="plan__item-point">
                                    Intermediate Retouching ${{plans.NO.retouching.Intermediate / 100}}/image
                                </div>
                                <div class="plan__item plan__item_light">
                                    <img src="@/assets/icons/Oval.svg" class="plan__item-point">
                                    Detailed Retouching ${{plans.NO.retouching.Advanced / 100}}/image
                                </div>
                            </div>
                        </div>
                        <div class="plan__footer">
                            <div class="plan__button">
                                <router-link :to="{ name: 'Settings', query: { tab: 'membership' }}">Choose Plan</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="plan plan_first">
                        <div class="plan__price">
                            ${{plans.BASIC.price / 100}}/month
                        </div>
                        <div class="plan__body">
                            <div class="plan__title">Basic Plan</div>
                            <div class="plan__items">
                                <div class="plan__item plan__item_bold">
                                    <img src="@/assets/icons/Oval.svg" class="plan__item-point">
                                    0-100 edited pictures a month
                                </div>
                                <div class="plan__item plan__item_light">
                                    <img src="@/assets/icons/Oval.svg" class="plan__item-point">
                                    Basic Retouching ${{plans.BASIC.retouching.Simple / 100}}/image
                                </div>
                                <div class="plan__item plan__item_light">
                                    <img src="@/assets/icons/Oval.svg" class="plan__item-point">
                                    Intermediate Retouching ${{plans.BASIC.retouching.Intermediate / 100}}/image
                                </div>
                                <div class="plan__item plan__item_light">
                                    <img src="@/assets/icons/Oval.svg" class="plan__item-point">
                                    Detailed Retouching ${{plans.BASIC.retouching.Advanced / 100}}/image
                                </div>
                            </div>
                        </div>
                        <div class="plan__footer">
                            <div class="plan__button">
                                <router-link :to="{ name: 'Settings', query: { tab: 'membership' }}">Choose Plan</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="plan plan_second">
                        <div class="plan__price">
                            ${{plans.INTERMEDIATE.price / 100}}/month
                        </div>
                        <div class="plan__body">
                            <div class="plan__title">Intermediate Plan</div>
                            <div class="plan__items">
                                <div class="plan__item plan__item_bold">
                                    <img src="@/assets/icons/Oval-white.svg" class="plan__item-point">
                                    101-1000 edited pictures a month
                                </div>
                                <div class="plan__item plan__item_light">
                                    <img src="@/assets/icons/Oval-white.svg" class="plan__item-point">
                                    Basic Retouching ${{plans.INTERMEDIATE.retouching.Simple / 100}}/image
                                </div>
                                <div class="plan__item plan__item_light">
                                    <img src="@/assets/icons/Oval-white.svg" class="plan__item-point">
                                    Intermediate Retouching ${{plans.INTERMEDIATE.retouching.Intermediate / 100}}/image
                                </div>
                                <div class="plan__item plan__item_light">
                                    <img src="@/assets/icons/Oval-white.svg" class="plan__item-point">
                                    Detailed Retouching ${{plans.INTERMEDIATE.retouching.Advanced / 100}}/image
                                </div>
                            </div>
                        </div>
                        <div class="plan__footer">
                            <div class="plan__button">
                                <router-link :to="{ name: 'Settings', query: { tab: 'membership' }}">Choose Plan</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="plan plan_third">
                        <div class="plan__price">
                            ${{plans.EXPERT.price / 100}}/ month
                        </div>
                        <div class="plan__body">
                            <div class="plan__title">Expert Plan</div>
                            <div class="plan__items">
                                <div class="plan__item plan__item_bold">
                                    <img src="@/assets/icons/Oval.svg" class="plan__item-point">
                                    1001-and up edited pictures a month
                                </div>
                                <div class="plan__item plan__item_light">
                                    <img src="@/assets/icons/Oval.svg" class="plan__item-point">
                                    Basic Retouching ${{plans.EXPERT.retouching.Simple / 100}}/image
                                </div>
                                <div class="plan__item plan__item_light">
                                    <img src="@/assets/icons/Oval.svg" class="plan__item-point">
                                    Intermediate Retouching ${{plans.EXPERT.retouching.Intermediate / 100}}/image
                                </div>
                                <div class="plan__item plan__item_light">
                                    <img src="@/assets/icons/Oval.svg" class="plan__item-point">
                                    Detailed Retouching ${{plans.EXPERT.retouching.Advanced / 100}}/image
                                </div>
                            </div>
                        </div>
                        <div class="plan__footer">
                            <div class="plan__button">
                                <router-link :to="{ name: 'Settings', query: { tab: 'membership' }}">Choose Plan</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="plan plan_fourth">
                        <div class="plan__price">
                            Special price
                        </div>
                        <div class="plan__body">
                            <div class="plan__title">Enterprise Plan</div>
                            <div class="plan__items">
                                <div class="plan__item plan__item_bold">
                                    <img src="@/assets/icons/Oval.svg" class="plan__item-point">
                                    Unlimited edited pictures a month
                                </div>
                                <div class="plan__item plan__item_light">
                                    <img src="@/assets/icons/Oval.svg" class="plan__item-point">
                                    Please message us for special pricing
                                </div>
                            </div>
                        </div>
                        <div class="plan__footer">
                            <div class="plan__button">
                                <a href="mailto:info@strobeart.com">Contact Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </FluidLayout>
</template>

<script>
import FluidLayout from "@/layouts/FluidLayout";
import { mapMutations } from 'vuex';

export default {
    name: 'Prices',
    components: {
        FluidLayout,
    },
    data(){
        return {
            page_loaded: false,
            plans: [],
        }
    },
    async mounted() {
        await this.getPlans();
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        async getPlans() {
            try {
                this.showLoader();
                const response = await this.$http.get(`${this.$http.apiUrl()}get-plans-prices`);
                if (response.data.data) {
                    this.plans = response.data.data;
                    this.page_loaded = true;
                }
            } catch (e) {
            }
            this.hideLoader();
        },
    }
}
</script>

<style lang="scss" scoped>
.plans-container {
    margin-bottom: 200px;

    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 992px) {
        flex-direction: column;
        align-items: center;
    }
}
.plan {
    width: 325px;
    padding: 33px 42px 42px 42px;

    border-radius: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (max-width: 1199px) {
        margin-left: 10px;
        margin-right: 10px;
        padding-left: 30px;
        padding-right: 30px;
    }

    @media only screen and (max-width: 992px) {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &_first {
        background: linear-gradient(135deg, #FFCFCF, white, white, white);
        box-shadow: 0px 12px 32px rgba(0, 22, 35, 0.0817482);
        color: #111111;

        .plan__item_light {
            color: rgba(17, 17, 17, 0.5);
        }
    }
    &_second {
        background: linear-gradient(180deg, #E27582 0%, #FEC0C8 99.5%), #C39787;
        box-shadow: 0px 12px 32px rgba(0, 22, 35, 0.16);
        color: white;

        .plan__item_light {
            color: rgba(255, 255, 255, 0.502972);
        }
    }
    &_third {
        background: linear-gradient(315deg, #FFCFCF, white, white, white);
        box-shadow: 0px 12px 32px rgba(0, 22, 35, 0.0817482);
        color: #111111;

        .plan__item_light {
            color: rgba(17, 17, 17, 0.5);
        }
    }
    &_fourth {
        background: linear-gradient(225deg, #FFCFCF, white, white, white);
        box-shadow: 0px 12px 32px rgba(0, 22, 35, 0.0817482);
        color: #111111;

        .plan__item_light {
            color: rgba(17, 17, 17, 0.5);
        }
    }
    &__price {
        font-weight: 500;
        font-size: 28px;
        line-height: 64px;
        letter-spacing: -2px;
    }
    &__title {
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 13px;
    }
    &__item {
        font-weight: 400;
        font-size: 14px;
        line-height: 32px;

        &_bold {
            font-weight: 500;
        }
        &-point {
            margin-right: 5px;
        }
    }
    &__footer {
        margin-top: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__button {
        width: 145px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;

        background: #FCF5EF;
        border: 0.5px solid #494949;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 100px;

        a {
            text-decoration: none;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #494949;
            text-align: center;
        }
    }
}
</style>
