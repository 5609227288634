<template>
    <MainLayout :is-show-header=true>
        <div class="content">
            <div class="social social_instagram" v-on="handleScroll">
                <div class="row flex-column flex-sm-row">
                    <div class="col-sm-6 col-12 left-part">
                        <transition name="slide-fade">
                            <div v-if="show_instagram" class="social__logo-container">
                                <img class="social__logo" alt="instagram logo" src="@/assets/icons/Instagram_logo_2016.png">
                            </div>
                        </transition>
                    </div>
                    <div class="col-sm-6 col-12 right-part">
                        <transition name="slide-fade">
                            <div v-if="show_instagram">
                                <div class="social__title">Manage Content</div>
                                <div class="social__body">
                                    Make posting easy with our automation features
                                    <ul>
                                        <li>Schedule and publish content automatically at your pre-defined time</li>
                                        <li>Add a description to your post</li>
                                        <li>Add hashtags, user tags and tag shoppable products to each post</li>
                                        <li>Share your IG content to Facebook</li>
                                        <li>Organize the order of your pictures as you would want them to appear on your IG post</li>
                                    </ul>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
            <div class="social social_shopify" v-on="handleScroll">
                <div class="row flex-sm-row flex-column-reverse">
                    <div class="col-sm-6 col-12 left-part">
                        <transition name="slide-fade">
                            <div v-if="show_shopify">
                                <div class="social__title">Publish Quality Images</div>
                                <div class="social__body">
                                    Our integration allows you to
                                    <ul>
                                        <li>Schedule when you would like to push your images to a specific SKU</li>
                                        <li>Arrange the order of your pictures</li>
                                        <li>Use AI to name your pictures and add ALT text which will help boost your SEO</li>
                                        <li>Update the Description of the SKU</li>
                                    </ul>
                                </div>
                            </div>
                        </transition>
                    </div>
                    <div class="col-sm-6 col-12 right-part">
                        <transition name="slide-fade">
                            <div v-if="show_shopify" class="social__logo-container">
                                <img alt="shopify logo" class="social__logo" src="@/assets/icons/shopify_logo.png" >
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
            <div class="social social_amazon" v-on="handleScroll">
                <div class="row flex-sm-row flex-column">
                    <div class="col-sm-6 col-12 left-part">
                        <transition name="slide-fade">
                            <div v-if="show_amazon" class="social__logo-container">
                                <img alt="amazon logo" class="social__logo" src="@/assets/icons/Amazon_logo.png" >
                            </div>
                        </transition>
                    </div>
                    <div class="col-sm-6 col-12 right-part">
                        <transition name="slide-fade">
                            <div v-if="show_amazon">
                                <div class="social__title">Upgrade your product Photos</div>
                                <div class="social__body">
                                    Our connection to Amazon allows to
                                    <ul>
                                        <li>Our connection to Amazon allows to</li>
                                        <li>Update your ASIN images</li>
                                    </ul>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 offset-lg-3 col-12">
                <div class="info-banner">
                    Push all of your edited images to your main social platforms all under one roof
                    <br>
                    <router-link class="btn-c3" :to="{name: 'Login'}">
                        Learn More
                    </router-link>
                </div>
            </div>
        </div>
    </MainLayout>
</template>

<script>
import MainLayout from '../layouts/MainLayout'

export default {
    name: 'SocialNetworksInfo',
    components: {
        MainLayout,
    },
    data() {
        return {
            show_instagram: false,
            show_shopify: false,
            show_amazon: false,
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            let obj = document.querySelector('.social_instagram');
            if (obj) {
                this.show_instagram = this.checkIsElementOnScreen(obj);
            }
            obj = document.querySelector('.social_shopify');
            if (obj) {
                this.show_shopify = this.checkIsElementOnScreen(obj);
            }
            obj = document.querySelector('.social_amazon');
            if (obj) {
                this.show_amazon = this.checkIsElementOnScreen(obj);
            }
            // this.scrolled = window.scrollY > 100;
        },
        checkIsElementOnScreen(obj) {
            let {top,bottom} = obj.getBoundingClientRect();
            let height = document.documentElement.clientHeight;
            return top < height;
        }
    },
    mounted() {
        setTimeout(() => {this.handleScroll()}, 200);
    }
}
</script>

<style lang="scss" scoped>
$roboto_font_family: 'Roboto', sans-serif;

$animation-show-time: .5s;
$breakpoint-tablet: 768px;

.content {
    //height: 1200px !important;
}
.social {
    margin-bottom: 100px;

    &__title {
        margin-bottom: 15px;

        font-size: 48px;
        line-height: 54px;
        font-family: $roboto_font_family;

        @media (max-width: $breakpoint-tablet) {
            text-align: center;
        }
    }
    &__body {
        font-size: 18px;
        line-height: 27px;

        & ul li {
            margin-bottom: 5px;
            margin-top: 5px;
        }
    }
    &__logo {
        max-height: 300px;
        max-width: 300px;
    }
}
.info-banner {
    margin-bottom: 200px;
    padding: 30px;
    border-radius: 15px;

    text-align: center;
    font-size: 48px;
    line-height: 54px;
    background-color: #fcf5ef;
    font-family: $roboto_font_family;
}
.left-part {
    .social__logo-container {
        text-align: center;
        padding: 0;

        @media (min-width: $breakpoint-tablet) {
            text-align: end;
            padding-right: 200px;
        }
    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(-10px);
        opacity: 0;
    }
}
.right-part {
    .social__logo-container {
        text-align: center;
        padding: 0;

        @media (min-width: $breakpoint-tablet) {
            text-align: start;
            padding-left: 200px;
        }

    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }
}
.btn-c3 {
    text-decoration: none;

    &:hover {
        color: white;
    }
}
</style>
