<template>
    <div class="app-main-layout">
        <Header :backgroundHeader="backgroundHeader"/>
        <main ref="main" class="app-content app-content-fluid">
            <div class="app-page" :class="{vh_header:vh_header}">
                <slot/>
            </div>
        </main>
        <Footer container="fluid"/>
    </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";

export default {
    props: {
        vh_header:null,
    },
    name: 'fluid-layout',
    data: () => ({
        isOpen: true,
        backgroundHeader: false
    }),
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    components: {
        Header, Footer
    },
    mounted() {
    },
    methods: {
        handleScroll (event) {
            this.backgroundHeader = this.$refs.main.getBoundingClientRect().top < 0;
        }
    }
}
</script>

<style lang="scss" scoped>

body {
    display: flex;
    font-style: normal;
    flex-direction: column;
}
.app-main-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
}
main {
    flex-grow: 1;
}
footer {
    flex-grow: 0;
}
.app-page {
    min-height: calc(100vh - 472px);
    margin-top: 107px;
}

@media screen and (max-width: 992px) {
    .vh_header.app-page {
        min-height: calc(100vh - 205px);
    }
    .app-page {
        margin-top: 0px;
    }
    .app-main-layout {
        background: white !important;
    }
}
</style>
