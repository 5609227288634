<template>
    <div>
        <MainLayout :is-show-header="true">
            <div v-if="load_page" class="page_profile">
                    <div class="page_header">
                        <div class="left_column">
                            <div class="x" v-if="!isMobile">
                                <div class="row">
                                    <div class="col-2 box_avatar p-0">
                                        <img v-if="avatar" class="avatar" :src=avatar alt="">
                                        <img v-else class="avatar" src='@/assets/images/sbcf-default-avatar.png' alt="">
                                        <img @click="editAvatar" class="icon_edit" src='@/assets/icons/editor_dark.svg' alt="">
                                        <input type="file" ref="avatar_file" @change="updateAvatar" hidden>
                                    </div>
                                    <div class="col-10 col_info">
                                        <div class="row mb-4">
                                            <div class="col-8">
                                                <div class="with_underline pb-2">
                                                    <div class="user_name cp" @click="openEditModal('userName')">{{ user.first_name }} {{ user.last_name }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-4">
                                            <div class="col-8">
                                                <div class="info_user cp with_underline pb-2" @click="openEditModal('userBusiness')">
                                                    <img v-if="is_type_user===business" class="me-1" src='@/assets/icons/business-center.svg' alt="">
                                                    <img v-else class="me-1" src='@/assets/icons/bi_person-fill.svg' alt="">
                                                    <div class="text2">
                                                        {{ user.business_name ? user.business_name : (is_type_user===business ? 'Enter your Company Name' : 'Level of Retouching') }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-4">
                                            <div class="col-8">
                                                <div class="info_user cp with_underline pb-2" @click="openEditModal('userLocation')">
                                                    <img class="me-1" src='@/assets/icons/ep_location.svg' alt="">
                                                    <div class="text2">{{ user.location ? user.location : 'Enter your City Name' }}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-5">
                                            <div class="col-12">
                                                <div class="bio_box bio_box_desc cp with_border" @click="openEditModal('userBio')">
                                                    <div class="bio_text">
                                                        {{ user.bio ? user.bio : `Here you can add information about yourself. Try to choose the most interesting points of your biography. Good luck!` }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-4">
                                            <div class="col-12">
                                                <div class="email__block cp with_underline pb-2" @click="openEditModal('userEmail')">
                                                    <div class="text2">
                                                        {{ user.email }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-4">
                                            <div class="col-12">
                                                <div class="phone__block cp with_underline pb-2" @click="openEditModal('userPhone')">
                                                    <div class="text2">
                                                        {{ user.phone }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-4" v-if="user.type_user == 'editor'">
                                            <div class="col-12">
                                                <div class="phone__block cp with_underline pb-2" @click="openEditModal('userEnglishLevel')">
                                                    <div class="text2">
                                                        {{ user.english_level || 'Select English level...' }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-4">
                                            <div class="col-12">
                                                <div class="phone__block cp with_underline pb-2" @click="openEditModal('userPassword')">
                                                    <div class="text2">
                                                        <button class="btn-c3">Change Password</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-4">
                                            <div class="col-12">
                                                <div class="phone__block cp with_underline pb-2" @click="openEditModal('userCoupon')">
                                                    <div class="text2">
                                                        <button class="btn-c3">Apply Coupon</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-4">
                                            <div class="col-12">
                                                <div class="phone__block cp with_underline pb-2" @click="openDeleteAccountModal()">
                                                    <div class="text2">
                                                        <button class="btn-c3" style="background: linear-gradient(180deg,#e27582,#fec0c8 99.5%), #fcf5ef !important">Delete Account</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <disconnect v-if="user.type_user === 'business'"/>
                                    </div>
                                </div>
                            </div>
                            <div class="mobile_menu_1 pb_65" v-if="isMobile">
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <div class="box_avatar">
                                            <img v-if="avatar" class="avatar" :src=avatar alt="">
                                            <img v-else class="avatar" src='@/assets/images/sbcf-default-avatar.png' alt="">
                                            <img @click="editAvatar" class="icon_edit" src='@/assets/icons/editor_dark.svg' alt="">
                                            <input type="file" ref="avatar_file" @change="updateAvatar" hidden>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-5">
                                    <div class="col-6 offset-3 with_underline pb-1">
                                        <div class="user_name cp text-center" @click="openEditModal('userName')">{{ user.first_name }} {{ user.last_name }}</div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-12 with_underline pb-1">
                                        <div class="info_user" @click="openEditModal('userBusiness')">
                                            <img class="me-1" v-if="is_type_user===business" src='@/assets/icons/business-center.svg' alt="">
                                            <img class="me-1" v-else src='@/assets/icons/bi_person-fill.svg' alt="">
                                            <div class="text2">{{ user.business_name ? user.business_name : 'Enter your Company Name' }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3 mb-4">
                                    <div class="col-12 with_underline pb-1">
                                        <div class="info2">
                                            <div class="info_user" @click="openEditModal('userLocation')">
                                                <img class="me-1" src='@/assets/icons/ep_location.svg' alt="">
                                                <div class="text2">{{ user.location ? user.location : 'Enter your City Name' }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3 mb-4">
                                    <div class="col-12 with_border" @click="openEditModal('userBio')">
                                        <div  class="bio_text">
                                            {{ user.bio ? user.bio : `Here you can add information about yourself. Try to choose the most interesting points of your biography. Good luck!`}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="right_column">
                            <div class="title-1 ps-3">Inbox</div>
                            <div class="pt-4 cp" @click="goToChats">
                                <div class="inbox">
                                    <div class="me-4">
                                        <img class="" src="@/assets/icons/mail.svg" alt="icon-approved.svg"/>
                                    </div>
                                    <div class="inbox_text">You have <span class="count_message"><ChatsMessageCount/></span> new messages.</div>
                                </div>
                            </div>
                            <div class="title-1 mt_29">
                                Past Jobs
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="active_jobs_row" v-if="past_jobs.length">
                                        <div class="card_job card_job_s cp"
                                            v-if="past_jobs.length"
                                            v-for="(job, index) in past_jobs"
                                            :key="'job-card-' + index">
                                            <div @click="scheduleFile(job)" class="img_div img_div_editor">
                                                <img v-if="job.image && job.image.src_cropped && job.image.src_cropped.includes('.tif')" src="@/assets/images/tif.png" class="card-img-top" :alt="'job-image-' + index">
                                                <img v-else :src="mainImage(job)" class="card-img-top" :alt="'job-image-' + index">
                                                <div class="details_work_images">
                                                    <div>Files: {{ job.image_count }}</div>
                                                    <div>Editing Level: {{ job['editing_level'] }}</div>
                                                    <div>Acceptance: {{ acceptance(job) }}%</div>
                                                    <div>Time Frame: {{ timerFrame(job) }} min/avg</div>
                                                    <div>End date: {{ getAmericanDateFormat(job.updated_at) }}</div>
                                                </div>
                                                <div class="name_div main_text text-center">
                                                    {{ job.name }}
                                                </div>
                                            </div>
                                            <template v-if="is_type_user===editor">
                                                <div class="loader_line">
                                                    <div class="done" style="width:100%"/>
                                                </div>
                                                <div class="card_body text-center">
                                                    All files approved
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div v-if="!isEmptyObject(job.review)" @click="openReviewModal(job)" class="leave-review">
                                                    Leave Review
                                                </div>
                                                <div class="rating_job" v-else>
                                                    <star-rating
                                                        v-model="job.review.rating"
                                                        :show-rating="false"
                                                        v-bind:star-size="22"
                                                        inactive-color="#D1D2D3"
                                                        :active-color="getDefaultColor"
                                                        :read-only="true"/>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div v-if="is_type_user===editor" class="review_column">
                    <div class="title_r">Reviews</div>
                    <div class="review_items">
                        <div class="review_item" v-for="review in editor_reviews">
                            <div class="header_box_review">
                                <div>
                                    <img v-if="review.user && review.user.avatar && review.user.avatar.url" class="avatar review-avatar" :src="review.user.avatar.url" alt="avatar">
                                    <img v-else class="avatar review-avatar" src='@/assets/images/sbcf-default-avatar.png' alt="">
                                </div>
                                <div>
                                    <div v-if="review.image_job">
                                        Job<span> #{{review.image_job.id}}</span>
                                        <span v-if="review.image_job.name" class="ms-2">"{{review.image_job.name}}"</span>
                                    </div>
                                    <star-rating
                                        v-model="review.rating"
                                        :show-rating="false"
                                        v-bind:star-size="18"
                                        inactive-color="#D1D2D3"
                                        active-color="#494949"
                                        :read-only="true"/>
                                </div>
                            </div>
                            <div class="d-flex gap-3 mt-4">
                                <div v-if="review.message">
                                    <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_3328_3146)">
                                            <path d="M6.40167 2.11288L5.85774 1.31543C2.09205 3.72771 0 6.65833 0 9.07061C0 11.4031 1.79916 12.4996 3.32636 12.4996C5.25105 12.4996 6.61088 10.9446 6.61088 9.30985C6.61088 7.93425 5.69038 6.75801 4.45607 6.31942C4.10042 6.1998 3.76569 6.10012 3.76569 5.52197C3.76569 4.78433 4.33054 3.68784 6.40167 2.11288ZM14.7071 2.11288L14.1632 1.31543C10.4393 3.72771 8.30544 6.65833 8.30544 9.07061C8.30544 11.4031 10.1464 12.4996 11.6736 12.4996C13.6192 12.4996 15 10.9446 15 9.30985C15 7.93425 14.0586 6.75801 12.7824 6.31942C12.4268 6.1998 12.113 6.10012 12.113 5.52197C12.113 4.78433 12.6987 3.68784 14.7071 2.11288Z" fill="#494949"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_3328_3146">
                                                <rect width="15" height="12.5" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div class="review_message">{{ review.message }}</div>
                                <div v-if="review.message">
                                    <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="rotated-180">
                                        <g clip-path="url(#clip0_3328_3146)">
                                            <path d="M6.40167 2.11288L5.85774 1.31543C2.09205 3.72771 0 6.65833 0 9.07061C0 11.4031 1.79916 12.4996 3.32636 12.4996C5.25105 12.4996 6.61088 10.9446 6.61088 9.30985C6.61088 7.93425 5.69038 6.75801 4.45607 6.31942C4.10042 6.1998 3.76569 6.10012 3.76569 5.52197C3.76569 4.78433 4.33054 3.68784 6.40167 2.11288ZM14.7071 2.11288L14.1632 1.31543C10.4393 3.72771 8.30544 6.65833 8.30544 9.07061C8.30544 11.4031 10.1464 12.4996 11.6736 12.4996C13.6192 12.4996 15 10.9446 15 9.30985C15 7.93425 14.0586 6.75801 12.7824 6.31942C12.4268 6.1998 12.113 6.10012 12.113 5.52197C12.113 4.78433 12.6987 3.68784 14.7071 2.11288Z" fill="#494949"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_3328_3146">
                                                <rect width="15" height="12.5" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
        <div class="modal" id="editModalUserName" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Name</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <input :class="{ 'is-invalid': !temp_modal_value_1}" type="text" class="form-control" v-model="temp_modal_value_1" placeholder="First Name">
                            <div class="invalid-feedback">
                                This field is required
                            </div>
                            <input :class="{ 'is-invalid': !temp_modal_value_2}" type="text" class="form-control mt-3" v-model="temp_modal_value_2" placeholder="Last Name">
                            <div class="invalid-feedback">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="updateUserName">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="editModalUserBusiness" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-if="is_type_user===business">Business</h5>
                        <h5 class="modal-title" v-else>Level of Retouching</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <input v-if="is_type_user===business" :class="{ 'is-invalid': !temp_modal_value_1}" type="text" class="form-control" v-model="temp_modal_value_1" placeholder="Business">
                            <select class="form-select" v-else v-model="temp_modal_value_1">
                                <option value="" disabled>Select your level</option>
                                <option v-for="(item,index) in level_user" :value="item" v-bind:selected="index === 0">{{ item }}</option>
                            </select>
                            <div class="invalid-feedback">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="updateUser('business_name')">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="editModalUserLocation" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Location</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <input :class="{ 'is-invalid': !temp_modal_value_1}" type="text" class="form-control" v-model="temp_modal_value_1" placeholder="Location">
                            <div class="invalid-feedback">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="updateUser('location')">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="editModalUserBio" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Bio</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <textarea :class="{ 'is-invalid': !temp_modal_value_1}" v-model="temp_modal_value_1" placeholder="Bio"
                                      class="form-control" id="exampleFormControlTextarea1" rows="3"/>
                            <div class="invalid-feedback">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="updateUser('bio')">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="modalCheckPassword" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Confirm the password</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <input :class="{ 'is-invalid': password_message}" type="password" class="form-control" v-model="password" placeholder="Enter your password">
                            <div class="invalid-feedback">
                                This is wrong password
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="checkPassword">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="editModalUserEmail" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Email</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <input :class="{ 'is-invalid': !temp_modal_value_1}" type="text" class="form-control" v-model="temp_modal_value_1" placeholder="Enter your new email">
                            <div class="invalid-feedback">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="updateUser('email')">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="editModalUserPhone" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Phone</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <input :class="{ 'is-invalid': !temp_modal_value_1 || phone_message}" type="text" class="form-control" v-model="temp_modal_value_1" placeholder="Enter your new phone">
                            <div class="invalid-feedback">
                                {{phone_message}}
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="updateUser('phone')">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="editModalUserEnglishLevel" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">English Level</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <select class="form-select" v-model="temp_modal_value_1" aria-label="Default select example">
                                <option disabled :value="null">Select English level...</option>
                                <option v-for="level in english_levels" :value="level">{{level}}</option>
                            </select>
                            <div class="invalid-feedback">
                                This field is required
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="updateUser('english_level')">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="editModalUserPassword" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Change Password</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <input :class="{ 'is-invalid': password_message}" type="password" class="form-control" v-model="temp_modal_value_1" placeholder="Enter your new password">
                            <input :class="{ 'is-invalid': password_message}" type="password" class="form-control mt-3" v-model="temp_modal_value_2" placeholder="Confirm password">
                            <div class="invalid-feedback">
                                {{password_message}}
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="updateUser('password')">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="editModalUserCoupon" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Apply Coupon</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="input_modal">
                            <input type="text" class="form-control" v-model="temp_modal_value_1" placeholder="Enter your coupon">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="updateUser('coupon')">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="deleteAccountModal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Delete Account</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div>Please confirm account deletion</div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Close</button>
                        <button class="btn_modal_t2" @click="deleteAccount()" style="background: linear-gradient(180deg,#e27582,#fec0c8 99.5%), #fcf5ef !important">Delete</button>
                    </div>
                </div>
            </div>
        </div>
        <ReviewModal ref="review_modal" @getProfileUser="getProfileUser" />
    </div>
</template>

<script>
import MainLayout from "../layouts/MainLayout";
import {errorMessage} from "../services/messages";
import { mapGetters, mapMutations } from 'vuex'
import TypeUserEnum from "@/enums/TypeUserEnum";
import ChatsMessageCount from "../components/ChatsMessageCount";
import {Modal} from "bootstrap";
import StarRating from 'vue-star-rating';
import Disconnect from "../components/FileSchedule/Disconnect";
import moment from 'moment-timezone';
import helpFunctions from "../helpFunctions";
import ReviewModal from "../components/Modals/ReviewModal";
import ('../css/custom.css');

export default {
    name: 'Profile',
    components: {
        ReviewModal,
        MainLayout,
        ChatsMessageCount,
        StarRating,
        Disconnect,
    },
    data() {
        return {
            level_user: ['Beginner','Proficient','Expert'],
            english_levels: ['Conversational', 'Advanced', 'Fluent'],
            user: {},
            is_type_user: true,
            business: TypeUserEnum.BUSINESS,
            editor: TypeUserEnum.EDITOR,
            password: '',
            password_message: null,
            phone_message: null,
            targetModal: '',
            editModals: {},
            deleteAccountModal: null,
            temp_modal_value_1: null,
            temp_modal_value_2: null,

            load_page: false,
            past_jobs: {},
            tab: 1,
            editor_reviews: [],
        }
    },
    destroyed() {
        Object.values(this.editModals).forEach(editModal => {
            editModal.dispose();
        });
        this.deleteAccountModal.dispose();
    },
    async mounted() {
        this.editModals = helpFunctions.editModals;
        if (this.isLogged) {
            await this.getProfileUser();
            this.editModals.userName = new Modal(document.getElementById('editModalUserName'));
            this.editModals.userBusiness = new Modal(document.getElementById('editModalUserBusiness'));
            this.editModals.userLocation = new Modal(document.getElementById('editModalUserLocation'));
            this.editModals.userBio = new Modal(document.getElementById('editModalUserBio'));
            this.editModals.userEmail = new Modal(document.getElementById('editModalUserEmail'));
            this.editModals.userPhone = new Modal(document.getElementById('editModalUserPhone'));
            this.editModals.userEnglishLevel = new Modal(document.getElementById('editModalUserEnglishLevel'));
            this.editModals.userPassword = new Modal(document.getElementById('editModalUserPassword'));
            this.editModals.userCoupon = new Modal(document.getElementById('editModalUserCoupon'));
            this.editModals.checkPassword = new Modal(document.getElementById('modalCheckPassword'));
            this.deleteAccountModal = new Modal(document.getElementById('deleteAccountModal'));
        }
        await this.isQueryRouteParam();
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'setUser',
            'setUpdateUserProfile'
        ]),
        async updateUserName() {
            if (!this.temp_modal_value_1 || !this.temp_modal_value_2) {
                return;
            }
            try {
                this.user.first_name = this.temp_modal_value_1;
                this.user.last_name = this.temp_modal_value_2;
                this.showLoader();
                const resp = await this.$http.postAuth(`${this.$http.apiUrl()}profile/update-user-name`, {
                    'first_name': this.user.first_name,
                    'last_name': this.user.last_name
                });
                this.updateUserStorage(resp?.data?.data || null);
                this.editModals.userName.hide();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        async updateUser(variable) {
            if(variable === 'password') {
                this.password_message = helpFunctions.password(this.temp_modal_value_1);
                if (this.password_message) {
                    return;
                }
                else if (!this.temp_modal_value_2 || this.temp_modal_value_1 !== this.temp_modal_value_2) {
                    this.password_message = 'Please make sure your passwords match';
                    return;
                }
            }
            else if (!this.temp_modal_value_1) {
                return;
            }
            else if (variable === 'phone' && this.temp_modal_value_1.length > 12) {
                this.phone_message = 'Please make sure your phone number is correct';
                return;
            }
            this.user[variable] = this.temp_modal_value_1;
            let postdata = {};
            postdata[variable] = this.user[variable];
            try {
                this.showLoader();
                const resp = await this.$http.postAuth(`${this.$http.apiUrl()}profile/update-user-` + variable.replace("_", "-"), postdata);
                this.updateUserStorage(resp?.data?.data || null);
                Object.values(this.editModals).forEach(editModal => {
                    editModal.hide();
                });
                this.password = '';
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        async deleteAccount() {
            try {
                this.showLoader();
                await this.$http.getAuth(`${this.$http.apiUrl()}delete-user`);
                delete localStorage.strobeart_user;
                delete localStorage.strobeart_jwt;
                this.$router.push({to: '/login', name: "Login"}).then();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        openDeleteAccountModal() {
            this.deleteAccountModal.show();
        },
        openEditModal(name) {
            if(name === 'userName') {
                this.temp_modal_value_1 = this.user.first_name;
                this.temp_modal_value_2 = this.user.last_name;
            }
            else if(name === 'userBusiness') {
                this.temp_modal_value_1 = this.user.business_name;
            }
            else if(name === 'userLocation') {
                this.temp_modal_value_1 = this.user.location;
            }
            else if(name === 'userBio') {
                this.temp_modal_value_1 = this.user.bio;
            }
            else if(name === 'userEmail') {
                this.temp_modal_value_1 = this.user.email;
            }
            else if(name === 'userPhone') {
                this.temp_modal_value_1 = this.user.phone;
            }
            else if(name === 'userEnglishLevel') {
                this.temp_modal_value_1 = this.user.english_level;
            }
            else if(name === 'userPassword') {
                this.temp_modal_value_1 = this.user.password;
                this.temp_modal_value_2 = this.user.confirm_password;
            }
            else if(name === 'userCoupon') {
                this.temp_modal_value_1 = this.user.coupon;
            }

            this.targetModal = this.editModals[name];
            this.editModals[name].show();
        },
        async checkPassword() {
            try {
                this.showLoader();
                const resp = await this.$http.postAuth(`${this.$http.apiUrl()}check-password`, { 'password': this.password });
                if (resp?.data?.data.check == true) {
                    this.editModals.checkPassword.hide();
                    this.password = '';
                    this.targetModal.show();
                }
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        updateUserStorage(data) {
            if (data) {
                localStorage.setItem('strobeart_user', JSON.stringify(data));
                this.setUser(data || {});
                this.setUpdateUserProfile(true);
            }
        },
        async updateAvatar(e) {
            const tmpFiles = e.target.files;
            if (tmpFiles.length === 0) {
                return false;
            }
            try {
                this.showLoader();
                let data = new FormData();
                data.append('avatar', tmpFiles[0]);
                const resp = await this.$http.postAuth(`${this.$http.apiUrl()}profile/update-avatar`, data);
                this.updateUserStorage(resp?.data?.data || null);
                await this.getProfileUser();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        editAvatar() {
            this.$refs.avatar_file.click();
        },
        isEmptyObject(obj) {
            return helpFunctions.isEmptyObject(obj);
        },
        openReviewModal(job) {
            this.$refs.review_modal.load(job);
        },
        async isQueryRouteParam() {
            let code = this.$route.query.code ?? null;
            let shop = this.$route.query.shop ?? null;
            let amazon_callback_uri = this.$route.query.amazon_callback_uri ?? null;
            let spapi_oauth_code = this.$route.query.spapi_oauth_code ?? null;
            if (amazon_callback_uri) {
                //redirect to amazon again
                let strobeart_amazon_redirect = localStorage.getItem('strobeart_amazon_redirect');
                strobeart_amazon_redirect = strobeart_amazon_redirect.replace('<amazon_callback_uri>', amazon_callback_uri);
                strobeart_amazon_redirect = strobeart_amazon_redirect.replace('<amazon_state>', this.$route.query.amazon_state);
                window.location = strobeart_amazon_redirect;
            } else if (spapi_oauth_code) {
                let amazon_data = {
                    spapi_oauth_code:  this.$route.query.spapi_oauth_code ?? null,
                    mws_auth_token: this.$route.query.mws_auth_token ?? null,
                    selling_partner_id: this.$route.query.selling_partner_id ?? null,
                }
                await this.generateAmazonToken(amazon_data);
            }
            if (code && shop) {
                await this.generateShopifyToken(code, shop);
            }
            if (code && !shop) {
                if (this.isLogged) {
                    await this.generateFacebookToken(code);
                } else {
                    window.location.href = `${this.$http.apiUrl()}auth/facebook/callback?code=${code}`;
                }
            }
        },
        mainImage(job) {
            if (job?.image?.src_cropped) {
                return job?.image?.src_cropped;
            }
            else {
                return job?.image?.src;
            }
        },
        acceptance(job) {
            if(job.image_count !== 0) {
                return (100 - (job.images_decline.length * 100) / job.image_count).toFixed(2);
            }
            else {
                return 0;
            }
        },
        timerFrame(job) {
            if(job.image_count !== 0) {
                return parseInt((job.finished_worked_images_sum_sum_timers / job.image_count) / 60) + 1;
            }
            else {
                return 0;
            }
        },
        async generateAmazonToken(amazon_data) {
            try {
                this.showLoader();
                await this.$http.getAuth(`${this.$http.apiUrl()}seller-partner/auth`, amazon_data);
                // await this.$router.replace({'query': null});
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        async generateFacebookToken(code) {
            try {
                this.showLoader();
                await this.$http.postAuth(`${this.$http.apiUrl()}connect-instagram`, {'code': code });
                await this.$router.replace({'query': null});
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        async generateShopifyToken(code, shop) {
            try {
                this.showLoader();
                await this.$http.postAuth(`${this.$http.apiUrl()}get-token-shopify`, {
                    'code': code,
                    'shop': shop
                });
                await this.$router.replace({'query': null});
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        scheduleFile(job) {
            if (this.is_type_user == this.editor) {
                return;
            }
            this.$router.push('/job/' + job.id + '/upload-files/');
        },
        goToChats() {
            this.$router.push({name: 'ChatsList'}).then();
        },
        async getProfileUser() {
            try {
                this.showLoader();
                const response = await this.$http.getAuth(`${this.$http.apiUrl()}profile/user`);
                this.user = response?.data?.data?.user || {};
                this.past_jobs = response?.data?.data?.past_job || {};
                this.is_type_user = response?.data?.data?.user?.type_user || false;
                this.editor_reviews = response?.data?.data?.user?.editor_reviews || {};
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.load_page = true;
            this.hideLoader();
        },
        fileName(file) {
            return file?.name || 'Choose from files...';
        },
        getAmericanDateFormat(date) {
            return moment.utc(date).local().format('MM/DD/YYYY');
        },
    },
    computed: {
        ...mapGetters([
            'isLogged',
            'getDefaultColor'
        ]),
        avatar() {
            return this.user?.avatar?.url || null;
        },
        isMobile() {
            return window.innerWidth <= 992;
        },
    },
}
</script>

<style lang="scss" scoped>
.review-avatar {
    max-width: 130px !important;
}
.details_work_images {
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 10px 13px;
    flex-direction: column;
    justify-content: center;
}
.details_work_images > div {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #494949;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
.img_div_editor:hover {
    .card-img-top {
        opacity: 0.3;
    }
    .details_work_images {
        display: flex;
    }
    .name_div.main_text {
        display: none;
    }
}
.modal-title {
    width: 100%;
    text-align: center;
}
.page_profile {
    margin-top: 177px;
    margin-bottom: 230px;
}
.page_header {
    display: flex;
    gap: 3%;
}
.right_column {
    width: 45%;
}
.left_column {
    width: 55%;
    background: white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 49px 40px;
}
.title-1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #494949;
}
.user_name {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 44px;
    color: #494949;
}
.info_user {
    display: flex;
}
.inbox {
    background: white;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    display: flex;
    padding: 22px 36px;
    align-items: center;
    width: 80%;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}
.inbox_text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #494949;
    display: flex;
    gap: 5px;

    .count_message {
        color: red;
    }
}
.active_jobs_row {
    width: 100%;
    height: 300px;
    overflow: auto;
    white-space: nowrap;
    margin-top: 14px;
}

.main_text {
    color: #494949;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}
.card_job {
    width: 249px !important;
    height: 272px !important;
    display: inline-block;
    padding: 12px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    margin-right: 7px;
    border: 0.2px rgba(0, 0, 0, 0.2) solid;
}
.img_div {
    width: 225px;
    height: 217px;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 12px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.name_div {
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 215px;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    position: absolute;
    top: 97px;
    left: 5px;
}
.loader_line {
    width: 170px;
    height: 7px;
    border: 0.2px solid #696969;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0 auto;
    overflow: hidden;

    &.finished {
        border-color: #ABFCA4 !important;
    }
    &.in-process{
        border-color: #FFA159;
    }
    &.non-started{
        border-color: #FD5860;
    }
}
.done {
    height: 100%;
    background: #494949;

    &.finished {
        background: #ABFCA4 !important;
    }
    &.non-finished {
        background: #FFA159 !important;
    }
}
.card_body {
    width: 200px;
    height: 20px;
    margin: 5px auto;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
}
.x {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    padding: 7px;
}
input::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.45);
}
.form-select {
    border-radius: 19px;
    color: rgba(74, 74, 74, 0.6);
    border-color: rgba(74, 74, 74, 0.6);
    overflow: hidden;
    text-overflow: ellipsis;
}
.form-select:focus {
    border-color: rgba(74, 74, 74, 0.6);
}
.card-img-top {
    opacity: 0.7;
}
.form-control.is-invalid, .form-control:invalid, .form-select:invalid, .form-select.is-invalid, .is-invalid_c {
    border-color: #dc3545 !important;
}
.invalid-feedback, .invalid-feedback_c {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #dc3545;
}
.modal-footer {
    border-top: none;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    gap: 35px;
}
.btn_modal_t1 {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    background: white;
    height: 47px;
    width: 148px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #494949;
}
.leave-review {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration-line: underline;
    color: #494949;
}
.rating_job {
    display: flex;
    justify-content: center;
}
.review_column {
    background: white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 52%;
    margin-top: 43px;
    padding: 27px 0 27px 32px;
}
.title_r {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 44px;
    color: #494949;
}
.avatar {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0.2px solid #494949;
}
.header_box_review {
    display: flex;
    align-items: center;
    gap: 28px;
}
.review_message {
    font-style: italic;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    color: #494949;
}
.review_item {
    padding-bottom: 20px;
    border-bottom: 0.6px solid rgba(73, 73, 73, 0.65);
}
.review_items {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 80px;
    height: 365px;
    overflow: auto;
    padding-right: 32px;
}
.active_jobs_row {
    width: 100%;
    height: 300px;
    overflow: auto;
    white-space: nowrap;
}
.card_job_s {
    cursor: pointer;
}
.box_avatar {
    position: relative;
    width: 136px;
    height: 136px;
}
.icon_edit {
    position: absolute;
    bottom: 0px;
    right: 0px;
    cursor: pointer;
}
.col_info {
    padding-left: 83px;
    padding-top: 30px;
}
.with_underline {
    border-bottom: 0.5px solid rgba(73, 73, 73, 0.45);
}
.text2 {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    color: #494949;
}
.bio_box {
    padding: 6px 15px 8px 15px;
}
.with_border {
    border: 0.7px solid rgba(73, 73, 73, 0.45);
    border-radius: 4px;
}
.bio_text {
    box-sizing: border-box;
    border-radius: 4px;
    padding: 6px 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: rgba(73, 73, 73, 0.35);
    position: relative;
    left: -10px;
}
.rotated-180 {
    transform: rotate(180deg);
}

@media only screen and (max-width: 992px) {
    .inbox {
        padding: 22px 20px;
        width: 100%;
    }
    .right_column {
        display: none;
    }
    .left_column {
        width: 100%;
        box-shadow: none;
        background: none;
        padding: 36px 31px;
    }
    .page_profile {
        margin-top: 25px;
        margin-bottom: 10px;
    }
    .user_name {
        font-size: 18px;
        line-height: 22px;
    }
    .info_user {
        align-items: center;
        .text2 {
            font-size: 14px !important;
        }
    }
    .box_avatar {
        width: 80px;
        height: 80px;
        margin-left: auto;
        margin-right: auto;
    }
    .icon_edit {
        bottom: 2px;
        right: -6px;
        width: 20px;
        height: 20px;
    }
    .col_info {
        padding: 0;
        padding-left: 15px;
        padding-bottom: 5px;
        display: flex;
        align-items: end;
    }
    .text2 {
        font-weight: 500;
        font-size: 12.5px;
        line-height: 20px;
    }
    .bio_text {
        border: 0px solid rgba(73, 73, 73, 0.45);
        box-sizing: border-box;
        border-radius: 4px;
        padding: 12px 0px;
        font-style: normal;

        position: relative;
        left: 0;

        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #494949;
    }
}

@media (max-width: 992px) {
    .modal-footer {
        border-top: none;
        display: flex;
        justify-content: center;
        padding-bottom: 30px;
        gap: 15px;
    }
    .review_column {
        background: none;
        width: 100%;
        height: 530px;
        padding-bottom: 90px;
    }
    .review_items {
        margin-top: 20px;
    }
}
</style>
