<template>
    <MainLayout>
        <div class="container body_active">
            <div class="row">
                <div class="col-lg-8 offset-lg-2 col-12 text-center text">
                    Dear user, in order to complete the registration in our system, your Facebook account must have an email associated with it.
                    <br> <br>
                    Please enter your email in your Facebook account and try to complete the registration again
                    <br> <strong>OR</strong> <br>
                    <router-link :to="{name: 'Login'}" class="link">
                        Choose other Sign In method
                    </router-link>
                </div>
            </div>
        </div>
    </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";

export default {
    name: 'FacebookError',
    components: {
        MainLayout,
    },
}
</script>

<style lang="scss" scoped>
.text {
    margin-top: 140px;
    font-size: 20px;
}
.link {
    color: #212529;
}
</style>
