<template>
    <MainLayout :is-show-header=true>
        <div class="info">
            <div class="info__title">Brand Image</div>
            <div class="row mt-3">
                <div class="col-lg-8 offset-lg-2 col-12">
                    <div class="ps-2 pe-2">
                        Generate your brand image with ChatGPT! Answer 20 questions and ChatGPT will create ideal brand image for you!
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-lg-8 offset-lg-2 col-12">
                    <div v-for="question in questions">
                        <p>{{question.text}}</p>
                        <p><input class="form-control" v-model="question.answer"></p>
                    </div>
                    <div style="margin-top: 25px; text-align: center;">
                        <button type="button" class="btn_post_n" @click="saveChatGptAnswers()">Save answers</button>
                        <button type="button" class="btn_post_n" style="margin-left: 25px;" @click="generateDescription()">Generate brand image</button>
                    </div>
                    <div style="margin-top: 25px; text-align: center;">
                        <div class="info__title">ChatGpt Description</div>
                        <textarea v-model="ai_description" class="textarea"/>
                        <button type="button" style="margin-top: 25px;" class="btn_post_n" @click="saveChatGptDescription()">Save brand image</button>
                    </div>
                </div>
            </div>
        </div>
    </MainLayout>
</template>

<script>
import MainLayout from "../layouts/MainLayout";
import {mapMutations} from "vuex";
import {errorMessage, successMessage} from "../services/messages";
import axios from "axios";

export default {
    name: 'BrandImage',
    components: {
        MainLayout,
    },
    data() {
        return {
            questions: [],
            answers: [],
            ai_description: null,
        }
    },
    async mounted() {
        await this.getChatGptAnswers();
        await this.getChatGptDescription();
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader'
        ]),
        async getChatGptAnswers() {
            try {
                this.showLoader();
                const response = await this.$http.getAuth(`${this.$http.apiUrl()}profile/get-chat-gpt-answers`);
                this.answers = response?.data?.data;
                await this.getChatGptQuestions();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        async getChatGptQuestions() {
            try {
                this.showLoader();
                const response = await this.$http.getAuth(`${this.$http.apiUrl()}profile/get-chat-gpt-questions`);
                this.questions = response?.data?.data;
                this.answers.forEach(answer => {
                    this.questions[answer.question_id].answer = answer.text;
                });
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        async saveChatGptAnswers() {
            try {
                this.showLoader();
                const response = await this.$http.postAuth(`${this.$http.apiUrl()}profile/save-chat-gpt-answers`, {
                    'questions': this.questions
                });
                if(response.data.success) {
                    successMessage("Your answers successfully saved!");
                }
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        async getChatGptDescription() {
            try {
                this.showLoader();
                const response = await this.$http.getAuth(`${this.$http.apiUrl()}profile/get-chat-gpt-description`);
                this.ai_description = response?.data?.data;
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        async saveChatGptDescription() {
            try {
                this.showLoader();
                const response = await this.$http.postAuth(`${this.$http.apiUrl()}profile/save-chat-gpt-description`, {
                    'description': this.ai_description
                });
                if (response.data.success) {
                    successMessage("Brand image successfully saved!");
                }
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        async generateDescription() {
            let content = "You are an experienced marketing expert. Please generate a brand identity based on the given information. All the information is provided in the format of question and answer. Please write it in plain English without jargon. ";
            Object.values(this.questions).forEach(question => {
                if(question.answer) {
                    content += question.text + " " + question.answer + ". ";
                }
            });
            console.log(content);
            try {
                this.showLoader();
                axios.post('https://api.openai.com/v1/chat/completions', {
                    "model": "gpt-3.5-turbo",
                    "messages": [
                        {
                            "role": "user",
                            "content": content,
                        }
                    ],
                    "temperature": 1,
                    "top_p": 1,
                    "n": 1,
                    "stream": false,
                    "max_tokens": 2000,
                    "presence_penalty": 0,
                    "frequency_penalty": 0
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ` + process.env.VUE_APP_CHATGPT_KEY
                    }
                })
                .then(response => {
                    this.hideLoader();
                    this.ai_description = response.data.choices[0].message.content;
                });
            } catch (e) {
                this.hideLoader();
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.info {
    margin-bottom: 200px;

    &__title {
        font-size: 48px;
        text-align: center;
    }
}
.textarea {
    width: 100%;
    height: 300px;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    padding: 10px;
}
</style>
