<template>
    <MainLayout :is-show-header=true>
        <div class="info">
            <div class="info__title">How to connect your Amazon</div>
            <div class="row">
                <div class="col-lg-6 offset-lg-3">
                    <div class="info__step">
                        <div class="step__text">
                            1. Under <router-link :to="{name: 'Jobs'}">Jobs</router-link> >
                            <router-link :to="{name: 'PastJobs'}"> Past Jobs</router-link> >
                            Select any jobs you would like to push to Social Platforms
                            <ul>
                                <li>Select which image(s) you would like to push to Social (can select more than one)</li>
                                <li>Accept</li>
                                <li>Under “Upload To” → Select Amazon</li>
                            </ul>
                        </div>
                        <div class="info__step">
                            <div class="step__text">
                                2. You will be prompted to connect your Amazon account
                            </div>
                            <div class="step__image">
                                <img src="@/assets/images/HowConnectAmazon/step_2.png" alt="step 2" style="max-width:100%;">
                            </div>
                        </div>
                        <div class="info__step">
                            <div class="step__text">
                                3. A new tab will open which redirects to Amazon’s Client OAuth Login.
                            </div>
                            <div class="step__image">
                                <img src="@/assets/images/HowConnectAmazon/step_3_1.png" alt="step 3 (1)" style="max-width:100%;">
                            </div>
                            <div class="step__image mt-3">
                                <img src="@/assets/images/HowConnectAmazon/step_3_2.png" alt="step 3 (2)" style="max-width:100%;">
                            </div>
                        </div>
                        <div class="info__step">
                            <div class="step__text">
                                4. After authorizing the connection between StrobeArt and your Amazon Account, you will be redirected to StrobeArt’s Job Posting Page.
                            </div>
                        </div>
                        <div class="info__step">
                            <div class="step__text">
                                5. <em>To review your scheduled post:</em> <br>
                                Log In To Your Seller Central
                                <ul>
                                    <li>Click on your ASIN > under SKU</li>
                                    <li>Go to Actions (bottom/left hand side) > Edit Listing</li>
                                    <li>In the Navigation Under Images you will see the scheduled images</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </MainLayout>
</template>

<script>
import MainLayout from "../layouts/MainLayout"

export default {
    name: 'HowAmazonConnect',
    components: {
        MainLayout,
    },
}
</script>

<style lang="scss" scoped>
.info {
    &__title {
        font-size: 48px;
        text-align: center;
    }
    &__step {
        margin-bottom: 25px;
    }
    &__text {
        font-size: 18px;
    }
    &__image {
        max-height: 500px;
    }
}
.step {
    &__image {
        img {
            max-width: 1000px;
            max-height: 500px;
        }
    }
}
</style>
