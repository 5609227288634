<template>
    <MainLayout :is-show-header=true>
        <div class="info">
            <div class="info__title">How to connect your shopify store</div>
            <div class="row">
                <div class="col-lg-6 offset-lg-3">
                    <div class="info__step">
                        <div class="step__text">
                            1. Login to your <a href="https://accounts.shopify.com/store-login" target="_blank">Shopify Store</a>
                        </div>
                        <div class="step__image"/>
                    </div>
                    <div class="info__step">
                        <div class="step__text">
                            2. Go to Settings
                        </div>
                        <div class="step__image">
                            <img src="@/assets/images/HowConnectShopify/step_2.png" alt="step 2" style="max-width:100%;">
                        </div>
                    </div>
                    <div class="info__step">
                        <div class="step__text">
                            3. Go to Apps and Sales Channels
                        </div>
                    </div>
                    <div class="info__step">
                        <div class="step__text">
                            4. Scroll down to “Private apps have moved” and click on “Develop Apps for your store”
                        </div>
                    </div>
                    <div class="info__step">
                        <div class="step__text">
                            5. Click on Create an App
                            <ul>
                                <li>a. Under App Name, Type: StrobeArt</li>
                                <li>b. App developer you can leave as is with your email</li>
                                <li>c. Click on Create App</li>
                            </ul>
                        </div>
                    </div>
                    <div class="info__step">
                        <div class="step__text">
                            6. Find "Configuration" tab
                        </div>
                        <div class="step__image">
                            <img src="@/assets/images/HowConnectShopify/step_6.png" alt="step 6" style="max-width:100%;">
                        </div>
                    </div>
                    <div class="info__step">
                        <div class="step__text">
                            7. Find "Product listings", "Products" and "Customers" on the scopes list, and check it
                        </div>
                        <div class="step__image">
                            <img src="@/assets/images/HowConnectShopify/step_7.png" alt="step 7" style="max-width:100%;">
                            <img src="@/assets/images/HowConnectShopify/step_7_2.png" alt="step 7 (2)" style="max-width:100%;">
                        </div>
                    </div>
                    <div class="info__step">
                        <div class="step__text">
                            8. Save scopes
                        </div>
                        <div class="step__image">
                            <img src="@/assets/images/HowConnectShopify/step_8.png" alt="step 8" style="max-width:100%;">
                        </div>
                    </div>
                    <div class="info__step">
                        <div class="step__text">
                            9. Press "Install app"
                        </div>
                    </div>
                    <div class="info__step">
                        <div class="step__text">
                            10. Under App Development > Strobeart
                            <ul>
                                <li>a. Click on API Credentials</li>
                                <li>b. Under Admin API Access Token to the right of the box click on Show>then Copy</li>
                                <li>c. Please copy this code in a safe environment as you will only be able to access it once</li>
                            </ul>
                        </div>
                    </div>
                    <div class="info__step">
                        <div class="step__text">
                            11. Navigate back to Stobeart.com
                        </div>
                    </div>
                    <div class="info__step">
                        <div class="step__text">
                            12. Enter The name of your shop in the following format:
                            <ul>
                                <li>Yourshop.myshopify.com</li>
                            </ul>
                        </div>
                    </div>
                    <div class="info__step">
                        <div class="step__text">
                            13. Enter the copied Access Token
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </MainLayout>
</template>

<script>
import MainLayout from "../layouts/MainLayout";

export default {
    name: 'HowShopifyConnect',
    components: {
        MainLayout,
    },
}
</script>

<style lang="scss" scoped>
.info {
    &__title {
        font-size: 48px;
        text-align: center;
    }
    &__step {
        margin-bottom: 25px;
    }
    &__text {
        font-size: 18px;
    }
    &__image {
        max-height: 500px;
    }
}
</style>
