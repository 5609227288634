<template>
    <FluidLayout>
        <div class="container-fluid body_active pb-5">
            <div class="row">
                <div class="col-12 banner">
                    <div class="banner-left-banner">
                        <span class="banner-left-banner_purple">CREATE.</span><br>
                        <span class="banner-left-banner_red">CONNECT.</span><br>
                        <span class="banner-left-banner_brown">EDIT.</span><br>
                    </div>
                    <img src="@/assets/images/About/Banner_photo.png">
                </div>
                <div class="col-12 banner-mobile">
                    <div class="banner-left-mobile-banner">
                        <span class="banner-left-mobile-banner_purple">CREATE.</span><br>
                        <span class="banner-left-mobile-banner_red">CONNECT.</span><br>
                        <span class="banner-left-mobile-banner_brown">EDIT.</span><br>
                    </div>
<!--                <img src="@/assets/images/About/Banner.png" alt="CREATE. CONNECT. EDIT.">-->
                    <div style="width: 100%; text-align: center;">
                        <router-link v-if="!isLogged" :to="{ name: 'Login' }" class="banner-mobile-button">Sign in</router-link>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 roles">
                    <div class="roles__title">One Stop Photo Shop</div>
                    <div class="roles__cards">
                        <div class="roles__card">
                            <div class="roles__image-block roles__image-block_red">
                                <img src="@/assets/images/About/Business.svg" class="roles__image">
                            </div>
                            <div class="roles__text-block">
                                <div class="roles__text-title">Business Owner</div>
                                <div class="roles__text">Get professionally edited images</div>
                                <div class="roles__button-container">
                                    <router-link class="roles__button roles__button_red" :to="{name: 'Registration', query: {role: 'business'}}">
                                        Learn More
                                        <img class="roles__button-icon" src="@/assets/icons/Arrow_right.svg">
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="roles__card">
                            <div class="roles__image-block roles__image-block_yellow">
                                <img src="@/assets/images/About/Editor.svg" class="roles__image">
                            </div>
                            <div class="roles__text-block">
                                <div class="roles__text-title">Photo Retoucher</div>
                                <div class="roles__text">Join our creative network of Photo Retouchers</div>
                                <div class="roles__button-container">
                                    <router-link class="roles__button roles__button_yellow" :to="{name: 'Registration', query: {role: 'editor'}}">
                                        Learn More
                                        <img class="roles__button-icon" src="@/assets/icons/Arrow_right.svg">
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 roles-mobile">
                    <div class="roles-mobile__title">One Stop Photo Shop</div>
                    <div class="roles-mobile__buttons">
                        <input v-model="role" hidden type="radio" value="Business" name="role" id="roleBusiness" @change="changeRole">
                        <label for="roleBusiness" :class="{'active': role=='Business'}" class="roles-mobile__button roles-mobile__button_red">Business Owner</label>

                        <input v-model="role" hidden type="radio" value="Editor" name="role" id="roleEditor" @change="changeRole">
                        <label for="roleEditor" :class="{'active': role=='Editor'}" class="roles-mobile__button roles-mobile__button_yellow">Retoucher</label>
                    </div>
                    <div class="roles-mobile__slides">
                        <carousel-3d class="roles-mobile__slides-container" :clickable="false" :minSwipeDistance="1000">
                            <slide :index="0" class="roles-mobile__slide" id="slideBusiness">
                                <template>
                                    <div class="roles-mobile__slide-image-block  roles-mobile__slide-image-block_red">
                                        <img class="roles-mobile__slide-image" src="@/assets/images/About/Business.svg">
                                    </div>
                                    <div class="roles-mobile__slide-card">
                                        <div class="roles-mobile__slide-title">Business Owner</div>
                                        <div class="roles-mobile__slide-text">Get professionally edited images</div>
                                        <div class="roles-mobile__slide-button-container">
                                            <router-link class="roles-mobile__slide-button roles-mobile__slide-button_red" :to="{name: 'Registration', query: {role: 'business'}}">
                                                Learn More
                                                <img class="roles-mobile__slide-button-icon" src="@/assets/icons/Arrow_right.svg">
                                            </router-link>
                                        </div>
                                    </div>
                                </template>
                            </slide>
                            <slide :index="1" class="roles-mobile__slide" id="slideEditor">
                                <template>
                                    <div class="roles-mobile__slide-image-block roles-mobile__slide-image-block_yellow">
                                        <img class="roles-mobile__slide-image" src="@/assets/images/About/Editor.svg">
                                    </div>
                                    <div class="roles-mobile__slide-card">
                                        <div class="roles-mobile__slide-title">Photo Retoucher</div>
                                        <div class="roles-mobile__slide-text">Join our creative network of Photo Retouchers</div>
                                        <div class="roles-mobile__slide-button-container">
                                            <router-link class="roles-mobile__slide-button roles-mobile__slide-button_yellow" :to="{name: 'Registration', query: {role: 'editor'}}">
                                                Learn More
                                                <img class="roles-mobile__slide-button-icon" src="@/assets/icons/Arrow_right.svg">
                                            </router-link>
                                        </div>
                                    </div>
                                </template>
                            </slide>
                        </carousel-3d>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 calendar-mobile p-3">
                    <img class="calendar-mobile__image" src="@/assets/images/About/Calendar.svg">
                    <div class="calendar-mobile__title">
                        EASILY PLAN YOUR ECOM STORE AND SOCIAL MEDIA CONTENT
                    </div>
                    <div class="calendar-mobile__text mt-3">
                        Simplify your social media planning by performing all your social tasks under one platform
                        <ul class="mt-3">
                            <li>Plan, Schedule, Edit, and Publish Content</li>
                            <li>Integrates with Shopify, Instagram, Facebook and Amazon</li>
                        </ul>
                    </div>
<!--                    <div class="calendar-mobile__button-container">-->
<!--                        <button class="calendar-mobile__button">Learn More</button>-->
<!--                    </div>-->
                </div>
                <div class="calendar">
                    <div class="col-6">
                        <img class="calendar__image" src="@/assets/images/About/Calendar.svg">
                    </div>
                    <div class="col-6">
                        <div class="calendar__body">
                            <div class="calendar__title">
                                EASILY PLAN YOUR ECOM STORE AND SOCIAL MEDIA CONTENT
                            </div>
                            <div class="calendar__text">
                                Simplify your social media planning by performing all your social tasks under one platform
                                <ul class="mt-3">
                                    <li>Plan, Schedule, Edit, and Publish Content</li>
                                    <li>Integrates with Shopify, Instagram, Facebook and Amazon</li>
                                </ul>
                            </div>
<!--                            <div class="calendar__button-container">-->
<!--                                <button class="calendar__button">Learn More</button>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 features">
                    <div class="features__title">Features</div>
                    <div class="features__items">
                        <div class="features__item features__item_green">
                            <div class="features__item-image-block">
                                <img class="features__item-image" src="@/assets/images/About/Feature_1.svg">
                            </div>
                            <div class="features__item-title">
                                SELL MORE OR GROW YOUR AUDIENCE
                            </div>
                            <div class="features__item-text">
                                Grow you store with quality images and scheduled content. Whether you are a business or an influencer, studio images go a long way for brand building
                            </div>
                        </div>
                        <div class="features__item features__item_blue">
                            <div class="features__item-item-block">
                                <img class="features__item-image" src="@/assets/images/About/Feature_2.svg">
                            </div>
                            <div class="features__item-title">
                                FOLLOW THE TAILORED RETOUCHING JOURNEY
                            </div>
                            <div class="features__item-text">
                                Customized image retouching to your specific brand standards + aesthetic and follow the progress in real time
                            </div>
                        </div>
                        <div class="features__item features__item_yellow">
                            <div class="features__item-image-block">
                                <img class="features__item-image" src="@/assets/images/About/Feature_3.svg">
                            </div>
                            <div class="features__item-title">
                                AI FOR ALT TEXT & IMAGE NAMING
                            </div>
                            <div class="features__item-text">
                                Our powerful AI tool will recommend keywords for both your image naming and Alt text to boost your SEO!
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 features-mobile">
                    <div class="features-mobile__title">Features</div>
                    <div class="features-mobile__slides">
                        <carousel-3d class="features-mobile__slides-container">
                            <slide :index="0" class="features-mobile__slide features-mobile__slide_green">
                                <template>
                                    <div class="features-mobile__slide-image-block">
                                        <img class="features-mobile__slide-image" src="@/assets/images/About/Feature_1.svg">
                                    </div>
                                    <div class="features-mobile__slide-title">
                                        SELL MORE OR GROW YOUR AUDIENCE
                                    </div>
                                    <div class="features-mobile__text">
                                        Grow you store with quality images and scheduled content. Whether you are a business or an influencer, studio images go a long way for brand building
                                    </div>
                                </template>
                            </slide>
                            <slide :index="1" class="features-mobile__slide features-mobile__slide_blue">
                                <template>
                                    <div class="features-mobile__slide-image-block">
                                        <img class="features-mobile__slide-image" src="@/assets/images/About/Feature_2.svg">
                                    </div>
                                    <div class="features-mobile__slide-title">
                                        FOLLOW THE TAILORED RETOUCHING JOURNEY
                                    </div>
                                    <div class="features-mobile__text">
                                        Customized image retouching to your specific brand standards + aesthetic and follow the progress in real time
                                    </div>
                                </template>
                            </slide>
                            <slide :index="2" class="features-mobile__slide features-mobile__slide_yellow">
                                <template>
                                    <div class="features-mobile__slide-image-block">
                                        <img class="features-mobile__slide-image" src="@/assets/images/About/Feature_3.svg">
                                    </div>
                                    <div class="features-mobile__slide-title">
                                        AI FOR ALT TEXT & IMAGE NAMING
                                    </div>
                                    <div class="features-mobile__text">
                                        Our powerful AI tool will recommend keywords for both your image naming and Alt text to boost your SEO!
                                    </div>
                                </template>
                            </slide>
                        </carousel-3d>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 brands">
                    <div class="brands__title">Brands That Trust Us</div>
                    <div class="row red_row brands__brands-container">
                        <img src="@/assets/images/About/Brand_atlassian.svg" class="brands__brand">
                        <img src="@/assets/images/About/Brand_slack.svg" class="brands__brand">
                        <img src="@/assets/images/About/Brand_dropbox.svg" class="brands__brand">
                        <img src="@/assets/images/About/Brand_shopify.svg" class="brands__brand">
                        <img src="@/assets/images/About/Brand_google.svg" class="brands__brand">
                    </div>
                </div>
                <div class="col-12 brands-mobile">
                    <div class="brands-mobile__title">Brands That Trust Us</div>
                    <div class="row red_row brands-mobile__brands-container">
                        <img src="@/assets/images/About/Brand_mob_atlassian.svg" class="brands-mobile__brand">
                        <img src="@/assets/images/About/Brand_mob_slack.svg" class="brands-mobile__brand">
                        <img src="@/assets/images/About/Brand_mob_dropbox.svg" class="brands-mobile__brand">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 works">
                    <div class="works__title">Our Work</div>
                    <div class="works__images-container">
                        <img src="@/assets/images/About/Our_work_1.png" class="works__image">
                        <img src="@/assets/images/About/Our_work_4.png" class="works__image">
                        <img src="@/assets/images/About/Our_work_7.png" class="works__image">
                        <img src="@/assets/images/About/Our_work_2.png" class="works__image works__image_second">
                        <img src="@/assets/images/About/Our_work_5.png" class="works__image">
                        <img src="@/assets/images/About/Our_work_8.png" class="works__image">
                        <img src="@/assets/images/About/Our_work_3.png" class="works__image">
                        <img src="@/assets/images/About/Our_work_6.png" class="works__image">
                        <img src="@/assets/images/About/Our_work_9.png" class="works__image">
                    </div>
                </div>
                <div class="col-12 works-mobile">
                    <div class="works-mobile__title">Our Work</div>
                    <div class="works-mobile__images-container">
                        <img src="@/assets/images/About/Our_work_1.png" class="works-mobile__image">
                        <img src="@/assets/images/About/Our_work_3.png" class="works-mobile__image">
                        <img src="@/assets/images/About/Our_work_5.png" class="works-mobile__image">
                        <img src="@/assets/images/About/Our_work_7.png" class="works-mobile__image">
                        <img src="@/assets/images/About/Our_work_2.png" class="works-mobile__image works-mobile__image_second">
                        <img src="@/assets/images/About/Our_work_4.png" class="works-mobile__image">
                        <img src="@/assets/images/About/Our_work_6.png" class="works-mobile__image">
                        <img src="@/assets/images/About/Our_work_8.png" class="works-mobile__image">
                    </div>
                </div>
            </div>
        </div>
    </FluidLayout>
</template>

<script>
import FluidLayout from "@/layouts/FluidLayout";
import { Carousel3d, Slide } from 'vue-carousel-3d';
import { mapGetters } from 'vuex';
import ('@/css/carousel3D.css');

export default {
    name: 'About',
    components: {
        FluidLayout,
        Carousel3d,
        Slide,
    },
    data() {
        return {
            role: 'Business'
        }
    },
    methods: {
        changeRole() {
            document.querySelectorAll('.roles-mobile__slide').forEach(element => element.classList.remove('current'));
            document.querySelector('#slide' + this.role).classList.add('current');
        },
        getImagePath(index) {
            return '@/assets/images/About/Our_work_' + index + '.png';
        }
    },
    computed: {
        ...mapGetters([
            'isLogged',
        ]),
    }
}
</script>

<style lang="scss" scoped>
.red_row {
    background: #FCEFF4;
}
.banner {
    padding-right: 0!important;
    padding-left: 105px;
    display: flex;
    justify-content: space-between;
    position: relative;
    top: -107px;

    @media only screen and (max-width: 992px) {
        display: none;
    }

    &-left-banner {
        max-width: 480px;
        max-height: 385px;
        position: relative;
        top: 140px;

        font-weight: 700;
        font-size: 120px;
        font-family: 'Bebas Neue', sans-serif;
        line-height: 120px;

        &_purple {
            color: #F89DC3;
        }
        &_red {
            color: #F89DA5;
        }
        &_brown {
            color: #F8A683;
        }
    }
    &-mobile {
        padding-left: 10px;
        padding-top: 40px;
        padding-bottom: 32px;

        @media only screen and (min-width: 993px) {
            display: none;
        }

        &-button {
            padding: 14px 31px;
            background: linear-gradient(180deg, #E27582 0%, #FEC0C8 99.5%), #FCF5EF;
            border: 0.5px solid white;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
            border-radius: 100px;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: white;
            text-decoration: none;
        }
    }
    &-left-mobile-banner {
        max-width: 480px;
        max-height: 385px;
        position: relative;
        margin-bottom: 50px;

        font-weight: 700;
        font-size: 65px;
        font-family: 'Bebas Neue', sans-serif;
        line-height: 70px;

        &_purple {
            color: #F89DC3;
        }
        &_red {
            color: #F89DA5;
        }
        &_brown {
            color: #F8A683;
        }
    }
}
.roles {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-width: 992px) {
        display: none;
    }

    &__title {
        margin-bottom: 25px;
        font-weight: 600;
        font-size: 40px;
        line-height: 64px;
        letter-spacing: -2px;
        color: #333333;
        text-align: center;
    }
    &__cards {
        max-width: 950px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__card {
        max-width: 400px;

        &:hover {
            .roles__button-container {
                display: block;
            }
        }
    }
    &__image-block {
        height: 400px;
        width: 400px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;

        &_red {
            background: linear-gradient(180deg, #E27582 0%, #FEC0C8 99.5%);
        }
        &_yellow {
            background: linear-gradient(180deg, #F1B24D 0%, #FEE5BF 99.75%);
        }
    }
    &__text-block {
        margin-left: 10px;
        margin-right: 10px;
        padding: 15px 14px;
        position: relative;
        top: -120px;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.08) 0%, rgba(40, 0, 5, 0.08) 100%), white;
        box-shadow: 8px 8px 32px rgba(35, 0, 24, 0.324757);
        border-radius: 12px;
    }
    &__text-title {
        margin-bottom: 8px;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #333333;
    }
    &__text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #494949;
    }
    &__button-container {
        display: none;
        margin-top: 20px;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    &__button {
        margin-left: auto;
        margin-right: auto;
        padding: 15px 25px;
        border: none;
        border-radius: 92px;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: white;
        text-align: center;
        text-decoration: none;

        &_red {
            background: linear-gradient(180deg, #E27582 0%, #FEC0C8 99.5%);
        }
        &_yellow {
            background: linear-gradient(180deg, #F1B24D 0%, #FEE5BF 99.75%), linear-gradient(180deg, #E27582 0%, #FEC0C8 99.5%);
        }
    }
    &-mobile {
        padding-top: 32px;
        padding-bottom: 32px;
        margin-bottom: -100px;

        @media only screen and (min-width: 993px) {
            display: none;
        }

        &__title {
            text-align: center;
            font-weight: 600;
            font-size: 24px;
            line-height: 64px;
            letter-spacing: -2px;
            color: #333333;
        }
        &__buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        &__button {
            padding: 8px 33px;
            border: 1px solid #696969;
            border-radius: 24px;
            font-weight: 600;
            font-size: 16px;
            line-height: 32px;
            color: #696969;

            &_red.active {
                border: none;
                background: linear-gradient(180deg, #E27582 0%, #FEC0C8 99.5%);
                color: white;
            }
            &_yellow.active {
                border: none;
                background: linear-gradient(180deg, #F1B24D 0%, #FEE5BF 99.75%);
                color: white;
            }
        }
        &__slides-container {
            height: 600px !important;
        }
        &__slide {
            width: 320px;
            height: 600px !important;

            border: none !important;
            background: none;
            @media only screen and (max-width: 400px) {
                width: 300px !important;
            }
            &-image {
                max-width: 260px;
                width: 100%;

                &-block {
                    width: 100%;
                    padding-top: 30px;
                    border-radius: 14.7872px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    &_red {
                        background: linear-gradient(180deg, #E27582 0%, #FEC0C8 99.5%);
                    }
                    &_yellow {
                        background: linear-gradient(180deg, #F1B24D 0%, #FEE5BF 99.75%), linear-gradient(180deg, #E27582 0%, #FEC0C8 99.5%);
                    }
                }
            }
            &-card {
                margin-left: 10px;
                margin-right: 10px;
                padding: 15px 14px;

                position: relative;
                top: -100px;

                background: linear-gradient(135deg, rgba(255, 255, 255, 0.08) 0%, rgba(40, 0, 5, 0.08) 100%), white;
                box-shadow: 7.39359px 7.39359px 29.5743px rgba(35, 0, 24, 0.324757);
                border-radius: 11.0904px;
            }
            &-title {
                font-weight: 600;
                font-size: 16.6356px;
                line-height: 30px;
                color: #333333;
            }
            &-text {
                font-weight: 400;
                font-size: 14.7872px;
                line-height: 18px;
                color: #494949;
            }
            &-button {
                margin-left: auto;
                margin-right: auto;
                padding: 15px 25px;
                border: none;
                border-radius: 92px;
                font-weight: 600;
                font-size: 15px;
                line-height: 18px;
                color: white;
                text-align: center;
                text-decoration: none;

                &-container {
                    margin-top: 15px;
                    display: flex;
                    justify-content: center;
                }
                &-icon {
                    display: inline;
                    width: 9px;
                }
                &_red {
                    background: linear-gradient(180deg, #E27582 0%, #FEC0C8 99.5%);
                }
                &_yellow {
                    background: linear-gradient(180deg, #F1B24D 0%, #FEE5BF 99.75%), linear-gradient(180deg, #E27582 0%, #FEC0C8 99.5%);
                }
            }
        }
    }
}
.calendar {
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1280px;

    display: flex;
    flex-direction: row;
    align-items: center;

    @media only screen and (max-width: 992px) {
        display: none;
    }

    &__image {
        max-width: 650px;
        width: 100%;
    }
    &__body {
        margin-left: 50px;
    }
    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: -2px;
        color: #333333;
    }
    &__text {
        margin-top: 15px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #333333;
    }
    &__button {
        padding: 15px 25px;
        background: #FCF5EF;
        border: 0.5px solid #494949;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 100px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #494949;
    }
    &-mobile {
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media only screen and (min-width: 993px) {
            display: none;
        }

        &__image {
            max-width: 640px;
            width: 100%;
        }
        &__title {
            margin-top: 15px;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            letter-spacing: -2px;
            color: #333333;
        }
        &__text {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #333333;
        }
        &__button {
            padding: 15px 25px;
            background: #FCF5EF;
            border: 0.5px solid #494949;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
            border-radius: 100px;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #494949;

            &-container {
                display: flex;
                justify-content: center;
            }
        }
    }
}
.features {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1280px;

    @media only screen and (max-width: 992px) {
        display: none;
    }

    &__title {
        font-weight: 600;
        font-size: 40px;
        line-height: 64px;
        letter-spacing: -2px;
        color: #333333;
        text-align: center;
    }
    &__items {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
    }
    &__item {
        width: 290px;
        padding: 16px 16px 35px 16px;
        border-radius: 16px;
        box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.04);

        &_green {
            background: #E0FDF1;
        }
        &_blue {
            background: #E0F9FD;
        }
        &_yellow {
            background: #F9F8E0;
        }
        &-image {
            width: 100%;
        }
        &-title {
            margin-top: 18px;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            text-transform: uppercase;
            color: #333333;
        }
        &-text {
            margin-top: 10px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #333333;
            opacity: 0.9;
        }
    }
    &-mobile {
        margin-bottom: 50px;

        @media only screen and (min-width: 993px) {
            display: none;
        }

        &__slide {
            padding: 12px 12px 18px 12px;
            border-radius: 16px;
            border: none;

            &-title {
                margin-top: 14px;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                text-transform: uppercase;
                color: #333333;
            }
            &_green {
                background: #E0FDF1;
                box-shadow: 0px 24px 48px rgba(0, 0, 0, 0.04);
            }
            &_blue {
                background: #E0F9FD;
                box-shadow: 0px 20.8622px 41.7244px rgba(0, 0, 0, 0.04);
            }
            &_yellow {
                background: #F9F8E0;
                box-shadow: 0px 20.8622px 41.7244px rgba(0, 0, 0, 0.04);
            }
            &-image {
                &-block {
                }
            }
        }
        &__title {
            font-weight: 600;
            font-size: 24px;
            line-height: 64px;
            letter-spacing: -2px;
            color: #333333;
            text-align: center;
        }
        &__text {
            margin-top: 10px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;

            color: #333333;

            opacity: 0.9;
        }
    }
}
.brands {
    margin-top: 100px;

    @media only screen and (max-width: 992px) {
        display: none;
    }

    &__title {
        margin-bottom: 25px;
        font-weight: 600;
        font-size: 40px;
        line-height: 64px;
        letter-spacing: -2px;
        color: #333333;
        text-align: center;
    }
    &__brands-container {
        padding: 28px 90px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    &__brand {
        height: 100%;
        width: 100%;
        max-width: 150px;
    }
    &-mobile {
        @media only screen and (min-width: 993px) {
            display: none;
        }

        &__title {
            font-weight: 600;
            font-size: 24px;
            line-height: 64px;
            letter-spacing: -2px;
            color: #333333;

            text-align: center;
        }
        &__brands-container {
            padding: 15px 35px;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
        &__brand {
            height: 15px;
            width: 100%;
            max-width: 75px;
        }
    }
}
.works {
    margin-top: 130px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 1280px;

    @media only screen and (max-width: 992px) {
        display: none;
    }

    &__title {
        font-weight: 600;
        font-size: 40px;
        line-height: 64px;
        letter-spacing: -2px;
        color: #333333;
        text-align: center;
    }
    &__images-container {
        max-height: 1555px;
        max-width: 1233px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
    }
    &__image {
        width: 395px;
        height: 480px;
        margin-bottom: 24px;
        border-radius: 10px;

        &_second {
            margin-top: 40px;
        }
    }
    &-mobile {
        margin-bottom: 100px;

        @media only screen and (min-width: 993px) {
            display: none;
        }

        &__title {
            margin-top: 55px;
            font-weight: 600;
            font-size: 24px;
            line-height: 64px;
            letter-spacing: -2px;
            color: #333333;
            text-align: center;
        }
        &__images-container {
            max-height: 920px;
            max-width: 450px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
        }
        &__image {
            width: 160px;
            height: 200px;
            margin-bottom: 24px;
            border-radius: 10px;

            &_first {
            }
            &_second {
                margin-top: 20px;
            }
        }
    }
}
</style>
