<template>
    <div>
        <MainLayout :is-show-header="true">
            <div class="body_active" :class="[isMobile ? 'container' : 'container-fluid']">
                <div class="row">
                    <div :class="is_type_user===editor ? 'col-12' : 'col-12 col-lg-9'">
                        <div v-if="is_type_user===business" class="mb-5">
                            <hr class="mt-5"/>
                            <ActiveJobs ref="active_jobs" :showCreateJob="true" :isMobile="isMobile" @getJobs="getJobs" />
                        </div>
                        <hr class="mt-5">
                        <div class="row m_b_14 p-1 mt-5">
                            <router-link class="navbar-brand nav-link-text title-1" :to="{ name: 'PastJobs'}">
                                Past Jobs
                            </router-link>
                        </div>
                        <div class="row pt-4">
                            <div class="input_box">
                                <input class="icon_input_search style_input2" v-model="past_jobs_search_key" @keyup="searchPastJob" type="text">
                                <img class="search_icon" src="@/assets/icons/search.svg" alt="">
                            </div>
                        </div>
                        <div v-if="show" class="row mb_80" :class="isMobile ? 'm_b_103' : 'm_b_196'">
                            <div class="col-10">
                                <div class="active_jobs_row" v-if="past_jobs_search.length">
                                    <div class="card_job card_job_s cp" v-for="(job, index) in past_jobs_search" :key="'job-card-' + index">
                                        <div @click="scheduleFile(job)" class="img_div img_div_editor">
                                            <img v-if="past_jobs_image_preview_urls[job.id] === 'tif'" src="@/assets/images/tif.png" class="card-img-top" :alt="'job-image-' + index">
                                            <img v-if="past_jobs_image_preview_urls[job.id]" :src="past_jobs_image_preview_urls[job.id]" class="card-img-top" :alt="'job-image-' + index">
                                            <img v-else src="@/assets/images/raw.png" class="card-img-top" :alt="'job-image-' + index">
                                            <div class="details_work_images">
                                                <div>Files: <span class="fw-400">{{ job.image_count }}</span></div>
                                                <div>Editing Level: <span class="fw-400">{{ job['editing_level'] }}</span></div>
                                                <div>Acceptance: <span class="fw-400">{{ acceptance(job) }}%</span></div>
                                                <div>Time Frame: <span class="fw-400">{{ timerFrame(job) }} min/avg</span></div>
                                                <div>End date: <span class="fw-400">{{ getAmericanDateFormat(job.updated_at) }}</span></div>
                                                <div v-if="job.publishing_files && job.publishing_files.length > 0">
                                                    Last Publication: <br>
                                                    <span class="fw-400">
                                                        <span class="fromUpperLetter">{{job.publishing_files[0].platform}}</span>
                                                        ({{getAmericanDateFormat(job.publishing_files[0].date_publication)}})
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="name_div main_text text-center">
                                                {{ job.name }}
                                            </div>
                                        </div>
                                        <template v-if="is_type_user===editor">
                                            <div class="loader_line">
                                                <div class="done" style="width:100%"/>
                                            </div>
                                            <div class="card_body text-center">
                                                All files approved
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div v-if="!isEmptyObject(job.review)" @click="openReviewModal(job)" class="leave-review">
                                                Leave Review
                                            </div>
                                            <div class="rating_job" v-else>
                                                <star-rating
                                                        v-model="job.review.rating"
                                                        :show-rating="false"
                                                        v-bind:star-size="22"
                                                        inactive-color="#D1D2D3"
                                                        :active-color="getDefaultColor"
                                                        :read-only="true"/>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="is_type_user===business" class="col-12 col-lg-3" :class="[isMobile ? '' : 'p-0 with-left-border']">
                        <div class="row mb-5 sticky-top sticky-top-position z-index-1">
                            <div class="col-12">
                                <div class="assetsTitle">
                                    <span>Files & Assets</span>
                                    <span v-if="isMobile" @click="showStyleGuideInfo = !showStyleGuideInfo">
                                        Info
                                        <img src="@/assets/icons/info.svg" class="info-icon" alt="info">
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 mt-3 mb-3" v-if="isMobile">
                                <span v-if="showStyleGuideInfo" @click="showStyleGuideInfo = !showStyleGuideInfo">
                                    Create a style guide to autofill job posting parameters and to provide detailed editing instructions and guidelines for similar projects
                                </span>
                            </div>
                            <div v-if="tab === null" class="col-12">
                                <div class="tab_pages">
                                    <div class="col item_tab item_tab_red hovertext" @click="selectTab(1)"
                                         data-hover="Create a style guide to autofill job posting parameters and to provide
                                         detailed editing instructions and guidelines for similar projects"
                                         :class="[tab===1 ? 'item_tab_active item_tab_active_red' : '',
                                         !isMobile ? 'd-flex justify-content-between' : '']">
                                        <div class="item_tab_icon" v-if="isMobile">
                                            <img class="" src="@/assets/icons/jobs_style_guides_tab.svg" alt="">
                                        </div>
                                        <span>
                                            Style Guides
                                            <img src="@/assets/icons/info.svg" v-if="!isMobile" class="info-icon ms-3" alt="info">
                                        </span>
                                        <img v-if="!isMobile" src="@/assets/icons/direction-right.svg" alt="">
                                    </div>
                                    <div class="col item_tab item_tab_green" @click="selectTab(2)"
                                         :class="[tab===2 ? 'item_tab_active item_tab_active_green' : '',
                                         !isMobile ? 'd-flex justify-content-between' : '']">
                                        <div class="item_tab_icon" v-if="isMobile">
                                            <img class="" src="@/assets/icons/jobs_sample_work_tab.svg" alt="">
                                        </div>
                                        Sample Work
                                        <img v-if="!isMobile" src="@/assets/icons/direction-right.svg" alt="">
                                    </div>
                                    <div class="col item_tab item_tab_blue" @click="selectTab(3)"
                                         :class="[tab===3 ? 'item_tab_active item_tab_active_blue' : '',
                                         !isMobile ? 'd-flex justify-content-between' : '']">
                                        <div class="item_tab_icon" v-if="isMobile">
                                            <img class="" src="@/assets/icons/jobs_files_tab.svg" alt="">
                                        </div>
                                        Files
                                        <img v-if="!isMobile" src="@/assets/icons/direction-right.svg" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <template v-if="is_type_user===business">
                                    <div class="row mt-3">
                                        <div class="col-12">
                                            <div v-if="tab === 1" class="item_tab_selected" @click="selectTab(null)">
                                                <img src="@/assets/icons/direction-left.svg" alt="">
                                                Style Guides
                                            </div>
                                            <div v-if="tab === 2" class="item_tab_selected" @click="selectTab(null)">
                                                <img src="@/assets/icons/direction-left.svg" alt="">
                                                Sample Work
                                            </div>
                                            <div v-if="tab === 3" class="item_tab_selected" @click="selectTab(null)">
                                                <img src="@/assets/icons/direction-left.svg" alt="">
                                                Files
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="tab===1" class="pt-4">
                                        <div class="row v12345" v-if="isMobile">
                                            <div class="col-12">
                                                <div @click="addStyleGuideMob" class="add_button_mobile">
                                                    <div class="button_label main_text">
                                                        + Add Style Guide
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab_item_d">
                                            <div class="row">
                                                <div class="col-12 mb-4 d-flex justify-content-center align-items-center">
                                                    <div @click="addStyleGuide" class="add_button red-gradient-button">
                                                        <div class="icon">
                                                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <line x1="39.5" y1="4.5" x2="39.5" y2="75.5" stroke="white" stroke-width="9" stroke-linecap="round"/>
                                                                <line x1="75.5" y1="39.5" x2="4.5" y2="39.5" stroke="white" stroke-width="9" stroke-linecap="round"/>
                                                            </svg>
                                                        </div>
                                                        <div class="button_label main_text button_label_white">
                                                            Add Style Guide
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="add_style_guide" class="col-12">
                                                    <div class="style_guide_box">
                                                        <div class="title-1">Add Style Guide</div>
                                                        <div class="form_style_guide">
                                                            <div class="col">
                                                                <div class="input_item">
                                                                    <div class="title_form">Name</div>
                                                                    <div class="input_b1">
                                                                        <input type="text"
                                                                               :class="{ 'is-invalid': errors.name }"
                                                                               v-model="style_guide.name"
                                                                               class="form-control style_input1"
                                                                               placeholder="Type something...">
                                                                        <div class="invalid-feedback">
                                                                            This field is required
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Remove Background</div>
                                                                    <div class="input_b1">
                                                                        <select v-model="style_guide.remove_background" :class="{ 'is-invalid': errors.remove_background }" class="style_input1 form-select">
                                                                            <option selected disabled hidden value="">Yes / No</option>
                                                                            <option key="yes">Yes</option>
                                                                            <option key="no">No</option>
                                                                        </select>
                                                                        <div class="invalid-feedback">
                                                                            This field is required
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Size</div>
                                                                    <div class="d-flex justify-content-between">
                                                                        <div class="d-flex align-items-center me-2">
                                                                            <input type="text" v-model="style_guide.size_with" :class="{ 'is-invalid_c': errors.size_with}" class="form-control style_input1 with_input1" placeholder="300">
                                                                            <div class="x">x</div>
                                                                            <input type="text" v-model="style_guide.size_height" :class="{ 'is-invalid_c': errors.size_height}" class="form-control style_input1 with_input1" placeholder="300">
                                                                        </div>
                                                                        <input type="text" v-model="style_guide.unit_measurement" :class="{ 'is-invalid_c': errors.unit_measurement}" class="form-control style_input1 with_input1" placeholder="px">
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">File Format</div>
                                                                    <div class="input_b1">
                                                                        <select v-model="style_guide.file_format" :class="{ 'is-invalid': errors.file_format}" class="style_input1 form-select">
                                                                            <option selected disabled hidden value="">PNG, jpeg...</option>
                                                                            <option key="PNG">PNG</option>
                                                                            <option key="JPG">JPG</option>
                                                                            <option key="Jpeg">Jpeg</option>
                                                                            <option key="TIF">TIF</option>
                                                                            <option key="GIF">GIF</option>
                                                                        </select>
                                                                        <div class="invalid-feedback">
                                                                            This field is required
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Color Profile</div>
                                                                    <div class="input_b1">
                                                                        <select v-model="style_guide.color_profile" :class="{ 'is-invalid': errors.color_profile}" class="style_input1 form-select">
                                                                            <option selected disabled hidden value=""> RBG, CMYK, ICC...</option>
                                                                            <option key="RBG">RBG</option>
                                                                            <option key="CMYK">CMYK</option>
                                                                            <option key="ICC">ICC</option>
                                                                        </select>
                                                                        <div class="invalid-feedback">
                                                                            This field is required
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Resolution</div>
                                                                    <div class="d-flex align-items-center">
                                                                        <input type="text" v-model="style_guide.resolution"
                                                                               class="style_input1 with_input1"
                                                                               :class="{ 'is-invalid_c': errors.resolution}"
                                                                               placeholder="300">
                                                                        <div class="x"/>
                                                                        <input type="text" v-model="style_guide.resolution_units"
                                                                               :class="{ 'is-invalid_c': errors.resolution_units}"
                                                                               class="style_input1 with_input1" placeholder="dpi">
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Logo
                                                                        <span v-if="user_files.length == 0" @click="openModalAddFile" class="upload-files-text">(upload)</span>
                                                                    </div>
                                                                    <div class="input_b1">
                                                                        <select v-model="style_guide.file_id_logo" class="style_input1 form-select">
                                                                            <option selected value="">Select logo</option>
                                                                            <option v-for="file in user_files" :value="file.id" :key="file.id">
                                                                                {{ file.file_name }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Watermark
                                                                        <span v-if="user_files.length == 0" @click="openModalAddFile" class="upload-files-text">(upload)</span>
                                                                    </div>
                                                                    <div class="input_b1">
                                                                        <select v-model="style_guide.file_id_watermark" class="style_input1 form-select">
                                                                            <option selected value="">Select watermark</option>
                                                                            <option v-for="file in user_files" :value="file.id" :key="file.id">
                                                                                {{ file.file_name }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Video Instructions
                                                                        <span v-if="user_files.length == 0" @click="openModalAddFile" class="upload-files-text">(upload)</span>
                                                                    </div>
                                                                    <div class="input_b1">
                                                                        <select v-model="style_guide.file_video_instruction_ids" class="style_input1 form-select">
                                                                            <option selected value="">Select video instructions</option>
                                                                            <option v-for="file in user_files" :key="file.id" :value="file.id">
                                                                                {{ file.file_name }}
                                                                            </option>
                                                                        </select>
                                                                        <div v-if="errors.video_instructions" class="invalid-feedback_c">
                                                                            This field is required
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Color palette
                                                                        <span v-if="user_files.length == 0" @click="openModalAddFile" class="upload-files-text">(upload)</span>
                                                                    </div>
                                                                    <div class="input_b1">
                                                                        <select v-model="style_guide.file_id_color_palette" class="style_input1 form-select">
                                                                            <option selected value="">Select color palette</option>
                                                                            <option v-for="file in user_files" :value="file.id" :key="file.id">
                                                                                {{ file.file_name }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Typography
                                                                        <span v-if="user_files.length == 0" @click="openModalAddFile" class="upload-files-text">(upload)</span>
                                                                    </div>
                                                                    <div class="input_b1">
                                                                        <select v-model="style_guide.file_id_video_typography" class="style_input1 form-select">
                                                                            <option selected value="">Select typography</option>
                                                                            <option v-for="file in user_files" :value="file.id" :key="file.id">
                                                                                {{ file.file_name }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Other</div>
                                                                    <div class="input_b1">
                                                                        <input type="text" v-model="style_guide.other" class="style_input1" placeholder="Type something...">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="btn_form">
                                                            <div class="btn_modal_t2 cp" @click="saveStyleGuide">Add</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="selects_tab_item">
                                                        <div v-for="style in style_guides" class="box_select">
                                                            <div @click="selectStyleG(style.id)" class="select_tab1">
                                                                <div class="title-2">{{ style.name }}</div>
                                                                <div class="col-2 text-end">
                                                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                                                         xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M18.8528 6.94727C18.6066 6.70121 18.2729 6.56299 17.9248 6.56299C17.5768 6.56299 17.243 6.70121 16.9969 6.94727L10.5 13.4441L4.00315 6.94727C3.75561 6.70819 3.42407 6.5759 3.07994 6.57889C2.73581 6.58188 2.40661 6.71991 2.16327 6.96326C1.91992 7.20661 1.78189 7.5358 1.7789 7.87993C1.77591 8.22406 1.9082 8.5556 2.14728 8.80314L9.57209 16.228C9.81822 16.474 10.152 16.6122 10.5 16.6122C10.8481 16.6122 11.1818 16.474 11.428 16.228L18.8528 8.80314C19.0988 8.55701 19.2371 8.22324 19.2371 7.87521C19.2371 7.52718 19.0988 7.1934 18.8528 6.94727Z" fill="#494949"/>
                                                                    </svg>
                                                                </div>
                                                            </div>
                                                            <div class="select_item" v-if="selected_style_guide === style.id">
                                                                <div style="margin-bottom: 15px; text-align: right;" @click="openDeleteStyleGuideModal(style.id)">
                                                                    <img src="@/assets/icons/trash.png" title="Delete" class="delete_icon">
                                                                </div>
                                                                <ul class="list_onesies">
                                                                    <li class="select-item-row">Background
                                                                        <div>{{ style.remove_background }}</div>
                                                                    </li>
                                                                    <li> Size
                                                                        <div>{{ style.size_with }} x {{ style.size_height }}
                                                                            {{ style.unit_measurement }}
                                                                        </div>
                                                                    </li>
                                                                    <li> File Format
                                                                        <div>{{ style.file_format }}</div>
                                                                    </li>
                                                                    <li> Color profile
                                                                        <div> {{ style.color_profile }}</div>
                                                                    </li>
                                                                    <li> Resolution
                                                                        <div> {{ style.resolution }} {{ style.resolution_units }}</div>
                                                                    </li>
                                                                    <li> Logo
                                                                        <div>{{ styleFileLogo(style) }}</div>
                                                                    </li>
                                                                    <li> Watermark
                                                                        <div>{{ styleFileWatermark(style) }}</div>
                                                                    </li>
                                                                    <li> Video
                                                                        <div>
                                                                            <a v-if="style.file_video_instruction" class="video_href" :href="style.file_video_instruction.file_url" target="_blank">
                                                                               {{ style.file_video_instruction.file_name }}.{{ style.file_video_instruction.type }}
                                                                            </a>
                                                                            <span v-else>No</span>
                                                                        </div>
                                                                    </li>
                                                                    <li> Color palette
                                                                        <div>{{ styleFileColorPalette(style) }}</div>
                                                                    </li>
                                                                    <li> Typography
                                                                        <div>{{ styleFileTypography(style) }}</div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="box_select box_add_style_guide">
                                                            <div class="mobile_guide_box" v-if="add_style_guide_mob">
                                                                <div class="input_item">
                                                                    <div class="title_form">Name</div>
                                                                    <div class="input_b1">
                                                                        <input type="text"
                                                                               :class="{ 'is-invalid': errors.name }"
                                                                               v-model="style_guide.name"
                                                                               class="form-control style_input1"
                                                                               placeholder="Type something...">
                                                                        <div class="invalid-feedback">
                                                                            This field is required
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Remove Background</div>
                                                                    <div class="input_b1">
                                                                        <select v-model="style_guide.remove_background" :class="{ 'is-invalid': errors.remove_background }" class="style_input1 form-select">
                                                                            <option selected disabled hidden value="">Yes / No</option>
                                                                            <option key="yes">Yes</option>
                                                                            <option key="no">No</option>
                                                                        </select>
                                                                        <div class="invalid-feedback">
                                                                            This field is required
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Size</div>
                                                                    <div class="d-flex justify-content-between input_b1">
                                                                        <div class="d-flex align-items-center">
                                                                            <input type="text" v-model="style_guide.size_with"
                                                                                   :class="{ 'is-invalid_c': errors.size_with}"
                                                                                   class="form-control style_input1 with_input1"
                                                                                   placeholder="300">
                                                                            <div class="x">x</div>
                                                                            <input type="text" v-model="style_guide.size_height"
                                                                                   :class="{ 'is-invalid_c': errors.size_height}"
                                                                                   class="form-control style_input1 with_input1"
                                                                                   placeholder="300">
                                                                        </div>
                                                                        <input type="text" v-model="style_guide.unit_measurement"
                                                                               :class="{ 'is-invalid_c': errors.unit_measurement}"
                                                                               class="stmob form-control style_input1 with_input1"
                                                                               placeholder="px">
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">File Format</div>
                                                                    <div class="input_b1">
                                                                        <select v-model="style_guide.file_format" :class="{ 'is-invalid': errors.file_format}" class="style_input1 form-select">
                                                                            <option selected disabled hidden value="">PNG, JPEG...</option>
                                                                            <option key="PNG">PNG</option>
                                                                            <option key="JPG">JPG</option>
                                                                            <option key="Jpeg">JPEG</option>
                                                                            <option key="TIF">TIF</option>
                                                                            <option key="GIF">GIF</option>
                                                                        </select>
                                                                        <div class="invalid-feedback">
                                                                            This field is required
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Color Profile</div>
                                                                    <div class="input_b1">
                                                                        <select v-model="style_guide.color_profile" :class="{ 'is-invalid': errors.color_profile}" class="style_input1 form-select">
                                                                            <option selected disabled hidden value=""> RBG, CMYK, ICC...</option>
                                                                            <option key="RBG">RBG</option>
                                                                            <option key="CMYK">CMYK</option>
                                                                            <option key="ICC">ICC</option>
                                                                        </select>
                                                                        <div class="invalid-feedback">
                                                                            This field is required
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Resolution</div>
                                                                    <div class="d-flex align-items-center">
                                                                        <input type="text" v-model="style_guide.resolution"
                                                                               class="style_input1 with_input1"
                                                                               :class="{ 'is-invalid_c': errors.resolution}"
                                                                               placeholder="300">
                                                                        <div class="x"/>
                                                                        <input type="text" v-model="style_guide.resolution_units"
                                                                               :class="{ 'is-invalid_c': errors.resolution_units}"
                                                                               class="style_input1 with_input1" placeholder="dpi">
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Logo</div>
                                                                    <div class="input_b1">
                                                                        <select v-model="style_guide.file_id_logo" class="style_input1 form-select">
                                                                            <option selected disabled hidden value="">Logo</option>
                                                                            <option v-for="file in user_files" :value="file.id" :key="file.id">
                                                                                {{ file.file_name }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Watermark</div>
                                                                    <div class="input_b1">
                                                                        <select v-model="style_guide.file_id_watermark" class="style_input1 form-select">
                                                                            <option selected disabled hidden value="">Watermark</option>
                                                                            <option v-for="file in user_files" :value="file.id" :key="file.id">
                                                                                {{ file.file_name }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Video Instructions</div>
                                                                    <div class="input_b1">
                                                                        <select v-model="style_guide.file_video_instruction_ids" class="style_input1 form-select">
                                                                            <option selected disabled hidden value="">Video Instructions</option>
                                                                            <option v-for="file in user_files" :value="file.id" :key="file.id">
                                                                                {{ file.file_name }}
                                                                            </option>
                                                                        </select>
                                                                        <div v-if="errors.video_instructions" class="invalid-feedback_c">
                                                                            This field is required
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Color palette</div>
                                                                    <div class="input_b1">
                                                                        <select v-model="style_guide.file_id_color_palette" class="style_input1 form-select">
                                                                            <option selected disabled hidden value="">Color palette</option>
                                                                            <option v-for="file in user_files" :value="file.id" :key="file.id">
                                                                                {{ file.file_name }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Typography</div>
                                                                    <div class="input_b1">
                                                                        <select v-model="style_guide.file_id_video_typography" class="style_input1 form-select">
                                                                            <option selected disabled hidden value="">Typography</option>
                                                                            <option v-for="file in user_files" :value="file.id" :key="file.id">
                                                                                {{ file.file_name }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="input_item">
                                                                    <div class="title_form">Other</div>
                                                                    <div class="input_b1">
                                                                        <input type="text" v-model="style_guide.other" class="style_input1" placeholder="Type something...">
                                                                    </div>
                                                                </div>
                                                                <div class="btn_form">
                                                                    <div class="btn_modal_t2 cp" @click="saveStyleGuide">Add</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="tab===2">
                                        <div class="row mt-4">
                                            <div class="col-12">
                                                <div @click="openModalAddSampleWorksFolder" class="add_button_mobile" v-if="isMobile">
                                                    <div class="button_label main_text">
                                                        + Add New Sample Work Folder
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab_item_d2">
                                            <div class="sample_work">
                                                <div class="col-12 mb-4 d-flex justify-content-center align-items-center">
                                                    <div @click="openModalAddSampleWorksFolder" class="add_button red-gradient-button">
                                                        <div class="icon">
                                                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <line x1="39.5" y1="4.5" x2="39.5" y2="75.5" stroke="white" stroke-width="9" stroke-linecap="round"/>
                                                                <line x1="75.5" y1="39.5" x2="4.5" y2="39.5" stroke="white" stroke-width="9" stroke-linecap="round"/>
                                                            </svg>
                                                        </div>
                                                        <div class="button_label main_text button_label_white text-center">
                                                            Add New Sample Work Folder
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="spw_item" v-for="sampleWorksFolder in sample_works_folders" :class="{'active': openedSampleWorksFolder == sampleWorksFolder.id}">
                                                    <div class="item_sample_work">
                                                        <div class="d-flex justify-content-between w-100" @click="openSampleWorksFolder(sampleWorksFolder.id)">
                                                            <div class="d-flex">
                                                                <div class="box_sample_work_img_item">
                                                                    <img v-if="sampleWorksFolder.sample_works.length > 0" class="img_item_w" :src='getSamplesWorksFolderPreviewImage(sampleWorksFolder)'>
                                                                    <img v-else class="img_item_w" src="@/assets/images/sbcf-default-avatar.png">
                                                                </div>
                                                                <div class="title_name_work">{{ sampleWorksFolder.name }}</div>
                                                            </div>
                                                            <div class="arrow_select">
                                                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M18.8528 6.94727C18.6066 6.70121 18.2729 6.56299 17.9248 6.56299C17.5768 6.56299 17.243 6.70121 16.9969 6.94727L10.5 13.4441L4.00315 6.94727C3.75561 6.70819 3.42407 6.5759 3.07994 6.57889C2.73581 6.58188 2.40661 6.71991 2.16327 6.96326C1.91992 7.20661 1.78189 7.5358 1.7789 7.87993C1.77591 8.22406 1.9082 8.5556 2.14728 8.80314L9.57209 16.228C9.81822 16.474 10.152 16.6122 10.5 16.6122C10.8481 16.6122 11.1818 16.474 11.428 16.228L18.8528 8.80314C19.0988 8.55701 19.2371 8.22324 19.2371 7.87521C19.2371 7.52718 19.0988 7.1934 18.8528 6.94727Z" fill="#494949"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="me-4" @click="openDeleteSampleWorksFolderModal(sampleWorksFolder.id)">
                                                                <img src="@/assets/icons/trash.png" title="Delete" class="delete_icon">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="box_images_work">
                                                        <div class="featured-products">
                                                            <div v-for="work in sampleWorksFolder.sample_works" @click="openFullImg(work)" class="featured-product-item">
                                                                <template v-if="work.type==='png'||work.type==='jpeg'||work.type==='jpg'">
                                                                    <img class="featured-product-item-image p-2" :src=work.file_url alt="">
                                                                </template>
                                                                <template v-else>
                                                                    <div class="featured-product-item-text p-2">{{ work.file_name }}</div>
                                                                    <div v-if="work.type && work.type.length > 0" class="featured-product-item-text p-2">(.{{ work.type }})</div>
                                                                </template>
                                                            </div>
                                                            <div @click="openModalAddSampleWork(sampleWorksFolder.id)" class="featured-product-item featured-product-item_new">
                                                                <img class="plus_icon" src='@/assets/images/Plus.png' alt="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="tab===3">
                                        <div class="row mt-4">
                                            <div class="col-12">
                                                <div @click="openModalAddFile" class="add_button_mobile" v-if="isMobile">
                                                    <div class="button_label main_text">
                                                        + Add New File
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tab_item_d2">
                                            <div class="box_search_sample_work">
                                                <div class="search_sample_work">
                                                    <input @keyup="keypressSearchFile" type="text" class="search_prod_inp" placeholder="">
                                                    <img class="search_icon" src="@/assets/icons/search.svg" alt="">
                                                </div>
                                            </div>
                                            <div class="sample_work margin_box_f">
                                                <div class="col-12 mb-4 d-flex justify-content-center align-items-center">
                                                    <div @click="openModalAddFile" class="add_button red-gradient-button">
                                                        <div class="icon">
                                                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <line x1="39.5" y1="4.5" x2="39.5" y2="75.5" stroke="white" stroke-width="9" stroke-linecap="round"/>
                                                                <line x1="75.5" y1="39.5" x2="4.5" y2="39.5" stroke="white" stroke-width="9" stroke-linecap="round"/>
                                                            </svg>
                                                        </div>
                                                        <div class="button_label main_text button_label_white text-center">
                                                            Add New File
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="spw_item2" v-for="item in search_file">
                                                    <div @click="selectFile(item.id)" class="item_sample_work">
                                                        <div class="d-flex">
                                                            <div class="box_sample_work_img_item img_file">
                                                                <template v-if="item.type==='png'||item.type==='jpeg'||item.type==='jpg'">
                                                                    <img class="img_item_w p-2" :src=item.file_url alt="">
                                                                </template>
                                                                <template v-else>
                                                                    <div class="img_item_w p-2 type_box_f">.{{ item.type }}</div>
                                                                </template>
                                                            </div>
                                                            <div class="box_file_n">
                                                                <div class="title_file_name">{{ item.file_name }}</div>
                                                                <div class="file_type" @click="goPageFile(item.file_url)">
                                                                    {{ item.type }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="arrow_select" @click="openDeleteFileModal(item.id)">
                                                            <img src="@/assets/icons/trash.png" title="Delete" class="delete_icon">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MainLayout>
        <div class="modal" id="photoModal" tabindex="-1">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body photoModal-body">
                        <img class="photo_full" :src='work_image_src' alt="">
                        <div class="d-flex justify-content-between align-items-center mt-4">
                            <span class="featured-product-item__name">{{work_image_name}}</span>
                            <span class="delete_featured-product-item" @click="deleteSampleWork">Delete</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="addSampleWorksFolderModal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Add New Sample Works Folder</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label class="form-label">Folder name</label>
                            <input type="text" class="form-control" v-model="sample_works_folder.name" placeholder="Folder name">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Cancel</button>
                        <button :disabled="!sample_works_folder.name" class="btn_modal_t2" :class="{disabled: !sample_works_folder.name}" @click="saveSampleWorksFolder">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="deleteSampleWorksFolderModal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Delete Sample Works Folder</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3 text-center">
                            <label class="form-label">Do you want to delete this folder?</label>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Cancel</button>
                        <button class="btn_modal_t2" @click="deleteSampleWorksFolder">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="deleteStyleGuideModal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Delete Style Guide</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3 text-center">
                            <label class="form-label">Do you want to delete this style guide?</label>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Cancel</button>
                        <button class="btn_modal_t2" @click="deleteStyleGuide">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="deleteFileModal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Delete File</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3 text-center">
                            <label class="form-label">Do you want to delete this file?</label>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Cancel</button>
                        <button class="btn_modal_t2" @click="deleteFile">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" id="addSampleWorkModal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Add New Sample Work</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label class="form-label">File name</label>
                            <input type="text" :class="{ 'is-invalid': sample_work.file_name_valid }"
                                   class="form-control" v-model="sample_work.file_name"
                                   placeholder="File name">
                            <div class="invalid-feedback">{{ sample_work.file_name_valid }}</div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">File</label>
                            <input @change="sampleWorkFileChange" type="file"
                                   :class="{ 'is-invalid': sample_work.file_valid }" class="form-control"
                                   placeholder="File">
                            <div class="invalid-feedback">{{ sample_work.file_valid }}</div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn_modal_t1" data-bs-dismiss="modal">Cancel</button>
                        <button class="btn_modal_t2" @click="saveSampleWork">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <ReviewModal ref="review_modal" @getProfileUser="getProfileUser" />
        <AddFileModal ref="add_file_modal" @getProfileUser="getProfileUser" />
    </div>
</template>

<script>
import MainLayout from '@/layouts/MainLayout';
import { Modal } from 'bootstrap';
import { errorMessage } from '../services/messages';
import {mapGetters, mapMutations} from 'vuex';
import TypeUserEnum from '@/enums/TypeUserEnum';
import StarRating from 'vue-star-rating';
import moment from 'moment-timezone';
import helpFunctions from "../helpFunctions";
import ReviewModal from "../components/Modals/ReviewModal";
import AddFileModal from "../components/Modals/AddFileModal";
import ActiveJobs from "../components/ActiveJobs";
import axios from "axios";
import ('../css/custom.css');

export default {
    name: "Jobs",
    components: {
        ActiveJobs,
        AddFileModal,
        ReviewModal,
        MainLayout,
        StarRating,
    },
    data() {
        return {
            user: {},
            is_type_user: true,
            business: TypeUserEnum.BUSINESS,
            editor: TypeUserEnum.EDITOR,
            past_jobs_search_key: null,
            past_jobs: {},
            past_jobs_search: {},
            past_jobs_image_preview_urls: {},
            style_guides: {},
            tab: null,
            selected_style_guide: '',
            select_work: '',
            add_style_guide: false,
            add_style_guide_mob: false,
            style_guide: {},
            openedSampleWorksFolder: null,
            deleteSampleWorksFolderId: null,
            deleteStyleGuideId: null,
            deleteFileId: null,
            errors: {},
            select_file: '',
            sample_works_folder: {
                name: null,
            },
            sample_work: {
                file: null,
                file_name: '',
                file_valid: false,
                file_name_valid: false
            },
            work_image_src: '',
            work_image_name: '',
            work_image_id: '',
            work_image_type: '',
            user_files: [],
            sample_works_folders: {},
            search_file: {},

            jobs: [],
            jobModals: {},

            showStyleGuideInfo: false,
            show: false
        }
    },
    destroyed() {
        Object.values(this.jobModals).forEach(jobModal => {
            jobModal.dispose();
        });
    },
    async mounted() {
        this.style_guide = helpFunctions.style_guide;
        this.errors = helpFunctions.job_errors;
        this.jobModals = helpFunctions.jobModals;
        await this.getProfileUser();
        this.jobModals.photoModal = new Modal(document.getElementById('photoModal'));
        this.jobModals.addSampleWorksFolderModal = new Modal(document.getElementById('addSampleWorksFolderModal'));
        this.jobModals.deleteSampleWorksFolderModal = new Modal(document.getElementById('deleteSampleWorksFolderModal'));
        this.jobModals.deleteStyleGuideModal = new Modal(document.getElementById('deleteStyleGuideModal'));
        this.jobModals.deleteFileModal = new Modal(document.getElementById('deleteFileModal'));
        this.jobModals.addSampleWorkModal = new Modal(document.getElementById('addSampleWorkModal'));

        this.checkTabQuery();
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
            'setUser',
        ]),
        checkTabQuery() {
            let tab = this.$route.query.tab ?? null;
            if (tab) {
                switch (tab) {
                    case 'style-guides':
                        this.tab = 1;
                        if (this.isMobile) {
                            this.addStyleGuideMob();
                        } else {
                            this.addStyleGuide();
                        }
                        break
                    case 'sample-works':
                        this.tab = 2;
                        break
                    case 'files':
                        this.tab = 3;
                        this.openModalAddFile();
                        break
                }
            }
        },
        styleFileLogo(style) {
            return style?.file_logo?.file_name || 'No';
        },
        styleFileWatermark(style) {
            return style?.file_watermark?.file_name || 'No';
        },
        styleFileColorPalette(style) {
            return style?.file_id_color_palette?.file_name || 'No';
        },
        styleFileTypography(style) {
            return style?.file_id_video_typography?.file_name || 'No';
        },
        isEmptyObject(obj) {
            return helpFunctions.isEmptyObject(obj);
        },
        sampleWorkFileChange(e) {
            const tmpFiles = e.target.files;
            if (tmpFiles.length === 0) {
                return false;
            }
            this.sample_work.file = tmpFiles[0];
        },
        clearSampleWork() {
            this.sample_work.file_name = '';
            this.sample_work.file_name_valid = false;
            this.sample_work.file = null;
            this.sample_work.file_valid = false;
        },
        async saveSampleWorksFolder() {
            try {
                this.showLoader();
                await this.$http.postAuth(`${this.$http.apiUrl()}profile/sample-works-folder`, this.sample_works_folder);
                this.sample_works_folder = {
                    name: null,
                };
                await this.getProfileUser();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.jobModals.addSampleWorksFolderModal.hide();
            this.hideLoader();
        },
        async saveSampleWork() {
            let error = false;
            if (!this.sample_work.file_name) {
                this.sample_work.file_name_valid = 'This field is required';
                error = true;
            } else {
                this.sample_work.file_name_valid = '';
            }
            if (!this.sample_work.file) {
                this.sample_work.file_valid = 'This field is required';
                error = true;
            } else {
                this.sample_work.file_valid = '';
            }
            if (error) {
                return;
            }
            try {
                this.showLoader();
                let data = new FormData();
                data.append('file', this.sample_work.file);
                data.append('folder_id', this.openedSampleWorksFolder);
                data.append('file_name', this.sample_work.file_name);
                await this.$http.postAuth(`${this.$http.apiUrl()}profile/sample-work`, data);
                this.clearSampleWork();
                await this.getProfileUser();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.jobModals.addSampleWorkModal.hide();
            this.hideLoader();
        },
        openReviewModal(job) {
            this.$refs.review_modal.load(job);
        },
        openModalAddFile() {
            this.$refs.add_file_modal.load();
        },
        openModalAddSampleWorksFolder() {
            this.jobModals.addSampleWorksFolderModal.show();
        },
        openModalAddSampleWork(id) {
            this.work_id = id;
            this.jobModals.addSampleWorkModal.show();
        },
        acceptance(job) {
            if(job.image_count !== 0) {
                return (100 - (job.images_decline.length * 100) / job.image_count).toFixed(2);
            }
            else {
                return 0;
            }
        },
        timerFrame(job) {
            if(job.image_count !== 0) {
                return parseInt((job.finished_worked_images_sum_sum_timers / job.image_count) / 60) + 1;
            }
            else {
                return 0;
            }
        },
        scheduleFile(job) {
            if (this.is_type_user === this.editor) {
                return;
            }
            this.$router.push('/job/' + job.id + '/upload-files/');
        },
        validationForm() {
            let error = false;
            if (!this.style_guide.name) {
                this.errors.name = true;
                error = true;
            }
            if (!this.style_guide.remove_background) {
                this.errors.remove_background = true;
                error = true;
            }
            if (!this.style_guide.size_with) {
                this.errors.size_with = true;
                error = true;
            }
            if (!this.style_guide.size_height) {
                this.errors.size_height = true;
                error = true;
            }
            if (!this.style_guide.unit_measurement) {
                this.errors.unit_measurement = true;
                error = true;
            }
            if (!this.style_guide.file_format) {
                this.errors.file_format = true;
                error = true;
            }
            if (!this.style_guide.resolution) {
                this.errors.resolution = true;
                error = true;
            }
            if (!this.style_guide.resolution_units) {
                this.errors.resolution_units = true;
                error = true;
            }
            if (!this.style_guide.color_profile) {
                this.errors.color_profile = true;
                error = true;
            }
            return error;
        },
        async saveStyleGuide() {
            if (this.validationForm()) {
                return;
            }
            try {
                this.showLoader();

                await this.$http.postAuth(`${this.$http.apiUrl()}profile/add-style-guide`, this.style_guide);
                this.clearForm();
                this.add_style_guide = false;
                this.add_style_guide_mob = false;
                await this.getProfileUser();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        openFullImg(img) {
            if (img.type==='png' || img.type==='jpeg' || img.type==='jpg') {
                this.work_image_src = img.file_url;
                this.work_image_type = img.type;
                this.work_image_name = img.file_name;
                this.work_image_id = img.id;
                this.jobModals.photoModal.show();
            } else {
                this.goPageFile(img.file_url);
            }
        },
        addStyleGuideMob() {
            this.add_style_guide_mob = !this.add_style_guide_mob;
            this.clearForm();
        },
        selectStyleG(val) {
            if (val == this.selected_style_guide) {
                this.selected_style_guide = '';
            } else {
                this.selected_style_guide = val;
            }
        },
        selectFile(val) {
            if (val == this.select_file) {
                this.select_file = '';
            } else {
                this.select_file = val;
            }
        },
        selectTab(val) {
            this.tab = val;
        },
        typeUser(data) {
            this.is_type_user = data;
        },
        keypressSearchFile(event) {
            let search_text = (event.target.value).toUpperCase();
            if (search_text && search_text.length > 1) {
                this.search_file = this.user_files.filter(file => (file.file_name).toUpperCase().includes(search_text));
            } else {
                this.search_file = this.user_files;
            }
        },
        goPageFile(url) {
            window.open(url, '_blank');
        },
        addStyleGuide() {
            this.add_style_guide = !this.add_style_guide;
            this.clearForm();
        },
        async getProfileUser() {
            try {
                this.showLoader();
                const response = await this.$http.getAuth(`${this.$http.apiUrl()}profile/user`);
                this.user = response?.data?.data?.user || {};
                //SHOULD FILL PREVIEW ARRAY
                response?.data?.data?.past_job.forEach(job => {
                    this.past_jobs_image_preview_urls[job.id] = null;
                });
                //CUSTOM FIX TO LOAD TIFF IMAGES
                let total_images = response?.data?.data?.past_job.length;
                let image_counter = 0;
                await response?.data?.data?.past_job.forEach(job => {
                    let image_path = (job?.image?.src_cropped || job?.image?.src) || '';
                    if(image_path) {
                        let file_name = image_path.split("/").pop();
                        let extension = file_name.split(".").pop();
                        if (extension.toLowerCase() === 'tiff') {
                            try {
                                //image_path = image_path.replace('/storage', '');
                                axios.get(image_path, {responseType: "blob"}).then(response => {
                                    const blob = new Blob([response.data], {type: response.data.type});
                                    const reader = new FileReader();
                                    reader.onload = e => {
                                        let tiff = new Tiff({buffer: e.target.result});
                                        let canvas = tiff.toCanvas();
                                        this.past_jobs_image_preview_urls[job.id] = canvas.toDataURL();
                                        image_counter++;
                                        if(image_counter === total_images) {
                                            this.show = true;
                                        }
                                    }
                                    reader.readAsArrayBuffer(blob);
                                });
                            } catch (e) {
                                this.past_jobs_image_preview_urls[job.id] = "tif";
                                image_counter++;
                                if(image_counter === total_images) {
                                    this.show = true;
                                }
                            }
                        }
                        else if (extension.toLowerCase() === 'gif' || extension.toLowerCase() === 'webp' || extension.toLowerCase() === 'bmp' ||
                            extension.toLowerCase() === 'jpeg' || extension.toLowerCase() === 'jpg' || extension.toLowerCase() === 'png') {
                            this.past_jobs_image_preview_urls[job.id] = image_path;
                            image_counter++;
                            if(image_counter === total_images) {
                                this.show = true;
                            }
                        }
                        else {
                            this.past_jobs_image_preview_urls[job.id] = "";
                            image_counter++;
                            if(image_counter === total_images) {
                                this.show = true;
                            }
                        }
                    }
                    else {
                        this.past_jobs_image_preview_urls[job.id] = '';
                        image_counter++;
                        if(image_counter === total_images) {
                            this.show = true;
                        }
                    }
                });
                //CUSTOM FIX TO LOAD TIFF IMAGES
                this.past_jobs = response?.data?.data?.past_job || {};
                this.past_jobs_search = response?.data?.data?.past_job || {};
                this.is_type_user = response?.data?.data?.user?.type_user || false;
                this.style_guides = response?.data?.data?.user?.style_guide || {};
                this.user_files = response?.data?.data?.user?.user_files || [];
                this.sample_works_folders = response?.data?.data?.user?.sample_works_folders || {};
                this.search_file = response?.data?.data?.user?.user_files || [];
                await this.getJobs();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
        },
        clearForm() {
            this.style_guide.name = '';
            this.style_guide.remove_background = '';
            this.style_guide.size_with = '';
            this.style_guide.size_height = '';
            this.style_guide.unit_measurement = '';
            this.style_guide.file_format = '';
            this.style_guide.color_profile = '';
            this.style_guide.resolution = '300';
            this.style_guide.resolution_units = 'dpi';
            this.style_guide.other = '';

            this.style_guide.file_id_logo = ''
            this.style_guide.file_id_watermark = '';
            this.style_guide.file_video_instruction_ids = '';

            this.errors.name = false;
            this.errors.remove_background = false;
            this.errors.size_with = false;
            this.errors.size_height = false;
            this.errors.unit_measurement = false;
            this.errors.file_format = false;
            this.errors.resolution_units = false;
            this.errors.resolution = false;
            this.errors.video_instructions = false;
            this.errors.color_profile = false;
        },
        async getJobs() {
            this.showLoader();
            const result = await this.$http.getAuth(`${this.$http.apiUrl()}get-user-owned-jobs`);
            this.hideLoader();
            if (!result?.data?.success) {
                return errorMessage('OOPS... Something went wrong...');
            }
            this.jobs = result?.data?.data?.jobs || [];
            if (this.is_type_user === this.business) {
                //SHOULD FILL PREVIEW ARRAY
                let image_preview_urls = {};
                this.jobs.forEach(job => {
                    image_preview_urls[job.id] = null;
                });
                this.$refs.active_jobs.load(image_preview_urls, this.jobs);
            }
        },
        searchPastJob(event) {
            let search_text = (event.target.value).toUpperCase();
            if (search_text && search_text.length > 1) {
                this.past_jobs_search = this.past_jobs.filter(job =>
                    (job.description != null && job.description.toUpperCase().includes(search_text)) ||
                    (job.name != null && job.name.toUpperCase().includes(search_text))
                );
            } else {
                this.past_jobs_search = this.past_jobs;
            }
        },
        openSampleWorksFolder(id) {
            if (this.openedSampleWorksFolder != id) {
                this.openedSampleWorksFolder = id;
            } else {
                this.openedSampleWorksFolder = '';
            }
        },
        openDeleteSampleWorksFolderModal(id) {
            this.deleteSampleWorksFolderId = id;
            this.jobModals.deleteSampleWorksFolderModal.show();
        },
        openDeleteStyleGuideModal(id) {
            this.deleteStyleGuideId = id;
            this.jobModals.deleteStyleGuideModal.show();
        },
        async deleteSampleWorksFolder() {
            try {
                this.showLoader();
                await this.$http.postAuth(`${this.$http.apiUrl()}profile/delete-sample-works-folder/${this.deleteSampleWorksFolderId}`);
                await this.getProfileUser();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.jobModals.deleteSampleWorksFolderModal.hide();
            this.hideLoader();
        },
        async deleteStyleGuide() {
            try {
                this.showLoader();
                await this.$http.postAuth(`${this.$http.apiUrl()}profile/delete-style-guide/${this.deleteStyleGuideId}`);
                await this.getProfileUser();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.jobModals.deleteStyleGuideModal.hide();
            this.hideLoader();
        },
        async deleteFile() {
            try {
                this.showLoader();
                await this.$http.postAuth(`${this.$http.apiUrl()}profile/delete-file/${this.deleteFileId}`);
                await this.getProfileUser();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.jobModals.deleteFileModal.hide();
            this.hideLoader();
        },
        async deleteSampleWork() {
            try {
                this.showLoader();
                await this.$http.postAuth(`${this.$http.apiUrl()}profile/delete-sample-work/${this.work_image_id}`);
                this.jobModals.photoModal.hide();
                await this.getProfileUser();
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.jobModals.deleteSampleWorksFolderModal.hide();
            this.hideLoader();
        },
        openDeleteFileModal(id) {
            this.deleteFileId = id;
            this.jobModals.deleteFileModal.show();
        },
        getSamplesWorksFolderPreviewImage(sampleWorksFolder) {
            return sampleWorksFolder.sample_works[0].file_url;
        },
        getAmericanDateFormat(date) {
            return moment.utc(date).local().format('MM/DD/YYYY');
        },
    },
    computed: {
        ...mapGetters([
            'getUser',
            'getDefaultColor'
        ]),
        isMobile() {
            return window.innerWidth <= 992;
        },
    },
}
</script>

<style lang="scss" scoped>
.input_box {
    position: relative;
}
.style_input2 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding: 10px 13px;
    height: 35px;
    color: #494949;
    background: white;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    width: 100%;
    padding-left: 40px;
}
.icon_input_search {
    padding-left: 40px;
    padding-right: 40px;
}
.upload-files-text {
    cursor: pointer;
    color: blue;
    font-size: 12px;
}
.img_div:hover {
    .img-delete-hover {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
    }
    .name_div {
        display: none;
    }
    .img-delete-hover__recreate-btn {
        font-weight: 700;
        font-size: 24px;
        color: black;
        opacity: 1;
        cursor: pointer;
    }
}
.details_work_images {
    display: none;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 10px 13px;
    flex-direction: column;
    justify-content: center;
}
.details_work_images > div {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #494949;
    overflow-x: hidden;
    text-overflow: ellipsis;
}
.img_div_editor:hover {
    .card-img-top {
        opacity: 0.3;
    }
    .details_work_images {
        display: flex;
    }
    .name_div.main_text {
        display: none;
    }
}
.modal-title {
    width: 100%;
    text-align: center;
}
.title-1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #494949;
}
.plus_icon {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
}
.active_jobs_row {
    width: 100%;
    height: 300px;
    overflow: auto;
    white-space: nowrap;
    margin-top: 14px;
}

.body_active {
    height: 100%;
}
.main_text {
    color: #494949;
    font-style: normal;
}
.card_job {
    width: 249px !important;
    height: 272px !important;
    display: inline-block;
    padding: 12px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    margin-right: 7px;
    border: 0.2px rgba(0, 0, 0, 0.2) solid;
}
.img_div {
    width: 225px;
    height: 217px;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 6px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.name_div {
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 215px;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    position: absolute;
    top: 97px;
    left: 5px;
}
.main_text {
    color: #494949;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}
.loader_line {
    width: 170px;
    height: 7px;
    border: 0.2px solid #696969;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0 auto;
    overflow: hidden;
}
.done {
    height: 100%;
    background: #494949;
}
.card_body {
    width: 200px;
    height: 20px;
    margin: 5px auto;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
}
.tab_pages {
    //border-bottom: 1.5px solid rgba(73, 73, 73, 0.25);
    display: flex;
    flex-direction: column;
    cursor: pointer;
    justify-content: space-between;
}
.item_tab {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    list-style: none;
    border-bottom: 1px solid #d5e0d5;
    font-weight: 500;
    padding: 15px;
    cursor: pointer;
    font-size: 14px;
}
.item_tab_active {
    color: #494949;
    //border-bottom: 5.5px solid #494949;
}
.item_tab_selected {
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}
.add_button {
    width: 120px;
    height: 120px;
    border: none;
    background: white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .icon {
        text-align: center;

        svg {
            width: 50%;
        }
    }
    .button_label {
        font-size: 13px;

        &_white {
            color: white;
        }
    }
}
.tab_item_d {
    margin-top: 64px;
    //display: flex;
    //gap: 55px;
}
.tab_item_d2 {
    margin-top: 64px;
    gap: 55px;
}
.selects_tab_item {
    display: flex;
    gap: 21px;
    flex-direction: column;
}
.box_select {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 0 36px;
}
.select_tab1 {
    background: white;
    display: flex;
    justify-content: space-between;

    cursor: pointer;
    height: 65px;
    align-items: center;
}
.title-2 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 44px;
    color: #494949;
}
.select_item {
    margin-bottom: 20px;
}
.list_onesies {
    padding: 0;
}
.list_onesies > li {
    font-style: normal;
    font-weight: 600;
    white-space: nowrap;
    font-size: 16px;
    line-height: 40px;
    color: black;
    display: flex;
    //width: 50%;
}
.list_onesies div {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    color: black;
    display: flex;
    justify-content: end;
    width: 100%;
}
.select-item-row {
    display: flex;
    justify-content: space-between;
}
.item_sample_work {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.spw_item, .spw_item2 {
    background: white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 12px;
}
.spw_item.active {
    .box_images_work {
        display: flex;
        flex-wrap: wrap;
    }
}
.arrow_select {
    display: flex;
    align-items: center;
    padding-right: 20px;
}
.sample_work {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 21px;
}
.box_sample_work_img_item {
    width: 100px;
    height: 151px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img_item_w {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.title_name_work {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 44px;
    color: #494949;
    margin-left: 55px;
    display: flex;
    align-items: center;
}
.box_images_work {
    display: none;
    margin-top: 38px;
    gap: 12px;
}
.search_sample_work {
    position: relative;
    margin-bottom: 44px;
}
.box_search_sample_work {
    width: 100%;
    display: flex;
    justify-content: center;
}
.search_icon {
    position: absolute;
    left: 25px;
    top: 11px;
    cursor: pointer;
}
.search_prod_inp {
    background: white;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    height: 35px;
    width: 100%;
    padding-left: 45px;
}
.box_add_style_guide {
    display: none;
}
.photo_full {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: white;
    border-radius: 5px;
}
#photoModal .modal-content {
    background-color: white!important;
    border: none;
}
#photoModal .modal-header {
    border: none;
    padding: 0;
}
#photoModal {
    .modal-body {
        padding: 23px;
        background: white;
        border-radius: 5px;
    }
}
.style_guide_box {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 15px;
    background: white;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .title-1 {
        padding: 27px 58px;
    }
}
.form_style_guide {
    margin-top: 57px;
    display: flex;
    gap: 30px;

    .input_item {
        padding-left: 12px;
        padding-right: 12px;
    }
}
.title_form {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 44px;
    color: black;
}
.input_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 26px;
}
.style_input1 {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    border: 0.3px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    height: 30px;
    padding: 6px 19px;
    width: 100%;
}
.with_input1 {
    width: 50px !important;
    padding: 0px 12px !important;
}
.x {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    padding: 7px;
}
input::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.45);
}
.btn_form {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 70px;
    padding-bottom: 20px;
}
.input_b1 {
    width: 50%;
}
.form-select {
    border-radius: 19px;
    color: rgba(74, 74, 74, 0.6);
    border-color: rgba(74, 74, 74, 0.6);
    overflow: hidden;
    text-overflow: ellipsis;
}
.form-select:focus {
    border-color: rgba(74, 74, 74, 0.6);
}
.card-img-top {
    opacity: 0.7;
}
.form-control.is-invalid, .form-control:invalid, .form-select:invalid, .form-select.is-invalid, .is-invalid_c {
    border-color: #dc3545 !important;
}
.invalid-feedback, .invalid-feedback_c {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #dc3545;
}
.video_href {
    text-decoration: none;
}

@media only screen and (max-width: 992px) {
    .style_guide_box {
        display: none;
    }
    .add_button {
        width: 100%;
        height: 65px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px;
        margin-top: 28px;
    }
    .tab_pages {
        margin-top: 0;
        margin-bottom: 35px;
    }
    .item_tab {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        padding: 10px 0;
        margin-bottom: 5px;

        &_red{background: #FFF5EE;}
        &_green{background: #F3FFEE;}
        &_blue{background: #EEF8FF;}

        &_icon {
            margin-bottom: 10px;

            img {
                width: 110%;
                margin-left: -10%;
            }
        }
    }
    .add_button {
        display: none;
    }
    .add_button_mobile {
        display: flex;
        justify-content: center;
        align-items: center;

        //width: 100%;
        height: 48px;
        margin-left: 5px;
        margin-right: 5px;

        background: #FCEFEF;
        border-radius: 8px;

        .button_label {
            font-size: 15px;
            text-align: center;
            color: #9E616A;
        }
    }
    .selects_tab_item {
        width: 100%;
        padding: 0 14px;
        gap: 15px;
    }
    .box_select {
        background: white;
        padding: 0 26px 0 30px;
    }
    .tab_item_d {
        margin-top: 12px;
        margin-bottom: 130px;
    }
    .title-2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
    }
    .list_onesies {
        padding-left: 0;
    }
    .list_onesies > li {
        font-weight: 700;
        font-size: 15px;
        line-height: 30px;
        width: 100%;
    }
    .list_onesies div {
        font-size: 15px;
        line-height: 30px;
    }
    .item_tab_active {
        border-bottom: none;
        border-radius: 8px;
        -webkit-filter: none;
        filter: none;
        &_red{background: #ddad72;}
        &_green{background: #bae5a9;}
        &_blue{background: #abcce3;}
    }
    .box_add_style_guide {
        display: block;
    }
    .tab_item_d2 {
        margin-top: 22px;
        padding: 0 12px;
    }
    .search_sample_work {
        margin-bottom: 16px;
        width: 100%;
    }
    .box_sample_work_img_item {
        width: 104px;
        height: 100px;

        &_mobile {
            svg {
                width: 40px;
            }
            .title_name_work {
                color: #9E616A;
            }
        }
    }
    .title_name_work {
        margin-left: 8px;
        font-weight: 700;
        font-size: 22px;
        line-height: 27px;
    }
    .spw_item {
        margin-bottom: 105px;
    }
    .margin_box_f {
        margin-bottom: 105px;
    }
    .box_images_work {
        margin-top: 21px;
    }
    .input_item {
        padding-left: 0;
    }
    .input_b1 {
        width: auto;
    }
    .stmob {
        margin-left: 10px;
    }
    .style_input1 {
        padding: 6px 30px 6px 12px;
        width: 200px;
    }
    .title_form {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
    }
    .mobile_guide_box {
        gap: 13px;
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;
        margin-top: 10px;
    }
    .btn_form {
        padding-top: 23px;
        padding-bottom: 0;
    }
}

@media only screen and (max-width: 400px) {
    .style_input1 {
        width: 150px;
    }
}
.featured-products {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
}
.modal-footer {
    border-top: none;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    gap: 35px;
}
.btn_modal_t1 {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    background: white;
    height: 47px;
    width: 148px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #494949;
}
.featured-product-item {
    width: 100%;
    max-height: 160px;

    border-radius: 5px;
    overflow: hidden;
    text-align: center;
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &_new {
        width: 175px;
        height: 175px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 992px) {
            max-width: 96px;
            max-height: 96px;
        }
    }
}
.featured-product-item-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: center;
}
.featured-product-item-text {
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: black;
    text-transform: uppercase;
}
.file_type {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: -0.02em;
    color: #2555FF;
}
.box_file_n {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.title_file_name {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 44px;
    color: #494949;
}
.img_file {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.type_box_f {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    color: black;
    text-transform: uppercase;
}
.leave-review {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration-line: underline;
    color: #494949;
}
.rating_job {
    display: flex;
    justify-content: center;
}

@media (max-width: 992px) {
    .featured-products {
        grid-template-columns: repeat(3, 1fr);
    }
    .modal-footer {
        border-top: none;
        display: flex;
        justify-content: center;
        padding-bottom: 30px;
        gap: 15px;
    }
    .box_file_n {
        margin-left: 20px;
    }
    .type_box_f {
        font-size: 25px;
    }
}
.card_job {
    width: 249px !important;
    height: 272px !important;
    display: inline-block;
    padding: 12px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    margin-right: 7px;
}
.card_job_s {
    cursor: pointer;
}
.img_div {
    width: 225px;
    height: 217px;
    overflow: hidden;
    border-radius: 5px;
    margin-bottom: 12px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.name_div {
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 215px;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    position: absolute;
    top: 97px;
    left: 5px;
}
.loader_line {
    width: 170px;
    height: 7px;
    border: 0.2px solid;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 0 auto;
    overflow: hidden;

    &.finished {
        border-color: #ABFCA4 !important;
    }
    &.in-process{
        border-color: #FFA159;
    }
    &.non-started{
        border-color: #FD5860;
    }
    &.in-past {
        border-color: #FD5860;
    }
}
.done {
    height: 100%;
    background: #494949;

    &.finished {
        background: #ABFCA4 !important;
    }
    &.non-finished {
        background: #FFA159 !important;
    }
    &.in-past {
        background: #FD5860 !important;
    }
}
.card_body {
    width: 200px;
    height: 20px;
    margin: 5px auto;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
}
.m_b_103 {
    .card_job {
        width: 169px !important;
        height: 200px !important;
    }
    .img_div {
        width: 145px;
        height: 145px;
    }
    .name_div {
        width: 145px;
        top: 58px;
    }
    .loader_line {
        width: 110px;
    }
    .card_body {
        width: 130px;
    }
    .active_jobs_row {
        height: 240px;
    }
}
.delete_icon {
    max-width: 18px;
    max-height: 18px;
}
.delete_featured-product-item {
    cursor: pointer;
}
.featured-product-item__name {
    font-size: 22px;
    font-weight: 600;
}
.fromUpperLetter {
    text-transform: capitalize;
}
.fw-400 {
    font-weight: 400;
}
.option {
    &__desc, &__image {
        display: inline-block;
        vertical-align: middle;
    }
    &__image {
        max-height: 80px;
        margin-right: 10px;
    }
    &__desc {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
.red-gradient-button {
    text-decoration: none;
    background: linear-gradient(180deg, #E27582 0%, #FEC0C8 99.5%), #FCF5EF;
    border: 0.5px solid white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
.assetsTitle {
    padding: 15px;
    font-weight: 700;
    font-size: 20px;

    border-bottom: 1px solid #d5e0d5;
    display: flex;
    justify-content: space-between;
}
.sticky-top-position {
    top: 93px;
}
.with-left-border {
    border-left: 1px solid #d5e0d5;
}
.z-index-1 {
    z-index: 1;
}
.info-icon {
    width: 20px;
}
.hovertext {
    position: relative;
    border-bottom: 1px dotted black;

    &:before {
        content: attr(data-hover);
        visibility: hidden;
        opacity: 0;
        width: 300px;
        background-color: black;
        color: white;
        text-align: center;
        border-radius: 5px;
        padding: 5px 5px;
        transition: opacity 1s ease-in-out;

        position: absolute;
        z-index: 1;
        left: 0;
        top: 110%;
    }
    &:hover:before {
        opacity: 1;
        visibility: visible;
    }
}
</style>
